import React, { useContext, useState } from 'react'
import DashboardNavbar from './DashboardNavbar'
import DashboardTopbar from './DashboardTopBar'
import { SettingContext } from '../../context/SettingsContext'
import { SwippleDrawer } from '../../components/drawers'
type Props = {
    children?: React.ReactNode,
    title?: string
}

function DashboardLayout(props: Props) {
    const { settings, changeSettings, changeThemeScheme, toggleTheme } = useContext(SettingContext)

    const [isNavBarOpen, setIsNavBarOpen] = useState(settings.screen === "desktop" ? true : false)
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: settings.screen === "mobile" ? "1fr" : "2fr 10fr",
                height: "100vh",
                width: "100%",
                overflow: "auto",
            }}
        >
            {
                settings.screen === "mobile" ?
                    <SwippleDrawer
                        isOpen={isNavBarOpen}
                        onClose={() => setIsNavBarOpen(false)}
                    >
                        <DashboardNavbar />
                    </SwippleDrawer>
                    :
                    <DashboardNavbar />
            }
            <div style={{
                position: "absolute",
                height: "100vh",
                overflow: "auto",
                // padding: "0 10px",
                background: "url(https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/innovator-assets/innovator.gif) no-repeat",
                backgroundSize: "cover",
                width: "100%",
                zIndex: -1,
                opacity: 0.6,
            }}>

            </div>
            <div style={{
                height: "100vh",
                overflow: "auto",
                // padding: "0 10px",
                // width: "100%",

            }}>
                <DashboardTopbar title={props.title as string} toggleNavBarOpen={() => {
                    setIsNavBarOpen(!isNavBarOpen)
                }} />
                <div style={{
                    height: "94vh",
                    overflow: "auto",
                    padding: "10px",
                }}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default DashboardLayout