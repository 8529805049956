import { Button, Tooltip } from '@mui/material';
import * as React from 'react';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MonitorIcon from '@mui/icons-material/Monitor';
import MemoryIcon from '@mui/icons-material/Memory';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { routes } from '../../../../../routes/routes';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined'; 
import ArticleIcon from '@mui/icons-material/Article';
import { LessonDataType } from '../../../../../types/Lesson';
import { eduCOBOTColorScheme } from '../../../../../context/SettingsContext';
export interface Props {
    lesson: LessonDataType;
    toggleHelp?: () => void
    autoFill?: () => void
    restart?: () => void
}

export function LessonBottomBarComponent({
    lesson,
    toggleHelp,
    autoFill,
    restart
}: Props) {
    const navigate = useNavigate()
    return (
        <>
            <div style={{
                position: "fixed",
                display: "flex",
                flexDirection: "column",
                top: "50%",
                right: "0",
                transform: "translate(0,-50%)",
                zIndex: 999999,
            }}>
                <Tooltip title="Go to Curriculum" placement='left'>
                    <Button size='small' style={{
                        background: "white"
                    }} onClick={() => {
                        navigate(routes.DASHBOARD.LESSONS);
                    }}>
                        <LibraryBooksIcon style={{
                            color: eduCOBOTColorScheme.gray
                        }} />
                    </Button>
                </Tooltip>
                {
                    toggleHelp &&
                    <Tooltip title="Help" placement='left'>
                        <Button style={{
                            background: "white"
                        }} onClick={() => {
                            toggleHelp && toggleHelp()
                        }}>
                            <img src="https://storage.googleapis.com/educobot-robotics/images/Help.svg" style={{
                                maxWidth: "20px",
                            }} alt="" />
                        </Button>
                    </Tooltip>
                }
                {/* <Tooltip title="Restart" placement='left'>
                    <Button style={{
                        background: "white"
                    }} onClick={() => {
                        restart && restart()
                    }}>
                        <img src="https://storage.googleapis.com/educobot-robotics/images/Reset.svg" style={{
                            maxWidth: "20px",
                        }} alt="" />
                    </Button>
                </Tooltip> */}
                {
                    autoFill &&
                    <Tooltip title="Autofill" placement='left'>
                        <Button style={{
                            background: "white"
                        }} onClick={() => {
                            autoFill()
                        }}>
                            <img src="https://storage.googleapis.com/educobot-robotics/images/Autofill.svg" style={{
                                maxWidth: "20px",
                            }} alt="" />
                        </Button>
                    </Tooltip>
                }
            </div>
            {/* <div id='LessonNavigation' style={{
                position: "fixed",
                bottom: "0",
                height: "50px",
                border: "1px solid #00000022",
                width: "min(100%,500px)",
                maxWidth: "100vw",
                left: "50%",
                transform: "translate(-50%,0)",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                gap: "5px",
                zIndex: 999999,
                background: "#0000001A",
                padding: "5px",
            }}>
                {
                    lesson.editors.includes("theory") && <>
                        <Tooltip title="Theory">
                            <Button id='Home' variant={window.location.href.includes("theory") ? "outlined" : undefined} style={{
                                background: "white"
                            }} onClick={() => {
                                // navigate(routes.DASHBOARD.LESSON.THEORY(lesson.lessonId));
                            }}>
                                <ArticleIcon />
                            </Button>
                        </Tooltip>
                    </>
                }
                {
                    lesson.editors.includes("assembly") && <>

                        <Tooltip title="Assembly">
                            <Button id='Assembly' variant={window.location.href.includes("assembly") ? "outlined" : undefined} style={{
                                background: "white"
                            }} onClick={() => {
                                navigate(routes.DASHBOARD.LESSON.WORKSPACE.ASSEMBLY(lesson.lessonId));
                            }}>
                                <img src="https://cdn-icons-png.flaticon.com/512/1453/1453773.png" width={25} alt="" />
                            </Button>
                        </Tooltip>
                    </>
                }
                {
                    lesson.editors.includes("quiz") && <>

                        <Tooltip title="Quiz">
                            <Button id='Quiz' variant={window.location.href.includes("quiz") ? "outlined" : undefined} style={{
                                background: "white"
                            }} onClick={() => {
                                navigate(routes.DASHBOARD.LESSON.WORKSPACE.MCQ(lesson.lessonId));
                            }}>
                                <SpaceDashboardOutlinedIcon />
                            </Button>
                        </Tooltip>
                    </>
                }
                {
                    lesson.editors.includes("exam") && <>

                        <Tooltip title="Exam">
                            <Button id='Exam' variant={window.location.href.includes("exam") ? "outlined" : undefined} style={{
                                background: "white"
                            }} onClick={() => {
                                navigate(routes.DASHBOARD.LESSON.WORKSPACE.MCQ(lesson.lessonId));
                            }}>
                                <SpaceDashboardOutlinedIcon />
                            </Button>
                        </Tooltip>
                    </>
                }
                {
                    lesson.editors.includes("circuit-designer") && <>
                        <Tooltip title="Circuit Designer">
                            <Button id='CircuitDesigner' variant={window.location.href.includes("circuit") ? "outlined" : undefined} style={{
                                background: "white"
                            }} onClick={() => {
                                navigate(routes.DASHBOARD.LESSON.WORKSPACE.CIRCUIT(lesson.lessonId));
                            }}>
                                <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Circuit.svg" width={25} alt="" />
                            </Button>
                        </Tooltip>
                    </>
                }

                {
                    lesson.editors.includes("micropython-editor") && <>
                        <Tooltip title="Micropython Code Editor">

                            <Button id='MicroPythonCodeEditor' variant={window.location.href.includes("micropython") ? "outlined" : undefined} style={{
                                background: "white"
                            }} onClick={() => {
                                navigate(routes.DASHBOARD.LESSON.WORKSPACE.MICROPYTHON(lesson.lessonId));
                            }}>
                                <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/IDE.svg" width={25} alt="" />
                            </Button>
                        </Tooltip>

                    </>
                }
                {
                    lesson.editors.includes("iot-monitor") && <>
                        <Tooltip title="IOT Monitor">
                            <Button id='IOTMonitor' variant={window.location.href.includes("monitor") ? "outlined" : undefined} style={{
                                background: "white"
                            }} onClick={() => {
                                navigate(routes.DASHBOARD.LESSON.WORKSPACE.MONITOR(lesson.lessonId));
                            }}>
                                <MonitorIcon />
                            </Button>
                        </Tooltip>
                    </>
                }
                {
                    lesson.editors.includes("ai-editor") && <>
                        <Tooltip title="AI">

                            <Button id='AIEditor' variant={window.location.href.includes("ai") ? "outlined" : undefined} style={{
                                background: "white"
                            }} onClick={() => {
                                navigate(routes.DASHBOARD.LESSON.WORKSPACE.AI(lesson.lessonId));
                            }}>
                                <SmartToyIcon />
                            </Button>
                        </Tooltip>

                    </>
                }

            </div> */}
        </>
    );
}
