import { PointPortModel, PortConstraints, PortVisibility } from "@syncfusion/ej2-react-diagrams";

export interface CustomPort extends PointPortModel {
    text: string;
}


export const breadBoardPorts: CustomPort[] = [
    {
        id: "breadboard-port1", shape: "Circle", offset: { x: 0.05414, y: 0.046 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port1", shape: "Circle", offset: { x: 0.05414, y: 0.046 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port1", shape: "Circle", offset: { x: 0.05414, y: 0.046 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port1", shape: "Circle", offset: { x: 0.05414, y: 0.046 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port1", shape: "Circle", offset: { x: 0.05414, y: 0.046 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port1", shape: "Circle", offset: { x: 0.05414, y: 0.046 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port1", shape: "Circle", offset: { x: 0.05414, y: 0.046 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port1", shape: "Circle", offset: { x: 0.05414, y: 0.046 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port1", shape: "Circle", offset: { x: 0.05414, y: 0.046 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port1", shape: "Circle", offset: { x: 0.05414, y: 0.046 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port1", shape: "Circle", offset: { x: 0.05414, y: 0.046 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port1", shape: "Circle", offset: { x: 0.05414, y: 0.046 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port1", shape: "Circle", offset: { x: 0.05414, y: 0.046 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "breadboard-port1", shape: "Circle", offset: { x: 0.05414, y: 0.046 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]





export const shieldPorts: CustomPort[] = [
    {
        id: "shield-port3", shape: "Square", offset: { x: 0, y: 0.305 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port4", shape: "Square", offset: { x: 0, y: 0.35 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port5", shape: "Square", offset: { x: 0, y: 0.39 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1",
        constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "shield-port6", shape: "Square", offset: { x: 0, y: 0.47 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port7", shape: "Square", offset: { x: 0, y: 0.51 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port8", shape: "Square", offset: { x: 0, y: 0.55 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },


    {
        id: "shield-port9", shape: "Square", offset: { x: 0, y: 0.63 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port10", shape: "Square", offset: { x: 0, y: 0.670 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port11", shape: "Square", offset: { x: 0, y: 0.710 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "shield-port13", shape: "Square", offset: { x: 0, y: 0.8 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port14", shape: "Square", offset: { x: 0, y: 0.835 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port15", shape: "Square", offset: { x: 0, y: 0.87 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "shield-port18", shape: "Square", offset: { x: 1, y: 0.315 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port35", shape: "Square", offset: { x: 1, y: 0.345 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port19", shape: "Square", offset: { x: 1, y: 0.375 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port20", shape: "Square", offset: { x: 1, y: 0.405 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },




    {
        id: "shield-port22", shape: "Square", offset: { x: 1, y: 0.49 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port23", shape: "Square", offset: { x: 1, y: 0.54 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },


    {
        id: "shield-port25", shape: "Square", offset: { x: 1, y: 0.640 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port26", shape: "Square", offset: { x: 1, y: 0.680 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },



    {
        id: "shield-port28", shape: "Square", offset: { x: 1, y: 0.78 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port29", shape: "Square", offset: { x: 1, y: 0.82 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port30", shape: "Square", offset: { x: 1, y: 0.87 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },




    {
        id: "shield-port31", shape: "Square", offset: { x: 0.615, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port32", shape: "Square", offset: { x: 0.69, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },



    {
        id: "shield-port33", shape: "Square", offset: { x: 0.355, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-port34", shape: "Square", offset: { x: 0.27, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]



export const newShieldPorts: CustomPort[] = [
    {
        id: "shield-led-d2", shape: "Square", offset: { x: 0, y: 0.185 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-led-gnd", shape: "Square", offset: { x: 0, y: 0.22 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },


    {
        id: "shield-touch-gnd", shape: "Square", offset: { x: 0, y: 0.31 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-touch-vcc", shape: "Square", offset: { x: 0, y: 0.345 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-touch-d15", shape: "Square", offset: { x: 0, y: 0.38 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1",
        constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "shield-ldr-vcc", shape: "Square", offset: { x: 0, y: 0.46 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ldr-gnd", shape: "Square", offset: { x: 0, y: 0.495 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ldr-d18", shape: "Square", offset: { x: 0, y: 0.53 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },


    {
        id: "shield-ir2-vcc", shape: "Square", offset: { x: 0, y: 0.61 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ir2-gnd", shape: "Square", offset: { x: 0, y: 0.645 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ir2-d19", shape: "Square", offset: { x: 0, y: 0.68 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "shield-servo-gnd", shape: "Square", offset: { x: 0, y: 0.76 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-servo-vcc", shape: "Square", offset: { x: 0, y: 0.795 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-servo-d23", shape: "Square", offset: { x: 0, y: 0.83 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },











    {
        id: "shield-rgb-vcc", shape: "Square", offset: { x: 1, y: 0.37 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-rgb-d14", shape: "Square", offset: { x: 1, y: 0.395 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-rgb-d12", shape: "Square", offset: { x: 1, y: 0.42 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-rgb-d13", shape: "Square", offset: { x: 1, y: 0.445 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },



    {
        id: "shield-ir1-vcc", shape: "Square", offset: { x: 1, y: 0.54 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    // {
    //     id: "shield-port22", shape: "Square", offset: { x: 1, y: 0.565 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    // },
    {
        id: "shield-ir1-gnd", shape: "Square", offset: { x: 1, y: 0.577 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ir1-d35", shape: "Square", offset: { x: 1, y: 0.615 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },


    {
        id: "shield-ultrasonic-vcc", shape: "Square", offset: { x: 1, y: 0.71 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ultrasonic-d4", shape: "Square", offset: { x: 1, y: 0.735 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ultrasonic-d5", shape: "Square", offset: { x: 1, y: 0.76 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-ultrasonic-gnd", shape: "Square", offset: { x: 1, y: 0.785 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },





    {
        id: "shield-m2-d32", shape: "Square", offset: { x: 0.54, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-m2-d33", shape: "Square", offset: { x: 0.58, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },



    {
        id: "shield-m1-d25", shape: "Square", offset: { x: 0.42, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-m1-d26", shape: "Square", offset: { x: 0.46, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },




    {
        id: "shield-lcd-gnd", shape: "Square", offset: { x: 0.14, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },

    {
        id: "shield-lcd-vcc", shape: "Square", offset: { x: 0.17, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-lcd-d21", shape: "Square", offset: { x: 0.20, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "shield-lcd-d22", shape: "Square", offset: { x: 0.23, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]



export const esp32Ports: CustomPort[] = [
    {
        id: "esp-port1", shape: "Square", offset: { x: 0, y: 0.207 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port2", shape: "Square", offset: { x: 0, y: 0.258 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port3", shape: "Square", offset: { x: 0, y: 0.305 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port4", shape: "Square", offset: { x: 0, y: 0.355 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port5", shape: "Square", offset: { x: 0, y: 0.405 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port6", shape: "Square", offset: { x: 0, y: 0.455 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port7", shape: "Square", offset: { x: 0, y: 0.505 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port8", shape: "Square", offset: { x: 0, y: 0.555 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port9", shape: "Square", offset: { x: 0, y: 0.605 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port10", shape: "Square", offset: { x: 0, y: 0.650 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port11", shape: "Square", offset: { x: 0, y: 0.700 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port12", shape: "Square", offset: { x: 0, y: 0.750 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port13", shape: "Square", offset: { x: 0, y: 0.795 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port14", shape: "Square", offset: { x: 0, y: 0.840 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port15", shape: "Square", offset: { x: 0, y: 0.890 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port16", shape: "Square", offset: { x: 1, y: 0.207 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port17", shape: "Square", offset: { x: 1, y: 0.258 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port18", shape: "Square", offset: { x: 1, y: 0.305 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port19", shape: "Square", offset: { x: 1, y: 0.355 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port20", shape: "Square", offset: { x: 1, y: 0.405 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port21", shape: "Square", offset: { x: 1, y: 0.455 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port22", shape: "Square", offset: { x: 1, y: 0.505 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port23", shape: "Square", offset: { x: 1, y: 0.555 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port24", shape: "Square", offset: { x: 1, y: 0.605 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port25", shape: "Square", offset: { x: 1, y: 0.650 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port26", shape: "Square", offset: { x: 1, y: 0.700 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port27", shape: "Square", offset: { x: 1, y: 0.750 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port28", shape: "Square", offset: { x: 1, y: 0.795 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port29", shape: "Square", offset: { x: 1, y: 0.840 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "esp-port30", shape: "Square", offset: { x: 1, y: 0.890 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]

export const ultrasonicPorts: CustomPort[] = [
    {
        id: "ultrasonic-port1", shape: "Square", offset: { x: 0.41, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "ultrasonic-port2", shape: "Square", offset: { x: 0.467, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "ultrasonic-port3", shape: "Square", offset: { x: 0.522, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "ultrasonic-port4", shape: "Square", offset: { x: 0.58, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const SevenSegmentPorts: CustomPort[] = [
    {
        id: "SevenSegment-port1", shape: "Square", offset: { x: 0.15, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SevenSegment-port2", shape: "Square", offset: { x: 0.25, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SevenSegment-port3", shape: "Square", offset: { x: 0.35, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SevenSegment-port4", shape: "Square", offset: { x: 0.45, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SevenSegment-port5", shape: "Square", offset: { x: 0.55, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SevenSegment-port6", shape: "Square", offset: { x: 0.65, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SevenSegment-port7", shape: "Square", offset: { x: 0.75, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SevenSegment-port8", shape: "Square", offset: { x: 0.85, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const SensorModulePorts: CustomPort[] = [
    // {
    //     id: "SensorModule-port1", shape: "Square", offset: { x: 0, y: 0.51 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    // },
    // {
    //     id: "SensorModule-port2", shape: "Square", offset: { x: 0, y: 0.47 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    // },



    {
        id: "SensorModule-port3", shape: "Square", offset: { x: 1, y: 0.42 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SensorModule-port4", shape: "Square", offset: { x: 1, y: 0.48 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SensorModule-port2", shape: "Square", offset: { x: 1, y: 0.54 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },



    {
        id: "SensorModule-port5", shape: "Square", offset: { y: 0, x: 0.535 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SensorModule-port6", shape: "Square", offset: { y: 0, x: 0.47 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },



    {
        id: "SensorModule-port7", shape: "Square", offset: { y: 1, x: 0.57 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SensorModule-port8", shape: "Square", offset: { y: 1, x: 0.49 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SensorModule-port9", shape: "Square", offset: { y: 1, x: 0.42 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const dht11Ports: CustomPort[] = [
    {
        id: "dht11-port1", shape: "Square", offset: { x: 0.20, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "dht11-port2", shape: "Square", offset: { x: 0.50, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "dht11-port3", shape: "Square", offset: { x: 0.80, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const IRPorts: CustomPort[] = [
    {
        id: "IR-port1", shape: "Square", offset: { x: 0.39, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "IR-port2", shape: "Square", offset: { x: 0.5, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "IR-port3", shape: "Square", offset: { x: 0.62, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]

export const TouchPorts: CustomPort[] = [
    {
        id: "Touch-GND", shape: "Square", offset: { x: 0.39, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Touch-VCC", shape: "Square", offset: { x: 0.5, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Touch-DOUT", shape: "Square", offset: { x: 0.62, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const SoilMoisturePorts: CustomPort[] = [
    {
        id: "SoilMoisture-GND", shape: "Square", offset: { x: 0.24, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SoilMoisture-VCC", shape: "Square", offset: { x: 0.43, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SoilMoisture-DOUT", shape: "Square", offset: { x: 0.60, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SoilMoisture-Analog", shape: "Square", offset: { x: 0.78, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]


export const LDRPorts: CustomPort[] = [
    {
        id: "LDR-port1", shape: "Square", offset: { x: 0.39, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "LDR-port2", shape: "Square", offset: { x: 0.5, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "LDR-port3", shape: "Square", offset: { x: 0.62, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const LCDPorts: CustomPort[] = [
    {
        id: "LCD-GND", shape: "Square", offset: { y: 0.20, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "LCD-VCC", shape: "Square", offset: { y: 0.27, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "LCD-SDA", shape: "Square", offset: { y: 0.34, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "LCD-SCL", shape: "Square", offset: { y: 0.41, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const SmokeSensorPorts: CustomPort[] = [
    {
        id: "SmokeSensor-port1", shape: "Square", offset: { x: 0.31, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SmokeSensor-port2", shape: "Square", offset: { x: 0.44, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SmokeSensor-port3", shape: "Square", offset: { x: 0.57, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "SmokeSensor-port4", shape: "Square", offset: { x: 0.70, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const KeypadPorts: CustomPort[] = [
    {
        id: "Keypad-port1", shape: "Square", offset: { x: 0.385, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Keypad-port2", shape: "Square", offset: { x: 0.42, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Keypad-port3", shape: "Square", offset: { x: 0.455, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Keypad-port4", shape: "Square", offset: { x: 0.49, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Keypad-port5", shape: "Square", offset: { x: 0.525, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Keypad-port6", shape: "Square", offset: { x: 0.56, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Keypad-port7", shape: "Square", offset: { x: 0.595, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Keypad-port8", shape: "Square", offset: { x: 0.63, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]




export const LEDPorts: CustomPort[] = [
    {
        id: "LED-port1", shape: "Square", offset: { x: 0, y: 0.45 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "LED-port2", shape: "Square", offset: { x: 0, y: 0.55 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]


export const RGB_LED_Ports: CustomPort[] = [
    {
        id: "rgb-+1", shape: "Square", offset: { x: 0, y: 0.44 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "rgb-r1", shape: "Square", offset: { x: 0, y: 0.52 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "rgb-+2", shape: "Square", offset: { x: 1, y: 0.44 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "rgb-r2", shape: "Square", offset: { x: 1, y: 0.52 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "rgb-r", shape: "Square", offset: { x: 0.38, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "rgb-g", shape: "Square", offset: { x: 0.46, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "rgb-b", shape: "Square", offset: { x: 0.54, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "rgb-+", shape: "Square", offset: { x: 0.62, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]



export const DCMotorPorts: CustomPort[] = [
    {
        id: "DCMotor-D25", shape: "Square", offset: { y: 0, x: 0.45 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "DCMotor-D26", shape: "Square", offset: { y: 0, x: 0.55 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "DCMotor-D32", shape: "Square", offset: { y: 1, x: 0.45 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "DCMotor-D33", shape: "Square", offset: { y: 1, x: 0.55 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const BOMotorPorts: CustomPort[] = [
    {
        id: "BOMotor-D25", shape: "Square", offset: { y: 0, x: 0.65 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "BOMotor-D26", shape: "Square", offset: { y: 0, x: 0.80 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const DotMatrixPorts: CustomPort[] = [
    {
        id: "DotMatrix-VCC", shape: "Square", offset: { y: 0.36, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "DotMatrix-GND", shape: "Square", offset: { y: 0.438, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "DotMatrix-DIN", shape: "Square", offset: { y: 0.515, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "DotMatrix-CS", shape: "Square", offset: { y: 0.588, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "DotMatrix-CLK", shape: "Square", offset: { y: 0.67, x: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const BuzzerPorts: CustomPort[] = [
    {
        id: "Buzzer-port1", shape: "Square", offset: { x: 1, y: 0.45 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Buzzer-port2", shape: "Square", offset: { x: 1, y: 0.55 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]

export const PushButtonPorts: CustomPort[] = [
    {
        id: "PushButton-port1", shape: "Square", offset: { x: 0, y: 0.51 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PushButton-port2", shape: "Square", offset: { x: 0, y: 0.47 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PushButton-port3", shape: "Square", offset: { x: 1, y: 0.53 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PushButton-port4", shape: "Square", offset: { x: 1, y: 0.49 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PushButton-port5", shape: "Square", offset: { y: 0, x: 0.535 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PushButton-port6", shape: "Square", offset: { y: 0, x: 0.47 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PushButton-port7", shape: "Square", offset: { y: 1, x: 0.53 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "PushButton-port8", shape: "Square", offset: { y: 1, x: 0.46 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]
export const ServoPorts: CustomPort[] = [
    {
        id: "Servo-GND", shape: "Square", offset: { x: 0.84, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Servo-VCC", shape: "Square", offset: { x: 0.90, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "Servo-DIN", shape: "Square", offset: { x: 0.96, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]




export const OLEDPorts: CustomPort[] = [
    {
        id: "OLED-GND", shape: "Square", offset: { x: 0.22, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "OLED-VCC", shape: "Square", offset: { x: 0.375, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "OLED-SCL", shape: "Square", offset: { x: 0.535, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "OLED-SDA", shape: "Square", offset: { x: 0.685, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]



export const RFIDPorts: CustomPort[] = [
    {
        id: "RFID-port1", shape: "Square", offset: { x: 0.25, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port2", shape: "Square", offset: { x: 0.32, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port3", shape: "Square", offset: { x: 0.385, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port4", shape: "Square", offset: { x: 0.45, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port5", shape: "Square", offset: { x: 0.52, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port6", shape: "Square", offset: { x: 0.585, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port7", shape: "Square", offset: { x: 0.65, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port8", shape: "Square", offset: { x: 0.715, y: 0 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]

export const ADXL345Ports: CustomPort[] = [
    {
        id: "RFID-port1", shape: "Square", offset: { x: 0.1, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port2", shape: "Square", offset: { x: 0.215, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port3", shape: "Square", offset: { x: 0.322, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port4", shape: "Square", offset: { x: 0.434, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port5", shape: "Square", offset: { x: 0.549, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port6", shape: "Square", offset: { x: 0.663, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port7", shape: "Square", offset: { x: 0.773, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
    {
        id: "RFID-port8", shape: "Square", offset: { x: 0.895, y: 1 }, height: 8, width: 8, visibility: PortVisibility.Visible, text: "In - 1", constraints: PortConstraints.Default | PortConstraints.Draw,
    },
]