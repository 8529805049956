import { Button, Card, Container, LinearProgress, Tooltip, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { LessonDataType } from '../../../types/Lesson'
import { ContentDialogBox } from '../../../components/dialog-box'
import CloseIcon from '@mui/icons-material/Close';
import { BackendConfigs } from '../../../config.environment'
import { APIResponse } from '../../../types/Response'
import { LeaderboardDataType } from '../../../types/Dashboard'
import { UserContext } from '../../../context/UserContext';
import { SettingContext } from '../../../context/SettingsContext';

type Props = {
    lessons: LessonDataType[],
}

function LeaderboardComponent({
    lessons,
}: Props) {
    const [isOpen, setIsOpen] = React.useState(false)
    const [leaderboardData, setLeaderboardData] = React.useState<LeaderboardDataType[]>([]);
    const [loading, setLoading] = React.useState(true)
    const { settings } = useContext(SettingContext)
    async function getLeaderboard() {
        try {
            const response = await fetch(`${BackendConfigs.url}/GetLeaderboardDataAPI`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            const data = await response.json() as APIResponse

            if (data.success) {
                setLeaderboardData(data.data)
            }

        } catch (error) {
            console.log(error)

        }
    }

    React.useEffect(() => {
        getLeaderboard()
    }, [])
    function getLeaderboardRankImage(rank: number) {
        if (rank == 1) {
            return "https://cdn-icons-png.flaticon.com/512/6394/6394616.png"
        } else if (rank == 2) {
            return "https://cdn-icons-png.flaticon.com/512/5406/5406798.png"
        } else if (rank == 3) {
            return "https://cdn-icons-png.flaticon.com/512/5406/5406800.png"
        } else {
            return "https://ui-avatars.com/api/?name=" + rank
        }


    }
    return (
        <>
            {
                leaderboardData.length > 0 &&
                <Tooltip title="Leaderboard" placement="bottom">

                    <Button variant="outlined" color="primary" onClick={() => {
                        setIsOpen(true)
                    }} style={{
                    }}>
                        <img src="https://storage.googleapis.com/educobot-robotics/images/set-1/Leader%20Board.svg" style={{
                            width: "30px"
                        }} alt="" />
                    </Button>
                </Tooltip>
            }
            <ContentDialogBox
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                fullScreen
                title={
                    <>
                        <Typography variant='h6'>
                            Leaderboard
                        </Typography>
                    </>
                }
                content={

                    <>
                        <Button variant='contained' color='warning' sx={{
                            position: "fixed",
                            top: "10px",
                            right: "10px"
                        }} onClick={() => {
                            setIsOpen(false)
                        }}>
                            <CloseIcon />
                        </Button>
                        <Container>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                            }}>

                                <img src={""} style={{
                                    width: "50px",
                                }} alt="" />
                                <Card style={{
                                    flexGrow: 1,
                                    margin: "10px",
                                    display: settings.screen == "mobile" ? "flex" : "grid",
                                    gridTemplateColumns: "1fr 1fr 1fr",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: "20px",
                                    backgroundColor: "#00AF4F",
                                    color: "white",
                                }}>
                                    <Typography style={{
                                        flexGrow: 1,
                                        display: "flex",
                                        justifyContent: "left",
                                        fontWeight: "bolder"
                                    }}>
                                        Name
                                    </Typography>
                                    <Typography style={{
                                        flexGrow: 1,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "10px",
                                        fontWeight: "bolder"
                                    }}>
                                        Coins
                                    </Typography>
                                    <Typography style={{
                                        flexGrow: 1,
                                        display: "flex",
                                        justifyContent: "end",
                                        fontWeight: "bolder"
                                    }}>
                                        Score
                                    </Typography>
                                </Card>
                            </div>
                            {
                                leaderboardData.map((leaderboard, index) => {
                                    return (<div key={index} style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                    }}>

                                        <img src={getLeaderboardRankImage(index + 1)} style={{
                                            width: "50px",
                                            borderRadius: index >= 3 ? "50%" : "0%",
                                        }} alt="" />
                                        <Card key={index} style={{
                                            flexGrow: 1,
                                            margin: "10px",
                                            display: settings.screen == "mobile" ? "flex" : "grid",
                                            gridTemplateColumns: "1fr 1fr 1fr",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "20px",
                                            backgroundColor: "#2065D1",
                                            color: "white",
                                        }}>
                                            <Typography style={{
                                                flexGrow: 1,
                                                display: "flex",
                                                justifyContent: "left"
                                            }}>
                                                {leaderboard.name}
                                            </Typography>
                                            <Typography style={{
                                                flexGrow: 1,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "10px"
                                            }}>
                                                <span>

                                                    {leaderboard.totalCoins}
                                                </span>
                                                <img src="https://educobot.com/imgs/product/coin5.png" style={{
                                                    width: "20px",
                                                }} alt="" />
                                            </Typography>
                                            <Typography style={{
                                                flexGrow: 1,
                                                display: "flex",
                                                justifyContent: "end"
                                            }}>
                                                {leaderboard.finalScore.toFixed(2)}
                                            </Typography>
                                        </Card>
                                    </div>
                                    )
                                })
                            }


                            {
                                leaderboardData.length == 0 && <>

                                    <Card style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        height: "30vh",
                                        margin: "10px",
                                        padding: "10px",
                                        boxShadow: "0 0 5px #ccc",
                                        borderRadius: "10px"
                                    }}>
                                        <p style={{ textAlign: "center" }}>No one yet cross the Line</p>
                                    </Card>

                                </>
                            }

                        </Container>






                    </>


                } />
        </>
    )
}

export default LeaderboardComponent
