import React, { useContext, useEffect } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Card, CardActions } from '@mui/material';
import { eduCOBOTColorScheme } from '../../../context/SettingsContext';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/routes';
import { UserContext } from '../../../context/UserContext';
import { SettingContext } from '../../../context/SettingsContext';
import { BackendConfigs } from '../../../config.environment';
import { APIResponse } from '../../../types/Response';
import { toast } from 'react-toastify';
import { ProjectDataType } from '../../../types/Dashboard';


type Props = {
    setIsDataChanged: React.Dispatch<React.SetStateAction<any>>
}


function ProjectListComponent({ setIsDataChanged }: Props) {
    const navigate = useNavigate()
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [projectList, setProjectList] = React.useState<ProjectDataType['project'][]>([])

    async function fetchProjects() {
        handleGlobalLoading(true, "Fetching Projects")
        setProjectList([])
        try {
            const response = await fetch(`${BackendConfigs.url}/GetProjectsByOwnerIdAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ownerId: user.userData?.id
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                setProjectList(result.data)
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }
    useEffect(() => {
        fetchProjects()
    }, [user])
    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: settings.screen === "mobile" ? "center" : "start",
                alignItems: "start",
                flexWrap: "wrap"
            }}>
                {
                    projectList && projectList.map(project => {
                        return <ProjectCard key={project._id} data={project} reFetch={fetchProjects} />
                    })
                }
                {
                    projectList.length === 0 && <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "30vh",
                        margin: "10px",
                        padding: "10px",
                        boxShadow: "0 0 5px #ccc",
                        borderRadius: "10px"
                    }}>

                        <p style={{ textAlign: "center" }}>No Projects Found</p>
                    </Card>
                }
            </div>
        </div>
    )
}




function ProjectCard({ data, reFetch }: {
    data: ProjectDataType['project'],
    reFetch: () => void
}) {
    const navigate = useNavigate()
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)


    return (
        <>
            <Card style={{
                width: "min(350px, 100%)",
                minHeight: "min(250px, 500px)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                borderRadius: "20px",
                margin: "10px",
                // background: "#ffffffCD",
                transition: "all 0.3s",
                paddingBottom: "50px",
            }}
                sx={{
                    ":hover": {
                        scale: "1.1",
                    }
                }}
            >
                {
                    data.isCreatedFromBlueprint &&
                    <Button size='small' variant="outlined"  color="warning" style={{
                        fontSize: "9px",
                        textTransform: "none",
                        margin: "5px 0",
                        padding: "2px 10px",
                        position: "absolute",
                        top: "0px",
                        right: "10px",
                        zIndex: "100"
                    }}>
                        Created from Blueprint
                    </Button>
                }
                <img src={data.blueprintImage} alt="" style={{
                maxWidth: data.blueprintImage === "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/website-matches/Innovator-logo.png" ? "100px" : "350px",
                maxHeight: data.blueprintImage === "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/website-matches/Innovator-logo.png" ? "100px" : "200px",

                    // marginBottom: "20px",
                }} />
                <CardActions style={{
                    width: "100%",
                    background: "#0000003E",
                    textAlign: "center",
                    marginBottom: "0px",
                    padding: "15px",
                    position: "absolute",
                    bottom: "0px",
                    height: "50px"
                }} onClick={() => {
                    
                    navigate(routes.DASHBOARD.PROJECT.DESCRIPTION(data._id))
                }}>
                    <h3 style={{
                        width: "100%",
                        // color: eduCOBOTColorScheme.black,
                        textAlign: "center",
                    }} >
                        {
                            data.name
                        }
                    </h3>
                </CardActions>
            </Card>



        </>

    )
}

export default ProjectListComponent