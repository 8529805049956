import React, { useEffect } from 'react'
import { LessonDataType } from '../../../../../types/Lesson'
import { DotLottieReact } from '@lottiefiles/dotlottie-react'
import { eduCOBOTColorScheme } from '../../../../../context/SettingsContext'
import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Button, Card, TextField } from '@mui/material';
import { APIResponse } from '../../../../../types/Response';
import { UserContext } from '../../../../../context/UserContext';
import { BackendConfigs } from '../../../../../config.environment';
import { toast } from 'react-toastify';
const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
}));

const customIcons: {
    [index: string]: {
        icon: React.ReactElement<unknown>;
        label: string;
    };
} = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon style={{
            fontSize: "50px"
        }} color="error" />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon style={{
            fontSize: "50px"
        }} color="error" />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon style={{
            fontSize: "50px"
        }} color="warning" />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon style={{
            fontSize: "50px"
        }} color="primary" />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon style={{
            fontSize: "50px"
        }} color="success" />,
        label: 'Very Satisfied',
    },
};
function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}
type Props = {
    lesson: LessonDataType,
}

function RatingComponent({
    lesson,
}: Props) {
    const { user } = React.useContext(UserContext)
    const [isReviewed, setIsReviewed] = React.useState(false)
    const [lessonRating, setLessonRating] = React.useState(0)
    const [experienceRating, setExperienceRating] = React.useState(0)
    const [feedback, setFeedback] = React.useState('')
    async function getLessonRating() {
        const response = await fetch(`${BackendConfigs.url}/GetLessonRatingAPI`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                lessonId: lesson.lessonId,
                userId: user.userData?.id
            })
        })
        const data = await response.json() as APIResponse
        if (data.success && !!data.data) {
            console.log(data);

            setLessonRating(data.data.lessonRating)
            setExperienceRating(data.data.experienceRating)
            setFeedback(data.data.lessonFeedback)
            setIsReviewed(true)

        }
    }
    useEffect(() => {
        if (!!user.userData)
            getLessonRating()
    }, [user])

    async function submitRating() {
        if (lessonRating === 0 || experienceRating === 0) {
            toast.error('Please rate the lesson and experience')
            return
        }
        const response = await fetch(`${BackendConfigs.url}/SubmitLessonRatingAPI`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: user.userData?.id,
                lessonId: lesson.lessonId,
                lessonRating,
                experienceRating,
                lessonFeedback: !!feedback ? feedback : 'No Feedback given',
            })
        })
        const data = await response.json() as APIResponse
        if (data.success) {
            setIsReviewed(true)
        }
    }



    if (isReviewed) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
            }}>
                <div
                    style={{
                        width: 'max(50%,280px)',
                    }}
                >
                    <DotLottieReact
                        src="/animations/Reviewed.lottie"
                        autoplay
                        loop
                    />
                </div>
                <h3 style={{
                    textAlign: 'center',
                    color: eduCOBOTColorScheme.green
                }}>Thanks for your review</h3>
            </div>
        )
    }
    return (
        <Card style={{
            margin: '50px auto',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: 'min(400px, 100%)',
        }} elevation={5}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '20px'
            }}>
                <p style={{
                    color: eduCOBOTColorScheme.gray
                }}>Rate this lesson</p>
                <Rating name="customized-10" defaultValue={0} max={10} size='large' onChange={(event, newValue) => setLessonRating(newValue as number)} />
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '20px',
                marginTop: '50px'
            }}>
                <p style={{
                    color: eduCOBOTColorScheme.gray
                }}>Rate your experience for this lesson</p>
                <StyledRating
                    name="highlight-selected-only"
                    defaultValue={0}
                    IconContainerComponent={IconContainer}
                    getLabelText={(value: number) => customIcons[value].label}
                    highlightSelectedOnly
                    onChange={(event, newValue) => {
                        setExperienceRating(newValue as number)
                    }}
                />
            </div>
            <div style={{
                margin: '20px 0',
                width: '100%'
            }}>
                <TextField
                    onChange={(e) => {
                        setFeedback(e.target.value)
                    }}
                    value={feedback}
                    multiline
                    fullWidth
                    label="Feedback (Optional)"
                    placeholder="Write your feedback here"
                    variant="outlined"
                />
            </div>
            <Button
                fullWidth
                onClick={() => {
                    submitRating()
                }}
                style={{
                    marginTop: '30px'
                }}
                variant="contained">Submit</Button>
        </Card>
    )
}

export default RatingComponent