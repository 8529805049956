import { useTheme } from '@emotion/react';
import HorizontalTabs from '../../../components/tabs/HorizontalTabs';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { eduCOBOTColorScheme, SettingContext } from '../../../context/SettingsContext';
import { BackendConfigs } from '../../../config.environment';
import { ProjectDataType } from '../../../types/Dashboard';
import { APIResponse } from '../../../types/Response';
import { ContentDialogBox } from '../../../components/dialog-box';
import { Button, Card, CardActions, MenuItem, Select, TextField } from '@mui/material';
import ProjectDescription from '../Projects/Project/Description/Description';
import { toast } from 'react-toastify';
import { routes } from '../../../routes/routes';
import { LessonDataType } from '../../../types/Lesson';
import TotalProgressComponent from './TotalProgress';
import LeaderboardComponent from './Leaderboard';

export interface LessonsProps {
}


export default function Lessons(props: LessonsProps) {
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate()
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [currentFilter, setCurrentFilter] = React.useState("all")
    const [currentGrade, setCurrentGrade] = React.useState(0)
    const [lessons, setLessons] = React.useState<LessonDataType[]>([])
    const [backupLessons, setBackupLessons] = React.useState<LessonDataType[]>([])
    const [searchText, setSearchText] = React.useState("")
    async function fetchLessons() {
        handleGlobalLoading(true, "Fetching lessons")
        setLessons([])
        try {
            const response = await fetch(`${BackendConfigs.url}/GetLessonsAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                setLessons(result.data)
                setBackupLessons(result.data)
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }
    React.useEffect(() => {
        fetchLessons()
    }, [user])

    useEffect(() => {
        if (currentFilter === "all") {
            setLessons(backupLessons)
        } else {
            setLessons(backupLessons.filter((lesson) => !!lesson.editors.some((editor) => editor.includes(currentFilter))))
        }
    }, [currentFilter])

    useEffect(() => {
        if (searchText === "") {
            setLessons(backupLessons)
        }
        else {
            setLessons(backupLessons.filter((lesson) =>
                lesson.name.toLowerCase().includes(searchText.toLowerCase()) ||
                lesson.description.toLowerCase().includes(searchText.toLowerCase()) ||
                lesson.lessonNumber.toString().includes(searchText.toLowerCase()) ||
                lesson.lessonId.toString().includes(searchText.toLowerCase())
            ))
        }
    }, [searchText])
    useEffect(() => {
        if (currentGrade === 0) {
            setLessons(backupLessons)
        }
        else {
            setLessons(backupLessons.filter((lesson) => lesson.lessonLevel === currentGrade
            ))
        }
    }, [currentGrade])
    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                // padding: "15px",
                gap: "10px",
            }}>
                <LeaderboardComponent lessons={lessons} />
                <TotalProgressComponent lessons={lessons} />

            </div>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: settings.screen === "mobile" ? "column-reverse" : "row",
                padding: "15px"
            }}>
                <div style={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                }}>
                    <Button size='small' sx={{
                        borderRadius: "20px"
                    }} variant={currentFilter === "all" ? "contained" : "outlined"} onClick={() => setCurrentFilter("all")}>
                        All
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: "20px"
                    }} variant={currentFilter === "theory" ? "contained" : "outlined"} onClick={() => setCurrentFilter("theory")}>
                        Theory
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: "20px"
                    }} variant={currentFilter === "assembly" ? "contained" : "outlined"} onClick={() => setCurrentFilter("assembly")}>
                        Assembly
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: "20px"
                    }} variant={currentFilter === "circuit" ? "contained" : "outlined"} onClick={() => setCurrentFilter("circuit")}>
                        Circuit
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: "20px"
                    }} variant={currentFilter === "micropython" ? "contained" : "outlined"} onClick={() => setCurrentFilter("micropython")}>
                        Code
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: "20px"
                    }} variant={currentFilter === "iot" ? "contained" : "outlined"} onClick={() => setCurrentFilter("iot")}>
                        IOT
                    </Button>
                    <Button size='small' sx={{
                        borderRadius: "20px"
                    }} variant={currentFilter === "ai" ? "contained" : "outlined"} onClick={() => setCurrentFilter("ai")}>
                        AI
                    </Button>






                </div>



                <div style={{
                    width: "100%",
                    margin: "10px 0px",
                    maxWidth: "300px",
                    display: "flex",
                    alignItems: "center",
                }}>
                    <TextField value={searchText} onChange={(e) => setSearchText(e.target.value)} size='small' fullWidth label="Search lessons" variant="outlined" />
                    <Select
                        variant='outlined'
                        color='primary'
                        size='small'
                        value={currentGrade}
                        onChange={(e) => setCurrentGrade(parseInt(e.target.value as string))}
                        sx={{
                            // borderRadius: "20px",
                            width: "150px",
                            color: eduCOBOTColorScheme.light_blue,
                        }}
                        label="Select Grade"
                    >
                        <MenuItem value={"0"}>All</MenuItem>
                        <MenuItem value={"1"}>Grade-1</MenuItem>
                        <MenuItem value={"2"}>Grade-2</MenuItem>
                        <MenuItem value={"3"}>Grade-3</MenuItem>
                        <MenuItem value={"4"}>Grade-4</MenuItem>
                        <MenuItem value={"5"}>Grade-5</MenuItem>
                        <MenuItem value={"6"}>Grade-6</MenuItem>
                        <MenuItem value={"7"}>Grade-7</MenuItem>
                        <MenuItem value={"8"}>Grade-8</MenuItem>
                        <MenuItem value={"9"}>Grade-9</MenuItem>
                    </Select>
                </div>
            </div>



            <div style={{
                display: "flex",
                justifyContent: 'space-evenly',
                alignItems: "start",
                flexWrap: "wrap",
            }}>
                {
                    lessons.map((lesson) => {
                        return (
                            <LessonCard key={lesson._id} title={lesson.name} image={lesson.thumbnail} lesson={lesson} />
                        )
                    })
                }
                {
                    lessons.length === 0 && <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "30vh",
                        margin: "10px",
                        padding: "10px",
                        boxShadow: "0 0 5px #ccc",
                        borderRadius: "10px"
                    }}>
                        <p style={{ textAlign: "center" }}>No Lesson Found</p>
                    </Card>
                }
            </div>
        </div>
    );
}

function LessonCard({ image, title, lesson }: { image: string, title: string, lesson: LessonDataType }) {
    const navigate = useNavigate()
    const [isHovering, setIsHovering] = React.useState(false)
    const { settings } = useContext(SettingContext)
    const [isReadMore, setIsReadMore] = React.useState(false)
    return (
        <Card onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} style={{
            width: "min(400px, 100%)",
            minHeight: "min(250px, 500px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: "20px",
            margin: "15px",
            background: isHovering ? eduCOBOTColorScheme.green : undefined,
            backdropFilter: "blur(2px)",
            padding: "20px 10px",
            transition: "all 0.2s ease-in-out",
            boxShadow: "1px 5px 5px #cccccc35",
        }}
            sx={{
                ":hover": {
                    scale: "1.1",
                }
            }}
        >
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "2px",
                padding: "10px"
            }}>
                <div style={
                    {
                        width: "30px",
                        height: "30px",
                        minWidth: "30px",
                        color: settings.themeMode != "dark" ? "white" : eduCOBOTColorScheme.black,
                        backgroundColor: settings.themeMode == "dark" ? "white" : eduCOBOTColorScheme.black,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                    }
                }>
                    {
                        lesson.lessonNumber
                    }
                </div>
                <h4 style={{
                    width: "min(300px, 100%)",
                    // color: eduCOBOTColorScheme.black,
                    textAlign: "center",

                }} >
                    {title}
                </h4>
                <div style={
                    {
                        width: "30px",
                        height: "30px",
                        color: settings.themeMode != "dark" ? "white" : eduCOBOTColorScheme.black,
                        backgroundColor: settings.themeMode == "dark" ? "white" : eduCOBOTColorScheme.black,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                    }
                }>
                    G
                </div>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                gap: "2px"
            }}>
                {
                    lesson.tags.map((tag, index) => {
                        return (
                            <p key={index} style={{
                                textAlign: "center",
                                color: isHovering ? eduCOBOTColorScheme.black : eduCOBOTColorScheme.gray,
                                background: isHovering ? eduCOBOTColorScheme.white : "#F2F3F5",
                                borderRadius: "15px",
                                margin: "5px 2px",
                                padding: "3px 10px",
                                fontSize: "0.8em",
                            }} >
                                {tag}
                            </p>
                        )
                    })
                }
            </div>
            <img src={image} alt="" style={{
                maxWidth: "80%",
                maxHeight: "150px",
                margin: "10px auto"
            }} />

            <p style={{
                fontSize: "0.8em",
                color: isHovering ? eduCOBOTColorScheme.white : eduCOBOTColorScheme.gray,
                padding: "10px 0px",
                textAlign: "justify"
            }}>
                {isReadMore ? lesson.description : lesson.description.slice(0, 100)} <Button onClick={() => setIsReadMore(!isReadMore)} size='small' variant='text' sx={{ textTransform: "none" }}>
                    {isReadMore ? "Read Less" : "Read More"}
                </Button>
            </p>
            <div style={{
                width: "100%",
                padding: "10px",
            }}>
                <Button size='small' fullWidth onClick={() => {
                    navigate(routes.DASHBOARD.LESSON.DASHBOARD(lesson.lessonId))
                }} variant={'outlined'} sx={{
                    textTransform: "none",
                    borderRadius: "8px",
                    color: settings.themeMode === "dark" ? eduCOBOTColorScheme.black : undefined,
                    background: eduCOBOTColorScheme.white,
                    ":hover": {
                        background: eduCOBOTColorScheme.white,
                    }
                }}>
                    Open
                </Button>
            </div>
        </Card>
    )
}




{/* <TotalProgressComponent lessons={lessons} progressData={{
                coins: {
                    gained: 50,
                    outof: 100,
                    maximumAchievableForYou: 80
                },
                lessons: {
                    completed: 15,
                    outof: 20,
                },
                assemblyProgress: {
                    completedLessonCount: 5,
                    remainingLessons: 5,
                    coinsGained: 15,
                    time: {
                        timeSpentInSeconds: 5000,
                        speedPerLessonInSeconds: 1000
                    }
                },
                circuitProgress: {
                    completedLessonCount: 5,
                    remainingLessons: 5,
                    coinsGained: 15,
                    time: {
                        timeSpentInSeconds: 5000,
                        speedPerLessonInSeconds: 1000
                    }
                },
                codeProgress: {
                    completedLessonCount: 5,
                    remainingLessons: 5,
                    coinsGained: 15,
                    time: {
                        timeSpentInSeconds: 5000,
                        speedPerLessonInSeconds: 1000
                    }
                },
                iotProgress: {
                    completedLessonCount: 5,
                    remainingLessons: 5,
                    coinsGained: 15,
                    time: {
                        timeSpentInSeconds: 5000,
                        speedPerLessonInSeconds: 1000
                    }
                },
                stats: {
                    time: {
                        timeSpentInSeconds: 5000,
                        speedPerLessonInSeconds: 1000
                    },
                    fastestLesson: {
                        lessonID: "9dde0a0c-c9a5-4239-9085-9699f0ad0c65",
                        timeTakenInSeconds: 1000
                    },
                    slowestLesson: {
                        lessonID: "9dde0a0c-c9a5-4239-9085-9699f0ad0c65",
                        timeTakenInSeconds: 1000
                    },
                    lastInteraction: {
                        lessonID: "9dde0a0c-c9a5-4239-9085-9699f0ad0c65",
                        timeTakenInSeconds: 1000,
                        section: "assembly"
                    }
                }
            }} /> */}