import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../../context/SettingsContext'
import { Button, Card, Container, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../../../../../../routes/routes'
import { BackendConfigs } from '../../../../../../config.environment'
import { APIResponse } from '../../../../../../types/Response'
import { toast } from 'react-toastify'
import { LessonBottomBarComponent } from '../../Common/BottomBar'
import { LessonDataType } from '../../../../../../types/Lesson'
import AssemblyPlayerComponent from './AssemblyPlayer'
type LessonAssemblyPageProps = {}

export default function LessonAssemblyPage({ }: LessonAssemblyPageProps) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const { lessonId } = useParams();
    const [lessonData, seLessonData] = useState<LessonDataType | null>(null)
    async function getProject() {
        console.log(lessonId, user.userData);
        if (!!user.userData && !!lessonId) {
            handleGlobalLoading(true, "Fetching Lesson")
            seLessonData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetLessonByIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id,
                        lessonId: lessonId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    // toast.success(result.message)
                    seLessonData(result.data)
                } else {
                    navigate(routes.DASHBOARD.MAIN)
                }
            } catch (error) {
                toast.error("Error Fetching Lesson")
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }

    useEffect(() => {
        getProject()
    }, [lessonId, user])
    useEffect(() => {

    }, [])
    if (!lessonData) {
        return <></>
    }
    return (
        <>
            <div style={{
                marginBottom: 10,
                overflow: "scroll",
                height: "99dvh",
                display: "flex",
                justifyContent: "center",
            }}>
                <div style={{
                    height: "100%",
                    // padding: "0px",
                    width:"min(100%, 1200px)",
                    padding: "10px",    
                }} >
                    <AssemblyPlayerComponent lessonData={lessonData} setLessonData={seLessonData} />
                </div>
            </div>
            
            {
                lessonData &&
                <LessonBottomBarComponent lesson={lessonData} />
            }
        </>

    )
}


