import { useDrag } from '@use-gesture/react';
import { animated, useSpring } from '@react-spring/web';
import { Button, Card } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { LessonDataType } from '../../../../../../types/Lesson';
import audioPlayer from '../../../../../../components/audio-player';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import CloseIcon from '@mui/icons-material/Close';
import { WidgetDataType } from '../../../../../../types/Dashboard';
import { ContentDialogBox } from '../../../../../../components/dialog-box';
import IOTSuccessCard from '../../ProgressHandler/IOTSuccessCard';
import { updateLessonProgressDetails } from '../../../../../../services/backend/Lessons';
import { UserContext } from '../../../../../../context/UserContext';
const label = { inputProps: { 'aria-label': 'Color switch demo' } };
const MonitorPlayerComponent = ({
    lessonData,
    monitorJSON,
    setMonitorJSON,
    setRefreshData,
    isVisible,
    closePlayer,
    setLessonData
}: {
    lessonData: LessonDataType,
    monitorJSON: WidgetDataType[],
    setMonitorJSON: (widgetsJSON: string) => void,
    setRefreshData: () => void,
    isVisible: boolean,
    closePlayer: () => void
    setLessonData: React.Dispatch<React.SetStateAction<LessonDataType | null>>

}) => {
    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const [position, setPosition] = useState({ x: 50, y: 50 });
    const [currentStatementIndex, setCurrentStatementIndex] = useState(!lessonData.progress.progressReport.IOTProgress.isCompleted ? lessonData.progress.progressReport.IOTProgress.currentStep + 1 || 0 : 0);
    const [isDeducted, setIsDeducted] = useState(lessonData.progress.progressReport.IOTProgress.isDeduction || false);
    let [timer, setTimer] = useState<number>(0)
    const { user } = useContext(UserContext)
    const [isCompleted, setIsCompleted] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            setTimer((pre) => {
                console.log("Timer", pre);
                return pre + 1
            })
        }, 1000)
        return () => clearInterval(interval)
    }, [])
    useEffect(() => {
        setTimer(0)
    }, [currentStatementIndex])

    const bindLessonCircuitPlayer = useDrag((parameters) => {
        setPosition(() => {
            return {
                x: parameters.offset[0],
                y: parameters.offset[1]
            }
        })
    }, { filterTaps: true, delay: true });
    const [isAnyError, setIsAnyError] = useState({
        isError: false,
        errorMessage: ''
    });


    async function updateLesson(isDeduction: boolean = false) {
        if (!!lessonData) {
            if (!lessonData.progress.progressReport.IOTProgress.isCompleted) {
                let isAlreadyUpdated = lessonData.progress.progressReport.IOTProgress.stepsDetails.some((step) => step.stepNumber === currentStatementIndex + 1)
                if (!isAlreadyUpdated) {
                    console.log("Updating Lesson Progress", currentStatementIndex, lessonData.iotInstructions.content.length);

                    let newProgressReportData = {
                        ...lessonData.progress.progressReport,
                        IOTProgress: {
                            ...lessonData.progress.progressReport.IOTProgress,
                            currentTime: lessonData.progress.progressReport.IOTProgress.currentTime + timer,
                            isCompleted: currentStatementIndex === lessonData.iotInstructions.content.length - 1,
                            coins: currentStatementIndex === lessonData.iotInstructions.content.length - 1 ? isDeduction || isDeducted ? 2 : 3 : 0,
                            createdDesign: JSON.stringify(monitorJSON),
                            currentStep: currentStatementIndex,
                            isDeduction: isDeduction || isDeducted,
                            stepsDetails: [
                                ...lessonData.progress.progressReport.IOTProgress.stepsDetails,
                                {
                                    stepNumber: currentStatementIndex,
                                    stepTime: timer
                                }
                            ]
                        }
                    }

                    lessonData.progress.progressReport.IOTProgress.stepsDetails.push({
                        stepNumber: currentStatementIndex,
                        stepTime: timer
                    })

                    let newLessonData = {
                        ...lessonData,
                        progress: {
                            ...lessonData.progress,
                            progressReport: newProgressReportData
                        }
                    }
                    setLessonData(newLessonData)
                    const response = await updateLessonProgressDetails(user.userData?.id as string, lessonData.lessonId, newProgressReportData)
                    console.log(response);

                }
            }
        }

    }



    useEffect(() => {
        audioPlayer.stop()
        audioPlayer.play(lessonData.iotInstructions.content[currentStatementIndex].audio, isAudioMuted)
        setMonitorJSON(lessonData.iotInstructions.content[currentStatementIndex].valid)
        setRefreshData()
    }, [])
    function previousStatement() {
        if (currentStatementIndex > 0) {
            setCurrentStatementIndex(currentStatementIndex - 1)
        } else {

        }
    }

    function isCurrentStatementResponseValid() {
        if (!isEqual(monitorJSON, JSON.parse(lessonData.iotInstructions.content[currentStatementIndex + 1].valid) as WidgetDataType[])) {
            console.log("invalid code");
            setIsAnyError({
                isError: true,
                errorMessage: "Invalid Code"
            })
            return false
        }
        return true
    }
    function nextStatement() {
        if (currentStatementIndex < lessonData.iotInstructions.content.length - 1 && isCurrentStatementResponseValid()) {
            setCurrentStatementIndex(currentStatementIndex + 1)
            updateLesson()
            audioPlayer.stop()
            audioPlayer.play(lessonData.iotInstructions.content[currentStatementIndex + 1].audio, isAudioMuted)
            setMonitorJSON(lessonData.iotInstructions.content[currentStatementIndex + 1].valid)
            setRefreshData()
        } else {

        }
    }
    function isEqual(userJSON: WidgetDataType[], correctJSON: WidgetDataType[]) {
        console.log("user", userJSON, "correct", correctJSON);
        for (let i = 0; i < correctJSON.length; i++) {
            const sameTypeJSON = userJSON.some((widget) => {
                // console.log(widget.widgetType === correctJSON[i].widgetType , widget.linkedVariable == correctJSON[i].linkedVariable , widget.widgetTitleText == correctJSON[i].widgetTitleText);

                return widget.widgetType == correctJSON[i].widgetType && widget.linkedVariable == correctJSON[i].linkedVariable && widget.widgetTitleText == correctJSON[i].widgetTitleText
            })
            console.log("sameTypeJSON", sameTypeJSON);

            if (!sameTypeJSON) {
                return false
            }
            // return false
        }
        // console.log("true");

        return true
    }
    function correctTheCode() {
        console.log("currentStatementIndex", currentStatementIndex);

        if (currentStatementIndex < lessonData.codeInstructions.content.length - 1) {
            setCurrentStatementIndex(currentStatementIndex + 1)
            updateLesson(true)
            audioPlayer.stop()
            audioPlayer.play(lessonData.iotInstructions.content[currentStatementIndex + 1].audio, isAudioMuted)
            setMonitorJSON(lessonData.iotInstructions.content[currentStatementIndex + 1].valid)
            setRefreshData()
        } else {

        }
    }
    useEffect(() => {

        if (currentStatementIndex < lessonData.iotInstructions.content.length - 1)
            if (!isEqual(monitorJSON, JSON.parse(lessonData.iotInstructions.content[currentStatementIndex + 1].valid) as WidgetDataType[])) {
                //         console.log("user", xml);
                //         console.log("correct", lessonData.codeInstructions.content[currentStatementIndex + 1].valid);
                //         console.log("correct one ", convertXML.convertXML(lessonData.codeInstructions.content[currentStatementIndex + 1].valid));
                //         console.log("user", convertXML.convertXML(xml));
            }

            else {
                if (currentStatementIndex < lessonData.iotInstructions.content.length - 1) {
                    setCurrentStatementIndex(currentStatementIndex + 1)
                    updateLesson()
                    audioPlayer.stop()
                    audioPlayer.play(lessonData.iotInstructions.content[currentStatementIndex + 1].audio, isAudioMuted)
                }
            }
    }, [monitorJSON])
    if (!isVisible) {
        return null
    }
    return (
        <>
            <div>
                {
                    !lessonData.progress.progressReport.IOTProgress.isCompleted ?
                        <Button color='error' variant='contained' sx={{
                            position: "fixed",
                            top: 0,
                            right: 0,
                            margin: 1,
                            zIndex: 99999
                        }}
                        >
                            {(timer / 60).toFixed(0)} min {timer % 60} sec
                        </Button> : <>

                            <Button color='warning' variant='contained' sx={{
                                position: "fixed",
                                top: 0,
                                right: 0,
                                margin: 1,
                                zIndex: 99999
                            }}
                            >
                                Completed With {(lessonData.progress.progressReport.IOTProgress.currentTime / 60).toFixed(0)} min {lessonData.progress.progressReport.IOTProgress.currentTime % 60} sec
                            </Button>
                        </>

                }
            </div>
            <animated.div {...bindLessonCircuitPlayer()} style={{
                x: position.x,
                y: position.y,
                maxWidth: "80vw",
                width: "100%",
                top: "5%",
                left: "50%",
                touchAction: 'none',
                position: "fixed"
            }} >
                <Card sx={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    cursor: "pointer",
                    maxWidth: "600px",
                    borderRadius: "10px",
                    position: "relative",
                    padding: "10px",
                }}>
                    {
                        isCompleted ? <>
                            Congratulations, You have successfully completed the Coding Section of the Lesson. You can now proceed to the next section of the lesson.
                        </> : <>
                            <div style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                zIndex: 9999
                            }}>
                                <Button size='small' variant='text' onClick={() => {
                                    setIsAudioMuted(!isAudioMuted)
                                }} >

                                    {
                                        isAudioMuted ?
                                            <VolumeOffIcon onClick={() => {
                                                audioPlayer.resume()
                                            }} /> :
                                            <VolumeUpIcon onClick={() => {
                                                audioPlayer.stop()
                                            }} />
                                    }
                                </Button>
                                <Button size='small' variant='text' onClick={() => {
                                    closePlayer()
                                }} >

                                    <CloseIcon fontSize="small" />
                                </Button>
                            </div>

                            <h3>
                                {
                                    currentStatementIndex === 0 ? "Task" : currentStatementIndex === lessonData.iotInstructions.content.length - 1 ? "Conclusion" : lessonData.iotInstructions.content[currentStatementIndex].title
                                }
                            </h3>
                            <p>
                                {
                                    lessonData.iotInstructions.content[currentStatementIndex].description
                                }
                            </p>
                        </>
                    }
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end"
                    }}>
                        <Button onClick={previousStatement} disabled={currentStatementIndex === 0 || isCompleted} color='warning' variant='contained' sx={{
                            textTransform: "none",
                            margin: 1
                        }}>
                            Previous
                        </Button>
                        {
                            currentStatementIndex === lessonData.iotInstructions.content.length - 1 ? <>
                                <Button disabled={isCompleted} onClick={() => {
                                    setIsCompleted(true)
                                    updateLesson()
                                }} color='secondary' variant='contained' sx={{
                                    textTransform: "none",
                                    margin: 1
                                }}>
                                    End
                                </Button>

                            </> : <>
                                <Button onClick={nextStatement} disabled={currentStatementIndex === lessonData.iotInstructions.content.length - 1} color='success' variant='contained' sx={{
                                    textTransform: "none",
                                    margin: 1
                                }}>
                                    Next
                                </Button>

                            </>
                        }
                    </div>
                </Card>
            </animated.div>
            <ContentDialogBox
                isOpen={isAnyError.isError}
                onClose={() => setIsAnyError({
                    ...isAnyError,
                    isError: false
                })}
                title="Code Error"
                maxWidth="xs"
                content={<>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>

                        <img src="https://cdn0.iconfinder.com/data/icons/shift-free/32/Error-512.png" style={{
                            maxWidth: "200px"
                        }} alt="" />
                        <h4>
                            {isAnyError.errorMessage}
                        </h4>

                    </div>
                </>}
                actions={
                    <>
                        <Button variant='contained' color='success' onClick={() => setIsAnyError({
                            isError: false,
                            errorMessage: ''
                        })}
                        >Try Again</Button>
                        <Button variant='contained' color='warning' onClick={() => {
                            correctTheCode()
                            setIsAnyError({
                                isError: false,
                                errorMessage: ''
                            })
                        }}>
                            Correct It
                        </Button>

                    </>
                }
            />
            <IOTSuccessCard lesson={lessonData} isCompleted={isCompleted} />

        </>
    )
}
export default MonitorPlayerComponent
