export const framerAnimation = {
    left2right: {
        initial: {
            left: "-1000px"
        },
        animate: {
            left: "0"
        },
        exit: {
            left: "0"
        },
        transition: {
            duration: 1
        }
    },
    right2left: {
        initial: {
            left: "100vw"
        },
        animate: {
            left: "0"
        },
        exit: {
            left: "0"
        },
        transition: {
            duration: 1
        }
    },
    scale1: {
        initial: {
            scale: 0
        },
        animate: {
            scale: 1
        },
        exit: {
            scale: 0
        },
        transition: {
            duration: 1
        }
    }
}