import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../../context/SettingsContext'
import { Button, Card, Container, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../../../../../../routes/routes'
import { BackendConfigs } from '../../../../../../config.environment'
import { APIResponse } from '../../../../../../types/Response'
import { toast } from 'react-toastify'
import { LessonBottomBarComponent } from '../../Common/BottomBar'
import { LessonDataType } from '../../../../../../types/Lesson'
import audioPlayer from '../../../../../../components/audio-player'
import MonitorDesigner from './MonitorDesigner'
import { WidgetDataType } from '../../../../../../types/Dashboard'
import MonitorPlayerComponent from './MonitorPlayer'








type LessonIOTMonitorWorkspacePageProps = {}

export default function LessonIOTMonitorWorkspacePage({ }: LessonIOTMonitorWorkspacePageProps) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const { lessonId } = useParams();
    const [lessonData, setLessonData] = useState<LessonDataType | null>(null)
    const [refreshData, setRefreshData] = useState<boolean>(false)
    const [isGuideVisible, setIsGuideVisible] = useState(true);
    const [widgets, setWidgets] = useState<WidgetDataType[]>([])

    const [keys, setKeys] = useState<{
        [key: string]: string
    }>({

    })

    async function getProject() {
        console.log(lessonId, user.userData);
        if (!!user.userData && !!lessonId) {
            handleGlobalLoading(true, "Fetching Lesson")
            setLessonData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetLessonByIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id,
                        lessonId: lessonId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    // toast.success(result.message)
                    setLessonData(result.data)
                } else {
                    navigate(routes.DASHBOARD.MAIN)
                }
            } catch (error) {
                toast.error("Error Fetching Lesson")
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }
    function deleteWidget(widgetId: string) {
        setWidgets((prev) => {
            return prev.filter((widget) => widget.widgetId !== widgetId)
        })
    }
    useEffect(() => {
        getProject()
    }, [lessonId, user])
    if (!lessonData) {
        return <></>
    }
    return (
        <>
            <div style={{
                overflow: "scroll",
                height: window.innerHeight,
                maxWidth: "100vw",
                overflowX: "hidden"
            }}>
                {
                    lessonData ? <>
                        <div key={refreshData ? "1" : "2"}>
                            {
                                <MonitorDesigner deleteWidget={deleteWidget} lessonData={lessonData} widgets={widgets} setWidgets={setWidgets} previewMode={false} reRender={() => {
                                    setRefreshData(!refreshData)
                                }} />
                            }
                        </div>
                    </> : <></>
                }
                <MonitorPlayerComponent closePlayer={() => {
                    setIsGuideVisible(false)
                }} isVisible={isGuideVisible} setRefreshData={() => { setRefreshData(!refreshData) }} lessonData={lessonData} setMonitorJSON={(widgetsJSON: string) => {
                    setWidgets(JSON.parse(widgetsJSON))
                }} monitorJSON={widgets} setLessonData={setLessonData}/>
            </div>
            {
                lessonData &&
                <LessonBottomBarComponent toggleHelp={() => { setIsGuideVisible(!isGuideVisible); window.navigator.clipboard.writeText(JSON.stringify(widgets)) }} lesson={lessonData} autoFill={() => {
                    setWidgets(JSON.parse(lessonData.iotInstructions.finalMonitorJSON) as any)
                    setRefreshData(!refreshData)
                }} />
            }
        </>

    )
}
