import { Box, Button, Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material"
import { LessonDataType } from "../../../../../../types/Lesson"
import { SetStateAction, useContext, useEffect, useState } from "react";
import audioPlayer from "../../../../../../components/audio-player";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import CloseIcon from '@mui/icons-material/Close';
import { framerAnimation } from "../../../../../../theme/animation/MotionConfigs";
import { motion } from 'framer-motion'
import { SettingContext } from "../../../../../../context/SettingsContext";
import { ContentDialogBox } from "../../../../../../components/dialog-box";
import AssemblySuccessCard from "../../ProgressHandler/AssemblySuccessCard";
import { updateLessonProgressDetails } from "../../../../../../services/backend/Lessons";
import { UserContext } from "../../../../../../context/UserContext";
export default function AssemblyPlayerComponent({
    lessonData,
    setLessonData
}: {
    lessonData: LessonDataType,
    setLessonData: React.Dispatch<React.SetStateAction<LessonDataType | null>>
}) {
    const [currentStatementIndex, setCurrentStatementIndex] = useState<number>(!lessonData.progress.progressReport.AssemblyProgress.isCompleted ? lessonData.progress.progressReport.AssemblyProgress.currentStep || 0 : 0)
    let [timer, setTimer] = useState<number>(0)
    const [isCompleted, setIsCompleted] = useState(false);
    const { settings } = useContext(SettingContext)
    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const [position, setPosition] = useState({ x: 50, y: 50 });
    const [currentSubStatementIndex, setCurrentSubStatementIndex] = useState(0);
    const [preview, setPreview] = useState({
        isOpen: false,
        image: "",
    });
    const { user } = useContext(UserContext)

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer((pre) => {
                console.log("Timer", pre);
                return pre + 1
            })
        }, 1000)
        return () => clearInterval(interval)
    }, [])
    useEffect(() => {
        setTimer(0)
    }, [currentStatementIndex])

    async function updateLesson() {
        if (!!lessonData) {
            if (!lessonData.progress.progressReport.AssemblyProgress.isCompleted) {
                let isAlreadyUpdated = lessonData.progress.progressReport.AssemblyProgress.stepsDetails.some((step) => step.stepNumber === currentStatementIndex + 1)
                if (!isAlreadyUpdated) {
                    console.log("Updating Lesson Progress", currentStatementIndex, lessonData.assemblyInstructions.content.length);

                    let newProgressReportData = {
                        ...lessonData.progress.progressReport,
                        AssemblyProgress: {
                            ...lessonData.progress.progressReport.AssemblyProgress,
                            currentTime: lessonData.progress.progressReport.AssemblyProgress.currentTime + timer,
                            isCompleted: currentStatementIndex === lessonData.assemblyInstructions.content.length - 1,
                            currentStep: currentStatementIndex,
                            coins: currentStatementIndex === lessonData.assemblyInstructions.content.length - 1 ? 1 : 0,
                            stepsDetails: [
                                ...lessonData.progress.progressReport.AssemblyProgress.stepsDetails,
                                {
                                    stepNumber: currentStatementIndex,
                                    stepTime: timer
                                }
                            ]
                        }
                    }

                    lessonData.progress.progressReport.AssemblyProgress.stepsDetails.push({
                        stepNumber: currentStatementIndex,
                        stepTime: timer
                    })

                    let newLessonData = {
                        ...lessonData,
                        progress: {
                            ...lessonData.progress,
                            progressReport: newProgressReportData
                        }
                    }
                    setLessonData(newLessonData)
                    const response = await updateLessonProgressDetails(user.userData?.id as string, lessonData.lessonId, newProgressReportData)
                    console.log(response);

                }
            }
        }

    }


    useEffect(() => {
        // audioPlayer.stop()
        // audioPlayer.play(lessonData.assemblyInstructions.content[currentStatementIndex].audio, isAudioMuted)
        // console.log(lessonData);

    }, [currentStatementIndex])
    function previousStatement() {
        if (currentStatementIndex > 0) {
            setCurrentStatementIndex(currentStatementIndex - 1)
        } else {

        }
    }

    function nextStatement() {
        if (currentSubStatementIndex < lessonData.assemblyInstructions.content[currentStatementIndex].parts.length) {
            setCurrentSubStatementIndex(currentSubStatementIndex + 1)
        } else {
            if (currentStatementIndex < lessonData.assemblyInstructions.content.length - 1) {
                setCurrentStatementIndex(currentStatementIndex + 1)
                updateLesson()
                for (let i = 0; i <= lessonData.assemblyInstructions.content[currentStatementIndex + 1].parts.length; i++) {
                    setTimeout(() => {
                        setCurrentSubStatementIndex(i)
                    }, 500 * i)
                }
            } else {

            }
        }
    }

    return (
        <>
            <div>
                {
                    !lessonData.progress.progressReport.AssemblyProgress.isCompleted ?
                        <Button color='error' variant='contained' sx={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            margin: 1,
                            zIndex: 99999
                        }}
                        >
                            {(timer / 60).toFixed(0)} min {timer % 60} sec
                        </Button> : <>

                            <Button color='warning' variant='contained' sx={{
                                position: "fixed",
                                top: 0,
                                left: 0,
                                margin: 1,
                                zIndex: 99999
                            }}
                            >
                                Completed With {(lessonData.progress.progressReport.AssemblyProgress.currentTime / 60).toFixed(0)} min {lessonData.progress.progressReport.AssemblyProgress.currentTime % 60} sec
                            </Button>
                        </>

                }
            </div>
            <Card style={{
                minHeight: settings.screen === "desktop" ? "95vh" : "auto",
                width: "100%",
                // margin: "10px",
                padding: "10px",
                boxShadow: "0 0 5px #ccc",
                borderRadius: "10px",
                background: "#83cceb",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}>
                <div style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    zIndex: 999
                }}>
                    <Button size='small' variant='text' onClick={() => {
                        setIsAudioMuted(!isAudioMuted)
                    }} >

                        {
                            isAudioMuted ?
                                <VolumeOffIcon onClick={() => {
                                    // audioPlayer.resume()
                                }} /> :
                                <VolumeUpIcon onClick={() => {
                                    // audioPlayer.stop()
                                }} />
                        }
                    </Button>
                    {/* <Button size='small' variant='text' onClick={() => {
                    setIsAudioMuted(!isAudioMuted)
                }} >

                    <CloseIcon />
                </Button> */}
                </div>
                {
                    // isCompleted ? <>

                    //     <div

                    //         style={{
                    //             minHeight: "70vh",
                    //             display: "flex",
                    //             justifyContent: "center",
                    //             alignItems: "center"
                    //         }}>
                    //         <h1 style={{
                    //             textAlign: "center"
                    //         }}>
                    //             Congratulations, You have successfully completed the Assembly Section of the Lesson. You can now proceed to the next section of the lesson.
                    //         </h1>
                    //     </div>
                    // </> :
                     <>

                        {
                            lessonData.assemblyInstructions.content[currentStatementIndex].parts.length > 0 ? <>

                                <h3>
                                    {
                                        currentStatementIndex === 0 ? "Task" : lessonData.assemblyInstructions.content[currentStatementIndex].title
                                    }
                                </h3>
                                <p>
                                    {
                                        lessonData.assemblyInstructions.content[currentStatementIndex].description
                                    }
                                </p>
                                <CardContent style={{
                                    minHeight: "70vh",
                                }}>

                                    <Box sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 1, md: 1 }}>
                                            <Grid item xs={1} sm={1} md={1} >
                                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 1, md: lessonData.assemblyInstructions.content[currentStatementIndex].parts.length }}>
                                                    {
                                                        lessonData.assemblyInstructions.content[currentStatementIndex].parts.map((part, index) => {
                                                            if (currentSubStatementIndex >= index)
                                                                return (
                                                                    <Grid item xs={1} sm={1} md={1} key={index}>
                                                                        <motion.div
                                                                            initial={framerAnimation.right2left.initial}
                                                                            animate={framerAnimation.right2left.animate}
                                                                            exit={framerAnimation.right2left.exit}
                                                                            transition={framerAnimation.right2left.transition}
                                                                        >
                                                                            <Card onClick={() => {
                                                                                setPreview({
                                                                                    isOpen: true,
                                                                                    image: part.image
                                                                                })
                                                                            }} elevation={0} style={{
                                                                                margin: "10px",
                                                                                borderRadius: "10px",
                                                                                // boxShadow: "0 0 5px #ccc",
                                                                                background: "transparent",
                                                                                // background: "#0000000A",
                                                                            }}>
                                                                                <CardContent style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    flexDirection: "column"
                                                                                }}>
                                                                                    <img src={
                                                                                        part.image
                                                                                    } alt="" style={{
                                                                                        maxWidth: "200px",
                                                                                        maxHeight: "200px",
                                                                                    }} />
                                                                                    <Typography gutterBottom variant="h5" component="div">
                                                                                        {part.title}
                                                                                    </Typography>
                                                                                    <Typography variant="body2" color="text.secondary">
                                                                                        {part.description}
                                                                                    </Typography>
                                                                                </CardContent>
                                                                            </Card>
                                                                        </motion.div>
                                                                    </Grid>
                                                                )
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                            {
                                                currentSubStatementIndex === lessonData.assemblyInstructions.content[currentStatementIndex].parts.length ?

                                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 1, md: lessonData.assemblyInstructions.content[currentStatementIndex].result.length }}>
                                                        {
                                                            lessonData.assemblyInstructions.content[currentStatementIndex].result.map((result, index) => {
                                                                return (
                                                                    <Grid item xs={1} sm={1} md={1} key={index}>
                                                                        <motion.div
                                                                            initial={framerAnimation.scale1.initial}
                                                                            animate={framerAnimation.scale1.animate}
                                                                            exit={framerAnimation.scale1.exit}
                                                                            transition={framerAnimation.scale1.transition}
                                                                            key={index}
                                                                        >
                                                                            <Card onClick={() => {
                                                                                setPreview({
                                                                                    isOpen: true,
                                                                                    image: result.image
                                                                                })
                                                                            }} elevation={0} style={{
                                                                                margin: "10px",
                                                                                borderRadius: "10px",
                                                                                // boxShadow: "0 0 5px #ccc",
                                                                                background: "transparent",
                                                                                // background: "#0000000A",
                                                                            }}>
                                                                                <CardContent style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    flexDirection: "column"
                                                                                }}>
                                                                                    <img src={result.image} alt="" style={{
                                                                                        maxWidth: "400px",
                                                                                        maxHeight: "300px",
                                                                                    }} />
                                                                                    <Typography gutterBottom variant="h5" component="div">
                                                                                        {result.title}
                                                                                    </Typography>
                                                                                    <Typography variant="body2" color="text.secondary">
                                                                                        {result.description}
                                                                                    </Typography>
                                                                                </CardContent>
                                                                            </Card>
                                                                        </motion.div>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                        {/* <motion.div
                                                initial={framerAnimation.scale1.initial}
                                                animate={framerAnimation.scale1.animate}
                                                exit={framerAnimation.scale1.exit}
                                                transition={framerAnimation.scale1.transition}
                                            >
                                                <Card elevation={0} style={{
                                                    margin: "10px",
                                                    borderRadius: "10px",
                                                    // boxShadow: "0 0 5px #ccc",
                                                    background: "transparent",
                                                    // background: "#0000000A",
                                                }}>
                                                    <CardActionArea>
                                                        <CardContent style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            flexDirection: "column"
                                                        }}>
                                                            <img src={lessonData.assemblyInstructions.content[currentStatementIndex].final.image} alt="" style={{
                                                                maxWidth: "100%",
                                                                margin: "auto"
                                                            }} />
                                                            <Typography gutterBottom variant="h5" component="div">
                                                                {lessonData.assemblyInstructions.content[currentStatementIndex].final.title}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {lessonData.assemblyInstructions.content[currentStatementIndex].final.description}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </motion.div> */}
                                                    </Grid> : <></>
                                            }
                                        </Grid>
                                    </Box>
                                </CardContent>
                            </> : <>
                                <h3>
                                    {
                                        currentStatementIndex === 0 ? "Information" : lessonData.assemblyInstructions.content[currentStatementIndex].title.replace("Step", "Task")
                                    }
                                </h3>
                                <div

                                    style={{
                                        minHeight: "70vh",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                    <h1 style={{
                                        textAlign: "center"
                                    }}>
                                        {
                                            lessonData.assemblyInstructions.content[currentStatementIndex].description
                                        }
                                    </h1>
                                </div>
                            </>
                        }
                    </>
                }

                <div style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end"
                }}>
                    <Button onClick={previousStatement} disabled={currentStatementIndex === 0 || isCompleted} color='warning' variant='contained' sx={{
                        textTransform: "none",
                        margin: 1
                    }}>
                        Previous
                    </Button>
                    {
                        currentStatementIndex === lessonData.assemblyInstructions.content.length - 1 && currentSubStatementIndex === lessonData.assemblyInstructions.content[currentStatementIndex].parts.length ? <>
                            <Button disabled={isCompleted} onClick={() => {
                                setIsCompleted(true)
                                updateLesson()
                            }} color='secondary' variant='contained' sx={{
                                textTransform: "none",
                                margin: 1
                            }}>
                                Complete
                            </Button>
                        </> : <>

                            <Button onClick={nextStatement} color='success' variant='contained' sx={{
                                textTransform: "none",
                                margin: 1
                            }}>
                                Next
                            </Button>

                        </>
                    }

                </div>
            </Card>
            <ContentDialogBox
                isOpen={preview.isOpen}
                onClose={() => {
                    setPreview({
                        isOpen: false,
                        image: ""
                    })
                }}
                fullScreen={true}
                content={<div style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                    background: "#C5E7F6"
                }}>
                    <Button variant='contained' color='warning' style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        zIndex: 99999999999
                    }} onClick={() => {
                        setPreview({
                            isOpen: false,
                            image: ""
                        })
                    }}>
                        <CloseIcon />
                    </Button>
                    <img src={preview.image} style={{
                        maxHeight: "95vh",
                        maxWidth: "95vw"
                    }} alt="" />
                </div>}
            />
            <AssemblySuccessCard lesson={lessonData} isCompleted={isCompleted} />
        </>

    )
}