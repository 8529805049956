import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../context/SettingsContext'
import { Button, Card, Container, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../../../../../routes/routes'
import { BackendConfigs } from '../../../../../config.environment'
import { APIResponse } from '../../../../../types/Response'
import { toast } from 'react-toastify'
import { LessonBottomBarComponent } from '../Common/BottomBar'
import { LessonDataType } from '../../../../../types/Lesson'
import LessonDescription from './Description'
import { ArrowLeft } from 'lucide-react'
type LessonDashboardPageProps = {}

export default function LessonDashboardPage({ }: LessonDashboardPageProps) {
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const { lessonId } = useParams();
    const [lessonData, seLessonData] = useState<LessonDataType | null>(null)
    async function getLessons() {
        console.log(lessonId, user);
        if (settings.globalLoading.loading) return
        if (user.isLogin && lessonId) {
            handleGlobalLoading(true, "Fetching Lesson")
            seLessonData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetLessonByIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id,
                        lessonId: lessonId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    // toast.success(result.message)
                    // console.log(result.data.editors);
                    seLessonData(result.data)
                } else {
                    navigate(routes.DASHBOARD.MAIN)
                }
            } catch (error) {
                toast.error("Error Fetching Lesson")
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }

    useEffect(() => {
        getLessons()
    }, [lessonId, user])

    return (
        <>
            <div style={{
                overflow: "scroll"
            }}>
                <Button variant='contained'  style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    margin: "10px",
                    zIndex:999999999
                }} onClick={() => {
                    navigate(routes.DASHBOARD.LESSONS)
                }}>
                    <ArrowLeft style={{
                        marginRight: "5px"
                    }}/>  Dashboard
                </Button>
                <Container>
                    {
                        lessonData && <LessonDescription lesson={lessonData} />
                    }
                </Container>
            </div>

        </>

    )
}