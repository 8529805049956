import { Palette } from '@mui/material/styles'
import { Typography } from '@mui/material/styles/createTypography'
export type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P]
}
export interface AppTheme {
    dark: {
        palette: DeepPartial<Palette>
        typography?: DeepPartial<Typography>
    }
    light: {
        palette: DeepPartial<Palette>
        typography?: DeepPartial<Typography>
    }
}

export const CustomTheme: AppTheme = {
    dark: {
        palette: {
            mode: 'dark',
            background: {
                default: '#161C24',
                paper: '#212B36',
            },
        },
        typography: {
            button: {
                textTransform: 'none',
            },
        },
    },
    light: {
        palette: {
            mode: 'light',

            background: {
                default: '#ffffff',
                paper: '#fafafa',
            },
        },
        typography: {
            button: {
                textTransform: 'none',
            },
        },
    },
}
