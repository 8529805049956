import { Button, Card, CardContent, CardMedia, MenuItem, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { DashboardNavConfig } from './config'
import { Link } from 'react-router-dom'
import DashboardNavbarIcon from './config'
import { UserContext } from '../../context/UserContext'
import { routes } from '../../routes/routes'
import { eduCOBOTColorScheme, SettingContext } from '../../context/SettingsContext'

type Props = {}

function DashboardNavbar({
}: Props) {
    const { user } = useContext(UserContext)
    const { settings } = useContext(SettingContext)
    return (
        <div style={{
            height: "100vh",
            borderRight: "0.1px dotted #00000055",
            overflow: "auto",
            position: "relative",
            minWidth: "300px",
            // background:"white",
            // zIndex:9999
        }}>
            <Link style={{
                width: "100%",
                height: "100%",
            }} to={`${routes.ROOT}`}
            >
                <Typography component={'div'} style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "20vh"
                }}>

                    <CardMedia
                        component="img"
                        height="100%"
                        image="https://storage.googleapis.com/educobot-robotics/images/logo/Innovator-Main.png"
                        sx={{
                            width: "130px",
                        }}
                    />
                </Typography>
            </Link>
            <Typography component='div' style={{
                padding: "10px",
            }}>
                <Card sx={{
                    padding: "12px 15px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: settings.themeMode === "dark" ? "#252C35" : "#F2F3F5",
                    outline: "none",
                    border: "none",
                    borderRadius: "10px",
                }} variant='outlined' >
                    <CardMedia
                        component="img"
                        alt="loading"
                        height="100%"
                        image={"https://ui-avatars.com/api/?name=" + user.userData?.name}
                        sx={{
                            width: "40px",
                            borderRadius: "50%",
                            margin: "5px",
                        }}
                    />
                    <Typography sx={{
                        padding: "5px",
                        flexGrow: 1
                    }}>
                        <Typography component='p' style={{
                            fontSize: "15px",
                            fontWeight: "500"
                        }}>
                            {user.userData?.name}
                        </Typography>
                        <Typography component='p' style={{
                            fontSize: "12px",
                            color: eduCOBOTColorScheme.gray
                        }}>
                            {user.userData?.role}
                        </Typography>
                    </Typography>






                </Card>
            </Typography>
            <Typography style={{
                zIndex: 10
            }}>
                {
                    DashboardNavConfig.navigation.map((element, index) => {
                        const isActive = window.location.href.includes(element.link);

                        return <MenuItem id={element.title} key={index} sx={{
                            margin: "5px 10px",
                            borderRadius: "5px",
                            color: eduCOBOTColorScheme.gray,
                            backgroundColor: isActive ? "#2367D215" : "inherit"
                        }}>
                            <Link to={element.link} style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                width: "100%",
                                color: isActive ? "#2367D2" : "inherit"
                            }}>
                                <Typography sx={{
                                    padding: "10px",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    {DashboardNavbarIcon(element.link)}
                                </Typography>
                                <Typography style={{
                                    fontSize: "15px"
                                }}>
                                    {element.title}
                                </Typography>
                            </Link>
                        </MenuItem>
                    })
                }
                {
                    user && user.userData && (user.userData.id == "001329.d54007cf2bbc44f5ac86df4e9c28f027.0951" || user.userData.id === "6547bbc9-6964-412e-a4e4-3ac1423a0a06") && <>
                        <MenuItem sx={{
                            margin: "5px 10px",
                            borderRadius: "5px",
                            color: eduCOBOTColorScheme.gray,
                        }}>
                            <Link to={routes.DASHBOARD.LESSON_BUILDER_LESSON_LIST} style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                width: "100%",
                                color: window.location.href.includes(routes.DASHBOARD.LESSON_BUILDER_LESSON_LIST) ? "#2367D2" : "inherit"
                            }}>
                                <Typography sx={{
                                    padding: "10px",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    {DashboardNavbarIcon(routes.DASHBOARD.LESSON_BUILDER_LESSON_LIST)}
                                </Typography>
                                <Typography style={{
                                    fontSize: "15px"
                                }}>
                                    Lesson Builder
                                </Typography>
                            </Link>
                        </MenuItem>
                    </>
                }
            </Typography>
            <div style={{
                position: "fixed",
                bottom: "0",
                opacity: 0.3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 0,
                width: "250px"
            }}>
                <img src="https://media-s3-us-east-1.ceros.com/ipsos/images/2023/10/13/6a6ba365887b997d67f1472b90d18f65/wired-outline-746-technology-integrated-circuits.gif" width={"50%"} alt="" style={{
                    margin: "30px",
                }} />
                <Button sx={{
                    position: "absolute",
                    bottom: "0",
                    opacity: 0.9,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textTransform: "none",
                    fontSize: "12px",

                }} size='small' variant='outlined' color="primary">Powered by <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/website-matches/eduCOBOT_one_line.svg" style={{
                    height: "20px",
                    margin: "5px",
                }} alt="" /></Button>
            </div>
        </div>
    )
}

export default DashboardNavbar