import { Order, PythonGenerator } from 'blockly/python';
import Blockly from './micropython-blocks';

const stringUtils=Blockly.utils.string
const NameType=Blockly.Names.NameType;
const Variables=Blockly.Variables;
type  Block=Blockly.Block
export function controls_if(block: Block, generator: PythonGenerator) {
    // If/elseif/else condition.
    let n = 0;
    let code = '',
        branchCode,
        conditionCode;
    if (generator.STATEMENT_PREFIX) {
        // Automatic prefix insertion is switched off for this block.  Add manually.
        code += generator.injectId(generator.STATEMENT_PREFIX, block);
    }
    do {
        conditionCode =
            generator.valueToCode(block, 'IF' + n, Order.NONE) || 'False';
        branchCode = generator.statementToCode(block, 'DO' + n) || generator.PASS;
        if (generator.STATEMENT_SUFFIX) {
            branchCode =
                generator.prefixLines(
                    generator.injectId(generator.STATEMENT_SUFFIX, block),
                    generator.INDENT,
                ) + branchCode;
        }
        code += (n === 0 ? 'if ' : 'elif ') + conditionCode + ':\n' + branchCode;
        n++;
    } while (block.getInput('IF' + n));

    if (block.getInput('ELSE') || generator.STATEMENT_SUFFIX) {
        branchCode = generator.statementToCode(block, 'ELSE') || generator.PASS;
        if (generator.STATEMENT_SUFFIX) {
            branchCode =
                generator.prefixLines(
                    generator.injectId(generator.STATEMENT_SUFFIX, block),
                    generator.INDENT,
                ) + branchCode;
        }
        code += 'else:\n' + branchCode;
    }
    return code;
}

export const controls_ifelse = controls_if;

export function logic_compare(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Comparison operator.
    const OPERATORS = {
        'EQ': '==',
        'NEQ': '!=',
        'LT': '<',
        'LTE': '<=',
        'GT': '>',
        'GTE': '>=',
    };
    type OperatorOption = keyof typeof OPERATORS;
    const operator = OPERATORS[block.getFieldValue('OP') as OperatorOption];
    const order = Order.RELATIONAL;
    const argument0 = generator.valueToCode(block, 'A', order) || '0';
    const argument1 = generator.valueToCode(block, 'B', order) || '0';
    const code = argument0 + ' ' + operator + ' ' + argument1;
    return [code, order];
}

export function logic_operation(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Operations 'and', 'or'.
    const operator = block.getFieldValue('OP') === 'AND' ? 'and' : 'or';
    const order = operator === 'and' ? Order.LOGICAL_AND : Order.LOGICAL_OR;
    let argument0 = generator.valueToCode(block, 'A', order);
    let argument1 = generator.valueToCode(block, 'B', order);
    if (!argument0 && !argument1) {
        // If there are no arguments, then the return value is false.
        argument0 = 'False';
        argument1 = 'False';
    } else {
        // Single missing arguments have no effect on the return value.
        const defaultArgument = operator === 'and' ? 'True' : 'False';
        if (!argument0) {
            argument0 = defaultArgument;
        }
        if (!argument1) {
            argument1 = defaultArgument;
        }
    }
    const code = argument0 + ' ' + operator + ' ' + argument1;
    return [code, order];
}

export function logic_negate(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Negation.
    const argument0 =
        generator.valueToCode(block, 'BOOL', Order.LOGICAL_NOT) || 'True';
    const code = 'not ' + argument0;
    return [code, Order.LOGICAL_NOT];
}

export function logic_boolean(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Boolean values true and false.
    const code = block.getFieldValue('BOOL') === 'TRUE' ? 'True' : 'False';
    return [code, Order.ATOMIC];
}

export function logic_null(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Null data type.
    return ['None', Order.ATOMIC];
}

export function logic_ternary(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Ternary operator.
    const value_if =
        generator.valueToCode(block, 'IF', Order.CONDITIONAL) || 'False';
    const value_then =
        generator.valueToCode(block, 'THEN', Order.CONDITIONAL) || 'None';
    const value_else =
        generator.valueToCode(block, 'ELSE', Order.CONDITIONAL) || 'None';
    const code = value_then + ' if ' + value_if + ' else ' + value_else;
    return [code, Order.CONDITIONAL];
}


export function controls_repeat_ext(block: Block, generator: PythonGenerator) {
    // Repeat n times.
    let repeats;
    if (block.getField('TIMES')) {
        // Internal number.
        repeats = String(parseInt(block.getFieldValue('TIMES'), 10));
    } else {
        // External number.
        repeats = generator.valueToCode(block, 'TIMES', Order.NONE) || '0';
    }
    if (stringUtils.isNumber(repeats)) {
        repeats = parseInt(repeats, 10);
    } else {
        repeats = 'int(' + repeats + ')';
    }
    let branch = generator.statementToCode(block, 'DO');
    branch = generator.addLoopTrap(branch, block) || generator.PASS;
    const loopVar = generator.nameDB_!.getDistinctName(
        'count',
        NameType.VARIABLE,
    );
    const code = 'for ' + loopVar + ' in range(' + repeats + '):\n' + branch;
    return code;
}

export const controls_repeat = controls_repeat_ext;

export function controls_whileUntil(block: Block, generator: PythonGenerator) {
    // Do while/until loop.
    const until = block.getFieldValue('MODE') === 'UNTIL';
    let argument0 =
        generator.valueToCode(
            block,
            'BOOL',
            until ? Order.LOGICAL_NOT : Order.NONE,
        ) || 'False';
    let branch = generator.statementToCode(block, 'DO');
    branch = generator.addLoopTrap(branch, block) || generator.PASS;
    if (until) {
        argument0 = 'not ' + argument0;
    }
    return 'while ' + argument0 + ':\n' + branch;
}

export function controls_for(block: Block, generator: PythonGenerator) {
    // For loop.
    const variable0 = generator.getVariableName(block.getFieldValue('VAR'));
    let argument0: string | number =
        generator.valueToCode(block, 'FROM', Order.NONE) || '0';
    let argument1: string | number =
        generator.valueToCode(block, 'TO', Order.NONE) || '0';
    let increment: string | number =
        generator.valueToCode(block, 'BY', Order.NONE) || '1';
    let branch = generator.statementToCode(block, 'DO');
    branch = generator.addLoopTrap(branch, block) || generator.PASS;

    let code = '';
    let range;

    // Helper functions.
    const defineUpRange = function () {
        return generator.provideFunction_(
            'upRange',
            `
  def ${generator.FUNCTION_NAME_PLACEHOLDER_}(start, stop, step):
    while start <= stop:
      yield start
      start += abs(step)
  `,
        );
    };
    const defineDownRange = function () {
        return generator.provideFunction_(
            'downRange',
            `
  def ${generator.FUNCTION_NAME_PLACEHOLDER_}(start, stop, step):
    while start >= stop:
      yield start
      start -= abs(step)
  `,
        );
    };
    // Arguments are legal generator code (numbers or strings returned by scrub()).
    const generateUpDownRange = function (
        start: string,
        end: string,
        inc: string,
    ) {
        return (
            '(' +
            start +
            ' <= ' +
            end +
            ') and ' +
            defineUpRange() +
            '(' +
            start +
            ', ' +
            end +
            ', ' +
            inc +
            ') or ' +
            defineDownRange() +
            '(' +
            start +
            ', ' +
            end +
            ', ' +
            inc +
            ')'
        );
    };

    if (
        stringUtils.isNumber(argument0) &&
        stringUtils.isNumber(argument1) &&
        stringUtils.isNumber(increment)
    ) {
        // All parameters are simple numbers.
        argument0 = Number(argument0);
        argument1 = Number(argument1);
        increment = Math.abs(Number(increment));
        if (argument0 % 1 === 0 && argument1 % 1 === 0 && increment % 1 === 0) {
            // All parameters are integers.
            if (argument0 <= argument1) {
                // Count up.
                argument1++;
                if (argument0 === 0 && increment === 1) {
                    // If starting index is 0, omit it.
                    range = argument1;
                } else {
                    range = argument0 + ', ' + argument1;
                }
                // If increment isn't 1, it must be explicit.
                if (increment !== 1) {
                    range += ', ' + increment;
                }
            } else {
                // Count down.
                argument1--;
                range = argument0 + ', ' + argument1 + ', -' + increment;
            }
            range = 'range(' + range + ')';
        } else {
            // At least one of the parameters is not an integer.
            if (argument0 < argument1) {
                range = defineUpRange();
            } else {
                range = defineDownRange();
            }
            range += '(' + argument0 + ', ' + argument1 + ', ' + increment + ')';
        }
    } else {
        // Cache non-trivial values to variables to prevent repeated look-ups.
        const scrub = function (arg: string, suffix: string) {
            if (stringUtils.isNumber(arg)) {
                // Simple number.
                arg = String(Number(arg));
            } else if (!arg.match(/^\w+$/)) {
                // Not a variable, it's complicated.
                const varName = generator.nameDB_!.getDistinctName(
                    variable0 + suffix,
                    NameType.VARIABLE,
                );
                code += varName + ' = ' + arg + '\n';
                arg = varName;
            }
            return arg;
        };
        const startVar = scrub(argument0, '_start');
        const endVar = scrub(argument1, '_end');
        const incVar = scrub(increment, '_inc');

        if (typeof startVar === 'number' && typeof endVar === 'number') {
            if (startVar < endVar) {
                range = defineUpRange();
            } else {
                range = defineDownRange();
            }
            range += '(' + startVar + ', ' + endVar + ', ' + incVar + ')';
        } else {
            // We cannot determine direction statically.
            range = generateUpDownRange(startVar, endVar, incVar);
        }
    }
    code += 'for ' + variable0 + ' in ' + range + ':\n' + branch;
    return code;
}

export function controls_forEach(block: Block, generator: PythonGenerator) {
    // For each loop.
    const variable0 = generator.getVariableName(block.getFieldValue('VAR'));
    const argument0 =
        generator.valueToCode(block, 'LIST', Order.RELATIONAL) || '[]';
    let branch = generator.statementToCode(block, 'DO');
    branch = generator.addLoopTrap(branch, block) || generator.PASS;
    const code = 'for ' + variable0 + ' in ' + argument0 + ':\n' + branch;
    return code;
}

export function controls_flow_statements(
    block: Block,
    generator: PythonGenerator,
) {
    // Flow statements: continue, break.
    let xfix = '';
    if (generator.STATEMENT_PREFIX) {
        // Automatic prefix insertion is switched off for this block.  Add manually.
        xfix += generator.injectId(generator.STATEMENT_PREFIX, block);
    }
    if (generator.STATEMENT_SUFFIX) {
        // Inject any statement suffix here since the regular one at the end
        // will not get executed if the break/continue is triggered.
        xfix += generator.injectId(generator.STATEMENT_SUFFIX, block);
    }
    if (generator.STATEMENT_PREFIX) {
        const loop = (block as any).getSurroundLoop();
        if (loop && !loop.suppressPrefixSuffix) {
            // Inject loop's statement prefix here since the regular one at the end
            // of the loop will not get executed if 'continue' is triggered.
            // In the case of 'break', a prefix is needed due to the loop's suffix.
            xfix += generator.injectId(generator.STATEMENT_PREFIX, loop);
        }
    }
    switch (block.getFieldValue('FLOW')) {
        case 'BREAK':
            return xfix + 'break\n';
        case 'CONTINUE':
            return xfix + 'continue\n';
    }
    throw Error('Unknown flow statement.');
}


export function math_number(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Numeric value.
    const number = Number(block.getFieldValue('NUM'));
    if (number === Infinity) {
        return ['float("inf")', Order.FUNCTION_CALL];
    } else if (number === -Infinity) {
        return ['-float("inf")', Order.UNARY_SIGN];
    } else {
        return [String(number), number < 0 ? Order.UNARY_SIGN : Order.ATOMIC];
    }
}

export function math_arithmetic(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Basic arithmetic operators, and power.
    const OPERATORS: Record<string, [string | null, Order]> = {
        'ADD': [' + ', Order.ADDITIVE],
        'MINUS': [' - ', Order.ADDITIVE],
        'MULTIPLY': [' * ', Order.MULTIPLICATIVE],
        'DIVIDE': [' / ', Order.MULTIPLICATIVE],
        'POWER': [' ** ', Order.EXPONENTIATION],
    };
    type OperatorOption = keyof typeof OPERATORS;
    const tuple = OPERATORS[block.getFieldValue('OP') as OperatorOption];
    const operator = tuple[0];
    const order = tuple[1];
    const argument0 = generator.valueToCode(block, 'A', order) || '0';
    const argument1 = generator.valueToCode(block, 'B', order) || '0';
    const code = argument0 + operator + argument1;
    return [code, order];
    // In case of 'DIVIDE', division between integers returns different results
    // in generator 2 and 3. However, is not an issue since Blockly does not
    // guarantee identical results in all languages.  To do otherwise would
    // require every operator to be wrapped in a function call.  This would kill
    // legibility of the generated code.
}

export function math_single(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Math operators with single operand.
    const operator = block.getFieldValue('OP');
    let code;
    let arg;
    if (operator === 'NEG') {
        // Negation is a special case given its different operator precedence.
        code = generator.valueToCode(block, 'NUM', Order.UNARY_SIGN) || '0';
        return ['-' + code, Order.UNARY_SIGN];
    }
    // TODO(#7600): find better approach than casting to any to override
    // CodeGenerator declaring .definitions protected (here and below).
    (generator as any).definitions_['import_math'] = 'import math';
    if (operator === 'SIN' || operator === 'COS' || operator === 'TAN') {
        arg = generator.valueToCode(block, 'NUM', Order.MULTIPLICATIVE) || '0';
    } else {
        arg = generator.valueToCode(block, 'NUM', Order.NONE) || '0';
    }
    // First, handle cases which generate values that don't need parentheses
    // wrapping the code.
    switch (operator) {
        case 'ABS':
            code = 'math.fabs(' + arg + ')';
            break;
        case 'ROOT':
            code = 'math.sqrt(' + arg + ')';
            break;
        case 'LN':
            code = 'math.log(' + arg + ')';
            break;
        case 'LOG10':
            code = 'math.log10(' + arg + ')';
            break;
        case 'EXP':
            code = 'math.exp(' + arg + ')';
            break;
        case 'POW10':
            code = 'math.pow(10,' + arg + ')';
            break;
        case 'ROUND':
            code = 'round(' + arg + ')';
            break;
        case 'ROUNDUP':
            code = 'math.ceil(' + arg + ')';
            break;
        case 'ROUNDDOWN':
            code = 'math.floor(' + arg + ')';
            break;
        case 'SIN':
            code = 'math.sin(' + arg + ' / 180.0 * math.pi)';
            break;
        case 'COS':
            code = 'math.cos(' + arg + ' / 180.0 * math.pi)';
            break;
        case 'TAN':
            code = 'math.tan(' + arg + ' / 180.0 * math.pi)';
            break;
    }
    if (code) {
        return [code, Order.FUNCTION_CALL];
    }
    // Second, handle cases which generate values that may need parentheses
    // wrapping the code.
    switch (operator) {
        case 'ASIN':
            code = 'math.asin(' + arg + ') / math.pi * 180';
            break;
        case 'ACOS':
            code = 'math.acos(' + arg + ') / math.pi * 180';
            break;
        case 'ATAN':
            code = 'math.atan(' + arg + ') / math.pi * 180';
            break;
        default:
            throw Error('Unknown math operator: ' + operator);
    }
    return [code, Order.MULTIPLICATIVE];
}

export function math_constant(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Constants: PI, E, the Golden Ratio, sqrt(2), 1/sqrt(2), INFINITY.
    const CONSTANTS: Record<string, [string, Order]> = {
        'PI': ['math.pi', Order.MEMBER],
        'E': ['math.e', Order.MEMBER],
        'GOLDEN_RATIO': ['(1 + math.sqrt(5)) / 2', Order.MULTIPLICATIVE],
        'SQRT2': ['math.sqrt(2)', Order.MEMBER],
        'SQRT1_2': ['math.sqrt(1.0 / 2)', Order.MEMBER],
        'INFINITY': ["float('inf')", Order.ATOMIC],
    };
    type ConstantOption = keyof typeof CONSTANTS;
    const constant = block.getFieldValue('CONSTANT') as ConstantOption;
    if (constant !== 'INFINITY') {
        (generator as any).definitions_['import_math'] =
            'import math';
    }
    return CONSTANTS[constant];
}

export function math_number_property(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Check if a number is even, odd, prime, whole, positive, or negative
    // or if it is divisible by certain number. Returns true or false.
    const PROPERTIES: Record<string, [string | null, Order, Order]> = {
        'EVEN': [' % 2 == 0', Order.MULTIPLICATIVE, Order.RELATIONAL],
        'ODD': [' % 2 == 1', Order.MULTIPLICATIVE, Order.RELATIONAL],
        'WHOLE': [' % 1 == 0', Order.MULTIPLICATIVE, Order.RELATIONAL],
        'POSITIVE': [' > 0', Order.RELATIONAL, Order.RELATIONAL],
        'NEGATIVE': [' < 0', Order.RELATIONAL, Order.RELATIONAL],
        'DIVISIBLE_BY': [null, Order.MULTIPLICATIVE, Order.RELATIONAL],
        'PRIME': [null, Order.NONE, Order.FUNCTION_CALL],
    };
    type PropertyOption = keyof typeof PROPERTIES;
    const dropdownProperty = block.getFieldValue('PROPERTY') as PropertyOption;
    const [suffix, inputOrder, outputOrder] = PROPERTIES[dropdownProperty];
    const numberToCheck =
        generator.valueToCode(block, 'NUMBER_TO_CHECK', inputOrder) || '0';
    let code;
    if (dropdownProperty === 'PRIME') {
        // Prime is a special case as it is not a one-liner test.
        (generator as any).definitions_['import_math'] =
            'import math';
        (generator as any).definitions_[
            'from_numbers_import_Number'
        ] = 'from numbers import Number';
        const functionName = generator.provideFunction_(
            'math_isPrime',
            `
  def ${generator.FUNCTION_NAME_PLACEHOLDER_}(n):
    # https://en.wikipedia.org/wiki/Primality_test#Naive_methods
    # If n is not a number but a string, try parsing it.
    if not isinstance(n, Number):
      try:
        n = float(n)
      except:
        return False
    if n == 2 or n == 3:
      return True
    # False if n is negative, is 1, or not whole, or if n is divisible by 2 or 3.
    if n <= 1 or n % 1 != 0 or n % 2 == 0 or n % 3 == 0:
      return False
    # Check all the numbers of form 6k +/- 1, up to sqrt(n).
    for x in range(6, int(math.sqrt(n)) + 2, 6):
      if n % (x - 1) == 0 or n % (x + 1) == 0:
        return False
    return True
  `,
        );
        code = functionName + '(' + numberToCheck + ')';
    } else if (dropdownProperty === 'DIVISIBLE_BY') {
        const divisor =
            generator.valueToCode(block, 'DIVISOR', Order.MULTIPLICATIVE) || '0';
        // If 'divisor' is some code that evals to 0, generator will raise an error.
        if (divisor === '0') {
            return ['False', Order.ATOMIC];
        }
        code = numberToCheck + ' % ' + divisor + ' == 0';
    } else {
        code = numberToCheck + suffix;
    }
    return [code, outputOrder];
}

export function math_change(block: Block, generator: PythonGenerator) {
    // Add to a variable in place.
    (generator as any).definitions_['from_numbers_import_Number'] =
        'from numbers import Number';
    const argument0 =
        generator.valueToCode(block, 'DELTA', Order.ADDITIVE) || '0';
    const varName = generator.getVariableName(block.getFieldValue('VAR'));
    return (
        varName +
        ' = (' +
        varName +
        ' if isinstance(' +
        varName +
        ', Number) else 0) + ' +
        argument0 +
        '\n'
    );
}

// Rounding functions have a single operand.
export const math_round = math_single;
// Trigonometry functions have a single operand.
export const math_trig = math_single;

export function math_on_list(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Math functions for lists.
    const func = block.getFieldValue('OP');
    const list = generator.valueToCode(block, 'LIST', Order.NONE) || '[]';
    let code;
    switch (func) {
        case 'SUM':
            code = 'sum(' + list + ')';
            break;
        case 'MIN':
            code = 'min(' + list + ')';
            break;
        case 'MAX':
            code = 'max(' + list + ')';
            break;
        case 'AVERAGE': {
            (generator as any).definitions_[
                'from_numbers_import_Number'
            ] = 'from numbers import Number';
            // This operation excludes null and values that aren't int or float:
            // math_mean([null, null, "aString", 1, 9]) -> 5.0
            const functionName = generator.provideFunction_(
                'math_mean',
                `
  def ${generator.FUNCTION_NAME_PLACEHOLDER_}(myList):
    localList = [e for e in myList if isinstance(e, Number)]
    if not localList: return
    return float(sum(localList)) / len(localList)
  `,
            );
            code = functionName + '(' + list + ')';
            break;
        }
        case 'MEDIAN': {
            (generator as any).definitions_[
                'from_numbers_import_Number'
            ] = 'from numbers import Number';
            // This operation excludes null values:
            // math_median([null, null, 1, 3]) -> 2.0
            const functionName = generator.provideFunction_(
                'math_median',
                `
  def ${generator.FUNCTION_NAME_PLACEHOLDER_}(myList):
    localList = sorted([e for e in myList if isinstance(e, Number)])
    if not localList: return
    if len(localList) % 2 == 0:
      return (localList[len(localList) // 2 - 1] + localList[len(localList) // 2]) / 2.0
    else:
      return localList[(len(localList) - 1) // 2]
  `,
            );
            code = functionName + '(' + list + ')';
            break;
        }
        case 'MODE': {
            // As a list of numbers can contain more than one mode,
            // the returned result is provided as an array.
            // Mode of [3, 'x', 'x', 1, 1, 2, '3'] -> ['x', 1]
            const functionName = generator.provideFunction_(
                'math_modes',
                `
  def ${generator.FUNCTION_NAME_PLACEHOLDER_}(some_list):
    modes = []
    # Using a lists of [item, count] to keep count rather than dict
    # to avoid "unhashable" errors when the counted item is itself a list or dict.
    counts = []
    maxCount = 1
    for item in some_list:
      found = False
      for count in counts:
        if count[0] == item:
          count[1] += 1
          maxCount = max(maxCount, count[1])
          found = True
      if not found:
        counts.append([item, 1])
    for counted_item, item_count in counts:
      if item_count == maxCount:
        modes.append(counted_item)
    return modes
  `,
            );
            code = functionName + '(' + list + ')';
            break;
        }
        case 'STD_DEV': {
            (generator as any).definitions_['import_math'] =
                'import math';
            const functionName = generator.provideFunction_(
                'math_standard_deviation',
                `
  def ${generator.FUNCTION_NAME_PLACEHOLDER_}(numbers):
    n = len(numbers)
    if n == 0: return
    mean = float(sum(numbers)) / n
    variance = sum((x - mean) ** 2 for x in numbers) / n
    return math.sqrt(variance)
  `,
            );
            code = functionName + '(' + list + ')';
            break;
        }
        case 'RANDOM':
            (generator as any).definitions_['import_random'] =
                'import random';
            code = 'random.choice(' + list + ')';
            break;
        default:
            throw Error('Unknown operator: ' + func);
    }
    return [code, Order.FUNCTION_CALL];
}

export function math_modulo(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Remainder computation.
    const argument0 =
        generator.valueToCode(block, 'DIVIDEND', Order.MULTIPLICATIVE) || '0';
    const argument1 =
        generator.valueToCode(block, 'DIVISOR', Order.MULTIPLICATIVE) || '0';
    const code = argument0 + ' % ' + argument1;
    return [code, Order.MULTIPLICATIVE];
}

export function math_constrain(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Constrain a number between two limits.
    const argument0 = generator.valueToCode(block, 'VALUE', Order.NONE) || '0';
    const argument1 = generator.valueToCode(block, 'LOW', Order.NONE) || '0';
    const argument2 =
        generator.valueToCode(block, 'HIGH', Order.NONE) || "float('inf')";
    const code =
        'min(max(' + argument0 + ', ' + argument1 + '), ' + argument2 + ')';
    return [code, Order.FUNCTION_CALL];
}

export function math_random_int(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Random integer between [X] and [Y].
    (generator as any).definitions_['import_random'] =
        'import random';
    const argument0 = generator.valueToCode(block, 'FROM', Order.NONE) || '0';
    const argument1 = generator.valueToCode(block, 'TO', Order.NONE) || '0';
    const code = 'random.randint(' + argument0 + ', ' + argument1 + ')';
    return [code, Order.FUNCTION_CALL];
}

export function math_random_float(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Random fraction between 0 and 1.
    (generator as any).definitions_['import_random'] =
        'import random';
    return ['random.random()', Order.FUNCTION_CALL];
}

export function math_atan2(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Arctangent of point (X, Y) in degrees from -180 to 180.
    (generator as any).definitions_['import_math'] = 'import math';
    const argument0 = generator.valueToCode(block, 'X', Order.NONE) || '0';
    const argument1 = generator.valueToCode(block, 'Y', Order.NONE) || '0';
    return [
        'math.atan2(' + argument1 + ', ' + argument0 + ') / math.pi * 180',
        Order.MULTIPLICATIVE,
    ];
}



export function procedures_defreturn(block: Block, generator: PythonGenerator) {
    // Define a procedure with a return value.
    // First, add a 'global' statement for every variable that is not shadowed by
    // a local parameter.
    const globals = [];
    const workspace = block.workspace;
    const usedVariables = Variables.allUsedVarModels(workspace) || [];
    for (const variable of usedVariables) {
        const varName = variable.name;
        // getVars returns parameter names, not ids, for procedure blocks
        if (!block.getVars().includes(varName)) {
            globals.push(generator.getVariableName(varName));
        }
    }
    // Add developer variables.
    const devVarList = Variables.allDeveloperVariables(workspace);
    for (let i = 0; i < devVarList.length; i++) {
        globals.push(
            generator.nameDB_!.getName(devVarList[i], NameType.DEVELOPER_VARIABLE),
        );
    }

    const globalString = globals.length
        ? generator.INDENT + 'global ' + globals.join(', ') + '\n'
        : '';
    const funcName = generator.getProcedureName(block.getFieldValue('NAME'));
    let xfix1 = '';
    if (generator.STATEMENT_PREFIX) {
        xfix1 += generator.injectId(generator.STATEMENT_PREFIX, block);
    }
    if (generator.STATEMENT_SUFFIX) {
        xfix1 += generator.injectId(generator.STATEMENT_SUFFIX, block);
    }
    if (xfix1) {
        xfix1 = generator.prefixLines(xfix1, generator.INDENT);
    }
    let loopTrap = '';
    if (generator.INFINITE_LOOP_TRAP) {
        loopTrap = generator.prefixLines(
            generator.injectId(generator.INFINITE_LOOP_TRAP, block),
            generator.INDENT,
        );
    }
    let branch = '';
    if (block.getInput('STACK')) {
        // The 'procedures_defreturn' block might not have a STACK input.
        branch = generator.statementToCode(block, 'STACK');
    }
    let returnValue = '';
    if (block.getInput('RETURN')) {
        // The 'procedures_defnoreturn' block (which shares this code)
        // does not have a RETURN input.
        returnValue = generator.valueToCode(block, 'RETURN', Order.NONE) || '';
    }
    let xfix2 = '';
    if (branch && returnValue) {
        // After executing the function body, revisit this block for the return.
        xfix2 = xfix1;
    }
    if (returnValue) {
        returnValue = generator.INDENT + 'return ' + returnValue + '\n';
    } else if (!branch) {
        branch = generator.PASS;
    }
    const args = [];
    const variables = block.getVars();
    for (let i = 0; i < variables.length; i++) {
        args[i] = generator.getVariableName(variables[i]);
    }
    let code =
        'def ' +
        funcName +
        '(' +
        args.join(', ') +
        '):\n' +
        globalString +
        xfix1 +
        loopTrap +
        branch +
        xfix2 +
        returnValue;
    code = generator.scrub_(block, code);
    // Add % so as not to collide with helper functions in definitions list.
    // TODO(#7600): find better approach than casting to any to override
    // CodeGenerator declaring .definitions protected.
    (generator as any).definitions_['%' + funcName] = code;
    return null;
}

// Defining a procedure without a return value uses the same generator as
// a procedure with a return value.
export const procedures_defnoreturn = procedures_defreturn;

export function procedures_callreturn(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Call a procedure with a return value.
    const funcName = generator.getProcedureName(block.getFieldValue('NAME'));
    const args = [];
    const variables = block.getVars();
    for (let i = 0; i < variables.length; i++) {
        args[i] = generator.valueToCode(block, 'ARG' + i, Order.NONE) || 'None';
    }
    const code = funcName + '(' + args.join(', ') + ')';
    return [code, Order.FUNCTION_CALL];
}

export function procedures_callnoreturn(
    block: Block,
    generator: PythonGenerator,
) {
    // Call a procedure with no return value.
    // Generated code is for a function call as a statement is the same as a
    // function call as a value, with the addition of line ending.
    const tuple = generator.forBlock['procedures_callreturn'](block, generator)!;
    return tuple[0] + '\n';
}

export function procedures_ifreturn(block: Block, generator: PythonGenerator) {
    // Conditionally return value from a procedure.
    const condition =
        generator.valueToCode(block, 'CONDITION', Order.NONE) || 'False';
    let code = 'if ' + condition + ':\n';
    if (generator.STATEMENT_SUFFIX) {
        // Inject any statement suffix here since the regular one at the end
        // will not get executed if the return is triggered.
        code += generator.prefixLines(
            generator.injectId(generator.STATEMENT_SUFFIX, block),
            generator.INDENT,
        );
    }
    if ((block as any).hasReturnValue_) {
        const value = generator.valueToCode(block, 'VALUE', Order.NONE) || 'None';
        code += generator.INDENT + 'return ' + value + '\n';
    } else {
        code += generator.INDENT + 'return\n';
    }
    return code;
}




export function text(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Text value.
    const code = generator.quote_(block.getFieldValue('TEXT'));
    return [code, Order.ATOMIC];
}

/**
 * Regular expression to detect a single-quoted string literal.
 */
const strRegExp = /^\s*'([^']|\\')*'\s*$/;

/**
 * Enclose the provided value in 'str(...)' function.
 * Leave string literals alone.
 *
 * @param value Code evaluating to a value.
 * @returns Array containing code evaluating to a string
 *     and
 *    the order of the returned code.[string, number]
 */
const forceString = function (value: string): [string, Order] {
    if (strRegExp.test(value)) {
        return [value, Order.ATOMIC];
    }
    return ['str(' + value + ')', Order.FUNCTION_CALL];
};

export function text_join(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Create a string made up of any number of elements of any type.
    // Should we allow joining by '-' or ',' or any other characters?
    const joinBlock = block as any;
    switch (joinBlock.itemCount_) {
        case 0:
            return ["''", Order.ATOMIC];
        case 1: {
            const element = generator.valueToCode(block, 'ADD0', Order.NONE) || "''";
            const codeAndOrder = forceString(element);
            return codeAndOrder;
        }
        case 2: {
            const element0 = generator.valueToCode(block, 'ADD0', Order.NONE) || "''";
            const element1 = generator.valueToCode(block, 'ADD1', Order.NONE) || "''";
            const code = forceString(element0)[0] + ' + ' + forceString(element1)[0];
            return [code, Order.ADDITIVE];
        }
        default: {
            const elements = [];
            for (let i = 0; i < joinBlock.itemCount_; i++) {
                elements[i] =
                    generator.valueToCode(block, 'ADD' + i, Order.NONE) || "''";
            }
            const tempVar = generator.nameDB_!.getDistinctName(
                'x',
                NameType.VARIABLE,
            );
            const code =
                "''.join([str(" +
                tempVar +
                ') for ' +
                tempVar +
                ' in [' +
                elements.join(', ') +
                ']])';
            return [code, Order.FUNCTION_CALL];
        }
    }
}

export function text_append(block: Block, generator: PythonGenerator) {
    // Append to a variable in place.
    const varName = generator.getVariableName(block.getFieldValue('VAR'));
    const value = generator.valueToCode(block, 'TEXT', Order.NONE) || "''";
    return varName + ' = str(' + varName + ') + ' + forceString(value)[0] + '\n';
}

export function text_length(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Is the string null or array empty?
    const text = generator.valueToCode(block, 'VALUE', Order.NONE) || "''";
    return ['len(' + text + ')', Order.FUNCTION_CALL];
}

export function text_isEmpty(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Is the string null or array empty?
    const text = generator.valueToCode(block, 'VALUE', Order.NONE) || "''";
    const code = 'not len(' + text + ')';
    return [code, Order.LOGICAL_NOT];
}

export function text_indexOf(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Search the text for a substring.
    // Should we allow for non-case sensitive???
    const operator = block.getFieldValue('END') === 'FIRST' ? 'find' : 'rfind';
    const substring = generator.valueToCode(block, 'FIND', Order.NONE) || "''";
    const text = generator.valueToCode(block, 'VALUE', Order.MEMBER) || "''";
    const code = text + '.' + operator + '(' + substring + ')';
    if (block.workspace.options.oneBasedIndex) {
        return [code + ' + 1', Order.ADDITIVE];
    }
    return [code, Order.FUNCTION_CALL];
}

export function text_charAt(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Get letter at index.
    // Note: Until January 2013 this block did not have the WHERE input.
    const where = block.getFieldValue('WHERE') || 'FROM_START';
    const textOrder = where === 'RANDOM' ? Order.NONE : Order.MEMBER;
    const text = generator.valueToCode(block, 'VALUE', textOrder) || "''";
    switch (where) {
        case 'FIRST': {
            const code = text + '[0]';
            return [code, Order.MEMBER];
        }
        case 'LAST': {
            const code = text + '[-1]';
            return [code, Order.MEMBER];
        }
        case 'FROM_START': {
            const at = generator.getAdjustedInt(block, 'AT');
            const code = text + '[' + at + ']';
            return [code, Order.MEMBER];
        }
        case 'FROM_END': {
            const at = generator.getAdjustedInt(block, 'AT', 1, true);
            const code = text + '[' + at + ']';
            return [code, Order.MEMBER];
        }
        case 'RANDOM': {
            // TODO(#7600): find better approach than casting to any to override
            // CodeGenerator declaring .definitions protected (here and below).
            (generator as any).definitions_['import_random'] =
                'import random';
            const functionName = generator.provideFunction_(
                'text_random_letter',
                `
  def ${generator.FUNCTION_NAME_PLACEHOLDER_}(text):
    x = int(random.random() * len(text))
    return text[x]
  `,
            );
            const code = functionName + '(' + text + ')';
            return [code, Order.FUNCTION_CALL];
        }
    }
    throw Error('Unhandled option (text_charAt).');
}

export function text_getSubstring(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Get substring.
    const where1 = block.getFieldValue('WHERE1');
    const where2 = block.getFieldValue('WHERE2');
    const text = generator.valueToCode(block, 'STRING', Order.MEMBER) || "''";
    let at1;
    switch (where1) {
        case 'FROM_START':
            at1 = generator.getAdjustedInt(block, 'AT1');
            if (at1 === 0) {
                at1 = '';
            }
            break;
        case 'FROM_END':
            at1 = generator.getAdjustedInt(block, 'AT1', 1, true);
            break;
        case 'FIRST':
            at1 = '';
            break;
        default:
            throw Error('Unhandled option (text_getSubstring)');
    }

    let at2;
    switch (where2) {
        case 'FROM_START':
            at2 = generator.getAdjustedInt(block, 'AT2', 1);
            break;
        case 'FROM_END':
            at2 = generator.getAdjustedInt(block, 'AT2', 0, true);
            // Ensure that if the result calculated is 0 that sub-sequence will
            // include all elements as expected.
            if (!stringUtils.isNumber(String(at2))) {
                (generator as any).definitions_['import_sys'] =
                    'import sys';
                at2 += ' or sys.maxsize';
            } else if (at2 === 0) {
                at2 = '';
            }
            break;
        case 'LAST':
            at2 = '';
            break;
        default:
            throw Error('Unhandled option (text_getSubstring)');
    }
    const code = text + '[' + at1 + ' : ' + at2 + ']';
    return [code, Order.MEMBER];
}

export function text_changeCase(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Change capitalization.
    const OPERATORS = {
        'UPPERCASE': '.upper()',
        'LOWERCASE': '.lower()',
        'TITLECASE': '.title()',
    };
    type OperatorOption = keyof typeof OPERATORS;
    const operator = OPERATORS[block.getFieldValue('CASE') as OperatorOption];
    const text = generator.valueToCode(block, 'TEXT', Order.MEMBER) || "''";
    const code = text + operator;
    return [code, Order.FUNCTION_CALL];
}

export function text_trim(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Trim spaces.
    const OPERATORS = {
        'LEFT': '.lstrip()',
        'RIGHT': '.rstrip()',
        'BOTH': '.strip()',
    };
    type OperatorOption = keyof typeof OPERATORS;
    const operator = OPERATORS[block.getFieldValue('MODE') as OperatorOption];
    const text = generator.valueToCode(block, 'TEXT', Order.MEMBER) || "''";
    const code = text + operator;
    return [code, Order.FUNCTION_CALL];
}

export function text_print(block: Block, generator: PythonGenerator) {
    // Print statement.
    const msg = generator.valueToCode(block, 'TEXT', Order.NONE) || "''";
    return 'print(' + msg + ')\n';
}

export function text_prompt_ext(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Prompt function.
    const functionName = generator.provideFunction_(
        'text_prompt',
        `
  def ${generator.FUNCTION_NAME_PLACEHOLDER_}(msg):
    try:
      return raw_input(msg)
    except NameError:
      return input(msg)
  `,
    );
    let msg;
    if (block.getField('TEXT')) {
        // Internal message.
        msg = generator.quote_(block.getFieldValue('TEXT'));
    } else {
        // External message.
        msg = generator.valueToCode(block, 'TEXT', Order.NONE) || "''";
    }
    let code = functionName + '(' + msg + ')';
    const toNumber = block.getFieldValue('TYPE') === 'NUMBER';
    if (toNumber) {
        code = 'float(' + code + ')';
    }
    return [code, Order.FUNCTION_CALL];
}

export const text_prompt = text_prompt_ext;

export function text_count(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    const text = generator.valueToCode(block, 'TEXT', Order.MEMBER) || "''";
    const sub = generator.valueToCode(block, 'SUB', Order.NONE) || "''";
    const code = text + '.count(' + sub + ')';
    return [code, Order.FUNCTION_CALL];
}

export function text_replace(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    const text = generator.valueToCode(block, 'TEXT', Order.MEMBER) || "''";
    const from = generator.valueToCode(block, 'FROM', Order.NONE) || "''";
    const to = generator.valueToCode(block, 'TO', Order.NONE) || "''";
    const code = text + '.replace(' + from + ', ' + to + ')';
    return [code, Order.MEMBER];
}

export function text_reverse(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    const text = generator.valueToCode(block, 'TEXT', Order.MEMBER) || "''";
    const code = text + '[::-1]';
    return [code, Order.MEMBER];
}



export function variables_get(
    block: Block,
    generator: PythonGenerator,
): [string, Order] {
    // Variable getter.
    const code = generator.getVariableName(block.getFieldValue('VAR'));
    return [code, Order.ATOMIC];
}

export function variables_set(block: Block, generator: PythonGenerator) {
    // Variable setter.
    const argument0 = generator.valueToCode(block, 'VALUE', Order.NONE) || '0';
    const varName = generator.getVariableName(block.getFieldValue('VAR'));
    return varName + ' = ' + argument0 + '\n';
}





