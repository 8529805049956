import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UserContext } from '../../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../context/SettingsContext'
import { LessonDataType } from '../../../../../types/Lesson'
import { BackendConfigs } from '../../../../../config.environment'
import { APIResponse } from '../../../../../types/Response'
import { routes } from '../../../../../routes/routes'
import { toast } from 'react-toastify'
import { uploadFileOnS3 } from '../../../../../services/S3Storage/Upload'
import { Button, Card, Container } from '@mui/material'
import { FileUploader } from "react-drag-drop-files";
import Submitted from './Submitted'
import RatingComponent from './Rating'
import { updateLessonProgressDetails } from '../../../../../services/backend/Lessons'

type Props = {
}
const fileTypes = ["JPG", "PNG", "GIF", "JPEG", "SVG", "HEIC"];

function SubmitLessonPage({ }: Props) {
    const navigate = useNavigate()
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const { lessonId } = useParams();
    const [lessonData, seLessonData] = useState<LessonDataType | null>(null)
    const [file, setFile] = useState<File | null>(null);
    const [isImageWindowOpen, setIsImageWindowOpen] = React.useState(true)
    const [image, setImage] = React.useState<string | null>(null)
    const [isSubmitted, setIsSubmitted] = React.useState(false)
    const [isSubmitAllowed, setIsSubmitAllowed] = React.useState(false)
    const [sections, setSections] = useState<{
        id: string,
        comparer: string,
        title: string,
        description: string,
        image: string,
        isCompleted: boolean | undefined,
    }[]>([])
    useEffect(() => {
        setSections(() => {
            return [
                {
                    id: "section-A",
                    comparer: "theory",
                    title: "Theory",
                    description: "Theory Section",
                    image: "https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20with%20the%20text%20Theory%20on%20the%20screen.png",
                    isCompleted: lessonData?.progress.progressReport.TheoryProgress.isCompleted,
                },
                {
                    id: "section-B",
                    comparer: "assembly",
                    title: "Assembly",
                    description: "Assembly Section",
                    image: "https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20with%20the%20text%20Assembly%20on%20the%20screen.png",
                    isCompleted: lessonData?.progress.progressReport.AssemblyProgress.isCompleted,

                },
                {
                    id: "section-C",
                    comparer: "circuit",
                    title: "Circuit",
                    description: "Circuit Section",
                    image: "https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20creating%20a%20circuit.png",
                    isCompleted: lessonData?.progress.progressReport.CircuitProgress.isCompleted,
                },
                {
                    id: "section-D",
                    comparer: "micropython",
                    title: "Coding",
                    description: "Micropython Coding Section",
                    image: "https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20with%20the%20text%20Coding%20written%20on%20the%20screen.png",
                    isCompleted: lessonData?.progress.progressReport.MicroPythonProgress.isCompleted,
                },
                {
                    id: "section-E",
                    comparer: "monitor",
                    title: "IOT Monitor Design",
                    description: "IOT Monitor Section",
                    image: "https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20with%20a%20text%20written%20on%20screen%20as%20IOT.png",
                    isCompleted: lessonData?.progress.progressReport.IOTProgress.isCompleted,
                },
                {
                    id: "section-F",
                    comparer: "ai",
                    title: "AI Logic",
                    description: " AI Logic Section",
                    image: "https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20with%20a%20text%20written%20on%20screen%20as%20AI.png",
                    isCompleted: lessonData?.progress.progressReport.AIProgress.isCompleted,
                },
            ]
        })
        if (!!lessonData) {
            let isAllCompleted = true;
            let isSectionNotCompleted = false

            if (lessonData.editors.some(editor => editor.includes("theory"))) {
                if (lessonData.progress.progressReport.TheoryProgress.isCompleted) { }
                else {
                    if (!isSectionNotCompleted) {

                        isAllCompleted = false
                        isSectionNotCompleted = true
                    }

                }
            }
            if (lessonData.editors.some(editor => editor.includes("assembly"))) {
                if (lessonData.progress.progressReport.AssemblyProgress.isCompleted) { }
                else {
                    if (!isSectionNotCompleted) {
                        isAllCompleted = false
                        isSectionNotCompleted = true

                    }
                }
            }
            if (lessonData.editors.some(editor => editor.includes("circuit"))) {
                if (lessonData.progress.progressReport.CircuitProgress.isCompleted) { }
                else {
                    if (!isSectionNotCompleted) {
                        isAllCompleted = false
                        isSectionNotCompleted = true

                    }


                }
            }
            if (lessonData.editors.some(editor => editor.includes("micropython"))) {
                if (lessonData.progress.progressReport.MicroPythonProgress.isCompleted) { }
                else {
                    if (!isSectionNotCompleted) {
                        isAllCompleted = false
                        isSectionNotCompleted = true

                    }

                }
            }
            if (lessonData.editors.some(editor => editor.includes("monitor"))) {
                if (lessonData.progress.progressReport.IOTProgress.isCompleted) { }
                else {
                    if (!isSectionNotCompleted) {
                        isAllCompleted = false
                        isSectionNotCompleted = true

                    }

                }
            }
            if (lessonData.editors.some(editor => editor.includes("ai"))) {
                if (lessonData.progress.progressReport.AIProgress.isCompleted) { }
                else {
                    if (!isSectionNotCompleted) {
                        isAllCompleted = false
                        isSectionNotCompleted = true
                        { }
                    }
                }
            }
            setIsSubmitAllowed(isAllCompleted)
        }
    }, [lessonData])
    function isSectionAvailable(section: string) {
        return lessonData && (
            lessonData.editors.some(editor => editor.includes(section))
        )
    }
    async function getLessons() {
        console.log(lessonId, user.userData);
        if (!!user.userData && !!lessonId) {
            handleGlobalLoading(true, "Fetching Lesson")
            seLessonData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetLessonByIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id,
                        lessonId: lessonId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    // toast.success(result.message)
                    console.log(result.data.editors);
                    seLessonData(result.data)
                    setIsSubmitted(result.data.progress.progressReport.isCompleted)
                } else {
                    navigate(routes.DASHBOARD.MAIN)
                }
            } catch (error) {
                toast.error("Error Fetching Lesson")
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }
    useEffect(() => {
        getLessons()
    }, [lessonId, user])


    async function SubmitLesson() {
        if (!!user.userData && !!lessonData) {
            handleGlobalLoading(true, "Submitting Lesson")
            if (!!lessonData) {

                let newProgressReportData = {
                    ...lessonData.progress.progressReport,
                    isCompleted: true,
                    coins: 3,
                    submittedFileURL: image,
                }
                const response = await updateLessonProgressDetails(user.userData?.id as string, lessonData.lessonId, newProgressReportData)
                setIsSubmitted(true)
                console.log(response);

            }
            handleGlobalLoading(false)
        }
    }

    const handleChange = async (file: File) => {
        if (!lessonData) {
            return;
        }
        handleGlobalLoading(true, "Uploading Image")
        const extension = file.name.split(".").pop();
        const newName = `${lessonData._id}`;
        console.log(file, extension, newName);
        setFile(file);
        if (!!file) {

            try {
                const response = await uploadFileOnS3(file, newName) as {
                    location: string,
                    bucket: string,
                    key: string
                };
                console.log(response);
                if (!!response.location) {
                    setImage(response.location)
                    setIsImageWindowOpen(false)
                    toast.info("Image uploaded successfully")
                }
            } catch (error) {
                console.log(error);
            }
        }
        handleGlobalLoading(false, "Uploading Image")
    };
    return (
        <div style={{
            width: "100%", minHeight: "100vh",
            display: settings.screen === "mobile" ? "flex" : "grid",
            alignItems: "center",
            gridTemplateColumns: "1fr 1fr",
            flexDirection: "column",
            justifyContent: "center",
            overflow: "scroll",

        }}>


            {
                isSubmitted ? lessonData && <Submitted lesson={lessonData} /> :
                    lessonData && <Container>




                        <h3 style={{
                            textAlign: "center",
                            margin: "20px 0",
                        }}>Submit Lesson:  {lessonData?.name}</h3>




                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "center",
                                flexWrap: "wrap",
                                gap: "10px",
                            }}>

                                {
                                    sections.map((section, index) => isSectionAvailable(section.comparer) && (
                                        <Card className={section.id} key={index} style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            margin: "10px",
                                            width: "100%",
                                            padding: "10px",
                                            boxShadow: "0 0 5px #ccc",
                                            borderRadius: "10px",
                                            zIndex: 999,
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                gap: "10px",
                                            }}>
                                                <img src={section.image} style={{
                                                    maxWidth: "50px",
                                                    borderRadius: "10px",
                                                }} alt="" />
                                                <h5 style={{
                                                    margin: "10px 0",
                                                    textAlign: "center",
                                                }}>{section.title}</h5>
                                            </div>
                                            {
                                                section.isCompleted ? <>
                                                    <Button style={{
                                                        margin: "10px 0",
                                                    }}>
                                                        <img src="https://cdn-icons-png.flaticon.com/512/5610/5610944.png" style={{
                                                            maxWidth: "30px",
                                                        }} alt="" />
                                                    </Button>


                                                </> : <>

                                                    <Button variant='contained' color='error'>
                                                        Not Completed
                                                    </Button>

                                                </>
                                            }
                                        </Card>
                                    ))
                                }
                            </div>
                            <div style={{
                                margin: "10px 0",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}>
                                <p>Upload Image of your performed work (optional)</p>
                                {
                                    isImageWindowOpen ?

                                        <FileUploader multiple={false} handleChange={handleChange} name="file" types={fileTypes} style={{ width: "min(500px,90%)" }} classes="file_uploader" /> : image && <img src={image} alt="" style={{
                                            maxWidth: "200px",
                                        }} />
                                }
                            </div>
                        </div>

                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}>
                            {
                                !isSubmitAllowed &&
                                <p style={{
                                    color: eduCOBOTColorScheme.red,
                                }}>
                                    Submission is only allowed once you have completed all the sections
                                </p>
                            }
                            <Button
                                disabled={!isSubmitAllowed}
                                sx={{
                                    width: "250px",
                                    height: "50px",
                                    borderRadius: "10px",
                                }}
                                onClick={SubmitLesson}
                                variant="contained"
                            >
                                Submit
                            </Button>
                        </div>

                    </Container>

            }

            {lessonData && <RatingComponent lesson={lessonData} />}


        </div>
    )
}

export default SubmitLessonPage