import { useDrag } from '@use-gesture/react';
import { animated, useSpring } from '@react-spring/web';
import { Button, Card } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { LessonDataType } from '../../../../../../types/Lesson';
import audioPlayer from '../../../../../../components/audio-player';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import CloseIcon from '@mui/icons-material/Close';
import CircuitSuccessCard from '../../ProgressHandler/CircuitSuccessCard';
import { updateLessonProgressDetails } from '../../../../../../services/backend/Lessons';
import { UserContext } from '../../../../../../context/UserContext';
const label = { inputProps: { 'aria-label': 'Color switch demo' } };
const CircuitPlayerComponent = ({
    lessonData,
    circuitJSON,
    setCircuitJSON,
    setRefreshData,
    isVisible,
    closePlayer,
    setLessonData
}: {
    lessonData: LessonDataType,
    circuitJSON: string,
    setCircuitJSON: React.Dispatch<React.SetStateAction<string>>,
    setRefreshData: () => void,
    isVisible: boolean,
    closePlayer: () => void,
    setLessonData: React.Dispatch<React.SetStateAction<LessonDataType | null>>

}) => {
    const { user } = useContext(UserContext)

    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const [position, setPosition] = useState({ x: 50, y: 50 });
    const [currentStatementIndex, setCurrentStatementIndex] = useState(!lessonData.progress.progressReport.CircuitProgress.isCompleted ? lessonData.progress.progressReport.CircuitProgress.currentStep + 1 || 0 : 0);
    let [timer, setTimer] = useState<number>(0)
    const [isCompleted, setIsCompleted] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            setTimer((pre) => {
                // console.log("Timer", pre);
                return pre + 1
            })
        }, 1000)
        return () => clearInterval(interval)
    }, [])
    useEffect(() => {
        setTimer(0)
    }, [currentStatementIndex])
    const bindLessonCircuitPlayer = useDrag((parameters) => {
        setPosition(() => {
            return {
                x: parameters.offset[0],
                y: parameters.offset[1]
            }
        })
    }, { filterTaps: true, delay: true });


    async function updateLesson() {
        if (!!lessonData) {
            if (!lessonData.progress.progressReport.CircuitProgress.isCompleted) {
                let isAlreadyUpdated = lessonData.progress.progressReport.CircuitProgress.stepsDetails.some((step) => step.stepNumber === currentStatementIndex + 1)
                if (!isAlreadyUpdated) {
                    console.log("Updating Lesson Progress", currentStatementIndex, lessonData.circuitInstructions.content.length);

                    let newProgressReportData = {
                        ...lessonData.progress.progressReport,
                        CircuitProgress: {
                            ...lessonData.progress.progressReport.CircuitProgress,
                            currentTime: lessonData.progress.progressReport.CircuitProgress.currentTime + timer,
                            isCompleted: currentStatementIndex === lessonData.circuitInstructions.content.length - 1,
                            currentStep: currentStatementIndex,
                            coins: currentStatementIndex === lessonData.circuitInstructions.content.length - 1 ? 3 : 0,
                            createdCircuits: circuitJSON,
                            stepsDetails: [
                                ...lessonData.progress.progressReport.CircuitProgress.stepsDetails,
                                {
                                    stepNumber: currentStatementIndex,
                                    stepTime: timer
                                }
                            ]
                        }
                    }

                    lessonData.progress.progressReport.CircuitProgress.stepsDetails.push({
                        stepNumber: currentStatementIndex,
                        stepTime: timer
                    })

                    let newLessonData = {
                        ...lessonData,
                        progress: {
                            ...lessonData.progress,
                            progressReport: newProgressReportData
                        }
                    }
                    setLessonData(newLessonData)
                    const response = await updateLessonProgressDetails(user.userData?.id as string, lessonData.lessonId, newProgressReportData)
                    console.log(response);

                }
            }
        }

    }

    function nextStatement() {
        if (currentStatementIndex < lessonData.circuitInstructions.content.length - 1) {
            audioPlayer.stop()
            audioPlayer.play(lessonData.circuitInstructions.content[currentStatementIndex + 1].audio, isAudioMuted)
            setCircuitJSON(lessonData.circuitInstructions.content[currentStatementIndex + 1].valid)
            setRefreshData()
            updateLesson()
            setCurrentStatementIndex(currentStatementIndex+1)
        } else {

        }
    }
    function getAllNodes(JSON_: any) {
        let Object_ = JSON.parse(JSON_)
        if (Object_.nodes) {
            return Object_.nodes.map((node: any) => {
                return node.id.slice(0, -5)
            })
        }
        return Object_.nodes || []

    }

    function getAllLinks(JSON_: any) {
        let Object_ = JSON.parse(JSON_)
        if (Object_.connectors) {
            return Object_.connectors.map((connector: any) => {
                return connector.sourcePortID + "->" + connector.targetPortID
            })
        }
        return Object_.connectors || []


    }

    function getZoomFactor(JSON_: any) {
        let Object_ = JSON.parse(JSON_)
        // console.log("Object_", Object_.scrollSettings);
        if (!Object_.scrollSettings) return 1
        return Object_.scrollSettings.currentZoom || 1


    }

    useEffect(() => {
        if (currentStatementIndex + 1 >= lessonData.circuitInstructions.content.length) return
        if(currentStatementIndex==0) return
        let isCircuitValid = true
        let temp1 = getAllNodes(lessonData.circuitInstructions.content[currentStatementIndex + 1].valid)
        let temp2 = getAllNodes(circuitJSON)
        console.log("temp1", temp1);
        console.log("temp2", temp2);
        for (let i = 0; i < temp1.length; i++) {
            if (temp2.includes(temp1[i])) {
                temp2.splice(temp2.indexOf(temp1[i]), 1)
            } else {
                isCircuitValid = false
                break
            }
        }
        console.log("isCircuitValid", isCircuitValid);

        temp1 = getAllLinks(lessonData.circuitInstructions.content[currentStatementIndex + 1].valid)
        temp2 = getAllLinks(circuitJSON)
        console.log("temp1", temp1);
        console.log("temp2", temp2);

        let isConnectionValid = true
        for (let i = 0; i < temp1.length; i++) {
            let source = temp1[i].split("->")[0]
            let target = temp1[i].split("->")[1]
            let direction1 = source + "->" + target
            let direction2 = target + "->" + source
            if (temp2.includes(direction1)) {
                temp2.splice(temp2.indexOf(direction1), 1)
            }
            else if (temp2.includes(direction2)) {
                temp2.splice(temp2.indexOf(direction2), 1)
            }
            else {
                isCircuitValid = false
                break
            }
        }
        console.log("isConnectionValid", isConnectionValid);

        temp1 = getZoomFactor(lessonData.circuitInstructions.content[currentStatementIndex + 1].valid)
        temp2 = getZoomFactor(circuitJSON)
        console.log("temp1", temp1);
        console.log("temp2", temp2);
        let isZoomValid = true
        if (temp1 > 1.5 && Math.abs(temp1 - temp2) > 0.4) {
            isZoomValid = false
        }
        console.log("isCircuitValid && isConnectionValid && isZoomValid", isCircuitValid, isConnectionValid, isZoomValid);

        if (isCircuitValid && isConnectionValid && isZoomValid) {
            audioPlayer.stop()
            setCurrentStatementIndex(currentStatementIndex + 1)
            audioPlayer.play(lessonData.circuitInstructions.content[currentStatementIndex + 1].audio, isAudioMuted)
            updateLesson()
        }
        
    }, [circuitJSON])

    useEffect(() => {

    }, [currentStatementIndex])

    function previousStatement() {
        if (currentStatementIndex > 0) {
            setCurrentStatementIndex(currentStatementIndex - 1)
        } else {

        }
    }


    if (!isVisible) {
        return null
    }
    return (
        <>
            <div>
                {
                    !lessonData.progress.progressReport.CircuitProgress.isCompleted ?
                        <Button color='error' variant='contained' sx={{
                            position: "fixed",
                            top: 0,
                            right: 0,
                            margin: 0,
                            zIndex: 99999
                        }}
                        >
                            {(timer / 60).toFixed(0)} min {timer % 60} sec
                        </Button> : <>
                            <Button color='warning' variant='contained' sx={{
                                position: "fixed",
                                top: 0,
                                right: 0,
                                margin: 0,
                                zIndex: 99999
                            }}
                            >
                                Completed With {(lessonData.progress.progressReport.CircuitProgress.currentTime / 60).toFixed(0)} min {lessonData.progress.progressReport.CircuitProgress.currentTime % 60} sec
                            </Button>
                        </>
                }
            </div>
            <animated.div {...bindLessonCircuitPlayer()} style={{
                x: position.x,
                y: position.y,
                maxWidth: "600px",
                width: "100%",
                top: "5%",
                left: "50%",
                touchAction: 'none',
                position: "fixed"
            }} >
                <Card sx={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    cursor: "pointer",
                    maxWidth: "600px",
                    borderRadius: "10px",
                    position: "relative",
                    padding: "10px",
                }}>

                    {
                        isCompleted ? <>
                            Congratulations, You have successfully completed the Circuitry Section of the Lesson. You can now proceed to the next section of the lesson.
                        </> : <>

                            <div style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                zIndex: 9999
                            }}>
                                <Button size='small' variant='text' onClick={() => {
                                    setIsAudioMuted(!isAudioMuted)
                                }} >

                                    {
                                        isAudioMuted ?
                                            <VolumeOffIcon onClick={() => {
                                                audioPlayer.resume()
                                            }} /> :
                                            <VolumeUpIcon onClick={() => {
                                                audioPlayer.stop()
                                            }} />
                                    }
                                </Button>
                                <Button size='small' variant='text' onClick={() => {
                                    closePlayer()
                                }} >

                                    <CloseIcon fontSize="small" />
                                </Button>
                            </div>
                            <h3>
                                {
                                    currentStatementIndex === 0 ? "Task" : currentStatementIndex === lessonData.circuitInstructions.content.length - 1 ? "Conclusion" : lessonData.circuitInstructions.content[currentStatementIndex].title
                                }
                            </h3>
                            <p>
                                {
                                    lessonData.circuitInstructions.content[currentStatementIndex].description
                                }
                            </p>

                        </>
                    }




                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end"
                    }}>
                        <Button onClick={previousStatement} disabled={currentStatementIndex === 0 || isCompleted} color='warning' variant='contained' sx={{
                            textTransform: "none",
                            margin: 1
                        }}>
                            Previous
                        </Button>
                        {
                            currentStatementIndex === lessonData.circuitInstructions.content.length - 1 ? <>
                                <Button disabled={isCompleted} onClick={() => {
                                    setIsCompleted(true)
                                    updateLesson()
                                }} color='secondary' variant='contained' sx={{
                                    textTransform: "none",
                                    margin: 1
                                }}>
                                    End
                                </Button>

                            </> : <>
                                <Button onClick={nextStatement} disabled={currentStatementIndex === lessonData.circuitInstructions.content.length - 1} color='success' variant='contained' sx={{
                                    textTransform: "none",
                                    margin: 1
                                }}>
                                    Next
                                </Button>

                            </>
                        }
                    </div>
                </Card>
            </animated.div>
            <CircuitSuccessCard lesson={lessonData} isCompleted={isCompleted} />

        </>
    )
}
export default CircuitPlayerComponent
