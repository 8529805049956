import { Button, TextField, Tooltip, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { eduCOBOTColorScheme } from '../../../../../../context/SettingsContext'
import { ContentDialogBox } from '../../../../../../components/dialog-box'
import { ProjectDataType } from '../../../../../../types/Dashboard'
// import AccessEditing from '../ProjectSettings/AccessEditing'
import { UserContext } from '../../../../../../context/UserContext'
import { formatDistance } from 'date-fns'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FrontendConfigs } from '../../../../../../config.environment'
import { routes } from '../../../../../../routes/routes'
import PreviewIcon from '@mui/icons-material/Preview';
import MonitorIcon from '@mui/icons-material/Monitor';
import SaveIcon from '@mui/icons-material/Save';
type Props = {
    project: ProjectDataType,
    previewMode: boolean,
    refreshProject: () => void,
    togglePreviewMode: () => void,
    saveWidgets: () => void,
}

function MonitoringHeader({
    project,
    previewMode,
    refreshProject,
    togglePreviewMode,
    saveWidgets,
}: Props) {
    const [isControllerOpen, setIsControllerOpen] = React.useState(false)
    const [isModuleActive, setIsModuleActive] = useState(false)
    function handleActiveStatus() {
        if (!project) return
        if (!project.lastInteraction) return
        if (Date.now() - parseInt(project.lastInteraction) < 300000) {
            setIsModuleActive(true)
        }
    }
    useEffect(() => {
        handleActiveStatus()
    }, [project])
    const { user } = useContext(UserContext)
    return (
        <div style={{
            height: previewMode?"12dvh":"8dvh",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 20px",
            width: "100%",
        }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%"
            }}>

                <div style={{
                    maxWidth: "98%",
                }}>
                    <div style={{
                        margin: "10px",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                    }}>
                        <img src="https://cdn-icons-png.freepik.com/256/5956/5956592.png?semt=ais_hybrid" alt="" style={{
                            maxWidth: "30px",
                        }} />
                        <h3>
                            {
                                project.project.name
                            }
                            {
                                previewMode && <Button color='secondary' variant='outlined' size='small' style={{
                                    fontSize: "7px",
                                    textTransform: "none",
                                    margin: "5px",
                                    padding: "2px 2px",
                                }}>Public View</Button>
                            }
                        </h3>
                    </div>
                    {
                        previewMode && <div>
                            <p style={{
                                fontSize: "10px",
                                // color: eduCOBOTColorScheme.black
                            }}>
                                Owner : {user.userData?.name}
                            </p>
                            <p style={{
                                fontSize: "10px",
                                // color: eduCOBOTColorScheme.black
                            }}>
                                Last updated At: {formatDistance(parseInt(project.project.lastUpdatedAt), Date.now(), { addSuffix: true })}
                            </p>
                            <p style={{
                                fontSize: "10px",
                                color: eduCOBOTColorScheme.gray
                            }}> {
                                    project.project.description
                                }
                            </p>
                        </div>
                    }
                </div>
                <Typography style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                }}>
                    <Tooltip title={isModuleActive ? "Module Active" : "Module Inactive"} placement='left'>
                        <Button id='ModuleStatusInMonitor' style={{}} onClick={() => {

                        }}>

                            <img src={isModuleActive ? "https://cdn-icons-png.flaticon.com/128/8622/8622132.png" : "https://vectorified.com/images/status-icon-33.png"} width={20} alt="" />
                        </Button>
                    </Tooltip>
                    <Tooltip title={previewMode ? "Close Preview" : "Open Preview"} placement='left'>
                        <Button id='MonitorPreview' style={{}} onClick={() => {
                            togglePreviewMode()
                        }}>
                            {
                                previewMode ? <PreviewIcon /> : <MonitorIcon />
                            }
                        </Button>
                    </Tooltip>
                    <Tooltip title={"Save Monitor"} placement='left'>
                        <Button id='SaveMonitorButton' style={{}} onClick={() => {
                            saveWidgets()
                        }}>
                            <SaveIcon />
                        </Button>
                    </Tooltip>
                </Typography>
            </div>
            {/* {
                !previewMode &&
                <div style={{
                    maxWidth: "200px",
                }}>
                    <Button variant='contained' size='small' sx={{
                        textTransform: "none",
                        borderRadius: "6px",
                        fontWeight: "bolder",
                        fontSize: "10px",
                        zIndex: 99999999999
                    }} onClick={() => {
                        setIsControllerOpen(true);
                    }}>
                        Share
                    </Button>
                </div>

            } */}

            {/* <ContentDialogBox
                isOpen={isControllerOpen}
                onClose={() => { setIsControllerOpen(false) }}
                content={
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: "20px",
                    }}>

                        <AccessEditing setIsSettingsOpen={setIsControllerOpen} refreshProject={refreshProject} project={project.project} />
                            {
                                project.project.isPublic &&
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "30px",
                                }}>
                                    <TextField disabled size='small' fullWidth value={`${FrontendConfigs.url}${routes.SHARED_MONITORING.ROOT}` + project.project._id} label={"Public Link"} />
                                    <Button variant='contained' size='small' sx={{
                                        textTransform: "none",
                                    }} onClick={() => { navigator.clipboard.writeText(`${FrontendConfigs.url}${routes.SHARED_MONITORING.ROOT}` + project.project._id) }}>
                                        <ContentCopyIcon/>
                                    </Button>
                                </div>
                            }
                    </div>
                }
                title='Share Monitor'
                maxWidth="md"
            /> */}

        </div>
    )
}

export default MonitoringHeader