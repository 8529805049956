import React, { useEffect } from 'react'

type Props = {}

function LogicGateSimulatorPage({ }: Props) {
    useEffect(() => {
        window.location.href = '/logic-gate-simulator'
    }, [])
    return (
        <div></div>
    )
}

export default LogicGateSimulatorPage