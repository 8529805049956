import React, { useContext, useState } from 'react'
import { ProjectDataType, Variable } from '../../../../../types/Dashboard'
import { Button, Card, Container, Grid, Switch, TextField, Typography } from '@mui/material'
import { UserContext } from '../../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../context/SettingsContext'
import { BackendConfigs } from '../../../../../config.environment'
import { APIResponse } from '../../../../../types/Response'
import { toast } from 'react-toastify'
import { ContentDialogBox } from '../../../../../components/dialog-box'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete';
import EditorSelectionEditing from './EditorsSelections'

type Props = {
    projectData: ProjectDataType,
    refreshProject: () => void
}

function VariableEditing({ projectData, refreshProject }: Props) {
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const [variables_list, setVariables_list] = useState<Variable[]>(projectData.project.variables)
    async function saveVariables() {
        handleGlobalLoading(true, "Saving Variables")
        try {
            const response = await fetch(`${BackendConfigs.url}/ChangeVariablesAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: projectData.project._id,
                    ownerId: user.userData?.id,
                    variables: variables_list.filter((variable) => variable.name !== "")
                })
            })
            const result = await response.json() as APIResponse
            if (result.success) {
                toast.success(result.message)
                refreshProject()
            } else {
                toast.error(result.message)
            }
        } catch (error) {
            console.log(error);
            toast.error("Error saving variables")
        }
        handleGlobalLoading(false)
    }

    return (
        <div id='VariableEditingSection' style={{
            width: "95%",
            margin: "10px auto",
            minHeight: "30vh",
        }}>


            {
                projectData.project.editors.includes("iot-monitor") && <>
                    <div style={{
                        // border: "1px solid gray",
                        padding: "10px",

                    }}>

                        <h4 style={{ marginBottom: "10px" }}>Add/Remove IOT-variable list</h4>
                        {
                            variables_list.map((variable, index) => (
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        id="outlined-multiline-flexible"
                                        label="Variable Name"
                                        multiline
                                        maxRows={10}
                                        value={variable.name}
                                        onChange={(e) => {
                                            const new_variables_list = [...variables_list]
                                            new_variables_list[index].name = e.target.value
                                            setVariables_list(new_variables_list)
                                        }}
                                        sx={{
                                            fontSize: "10px",
                                            marginBottom: "10px",
                                            background: "#0000001A",

                                        }}
                                    />
                                    <Button onClick={() => {
                                        if (projectData.micropython.microPythonXML.includes(`<block type="${variable.name}"`)) {
                                            toast.error("Variable is used in code, please remove from there to update it")
                                        } else {
                                            setVariables_list(variables_list.filter((v, i) => i !== index))
                                        }
                                    }}>
                                        <DeleteIcon />
                                    </Button>
                                </div>
                            ))
                        }
                        <div>
                            <Button id='AddVariableButton' variant='outlined' color='success' sx={{
                                textTransform: "none",
                                borderRadius: "6px",
                                fontWeight: "bolder",
                                fontSize: "10px",
                                width: "100%",
                            }} onClick={() => {
                                setVariables_list([...variables_list, { name: "", value: "", type: "string" }])
                            }}>
                                Add Variable
                            </Button>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "20px",
                        }}>

                            <Button id='SaveUpdatedVariableList'
                                variant='contained'
                                size='small'
                                onClick={() => {
                                    saveVariables()

                                }}
                                color="primary"
                                sx={{
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    fontWeight: "bolder",
                                    fontSize: "10px"
                                }}
                            >
                                Save Variables
                            </Button>

                        </div>
                    </div>

                </>
            }


        </div>
    )
}

export default VariableEditing