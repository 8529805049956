import { routes } from "../routes/routes"

const ToolsData = [
    {
        name: "Circuit Designer",
        description: "Design and visualize circuits using a simple drag-and-drop interface.",
        thumbnail: "https://cdn-icons-png.flaticon.com/512/2432/2432692.png",
        link: routes.TOOLS.CIRCUIT_DESIGNER,
        previewImage: ""
    },
    // {
    //     name: "AI Model Controller",
    //     description: "Design and visualize circuits using a simple drag-and-drop interface.",
    //     thumbnail: "https://cdn-icons-png.flaticon.com/512/12109/12109538.png",
    //     link: routes.TOOLS.AIEditor,
    //     previewImage: ""
    // },
    {
        name: "Micropython Editor",
        description: "Design and visualize circuits using a simple drag-and-drop interface.",
        thumbnail: "https://upload.wikimedia.org/wikipedia/commons/a/a5/MicroPython_new_logo.jpg",
        link: routes.TOOLS.MICROPYTHON_EDITOR,
        previewImage: ""
    },
    {
        name: "Logic Gate Simulator",
        description: "Design and visualize circuits using a simple drag-and-drop interface.",
        thumbnail: "https://cdn-icons-png.flaticon.com/512/3512/3512140.png",
        link: routes.TOOLS.LOGIC_GATE_SIMULATOR,
        previewImage: ""
    },
    // {
    //     name: "IOT Monitor",
    //     description: "Design and visualize circuits using a simple drag-and-drop interface.",
    //     thumbnail: "https://cdn-icons-png.flaticon.com/512/5958/5958878.png",
    //     link: routes.TOOLS.MONITOR_DESIGNER,
    //     previewImage: ""
    // },
]

export default ToolsData