import { BackendConfigs } from "../../config.environment"



export async function updateLessonProgressDetails(userId:string,lessonId:string,report:any): Promise<any> {
    try {
        const response = await fetch(`${BackendConfigs.url}/UpdateUserLessonProgressAPI`,{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userId: userId,
                lessonId: lessonId,
                report:report,
            })
        })
        const result = await response.json()
        return result
    } catch (error) {
        return null
    }
}