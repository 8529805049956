import { Button, Card, Container, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { ContentDialogBox } from '../../../../components/dialog-box'
import DeleteIcon from '@mui/icons-material/Delete';
type Props = {
    lessonInstructions: string[],
    setLessonInstructions: (instructions: string[]) => void
}

function AddInstructionComponent({
    lessonInstructions,
    setLessonInstructions
 }: Props) {
    const [open, setOpen] = React.useState(false)
    const [instructions, setInstructions] = React.useState<string[]>(lessonInstructions)
    useEffect(() => {
        setLessonInstructions(instructions)
    }, [instructions])
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        }}>

            <Button style={{
            }} fullWidth variant='contained' onClick={() => {
                setOpen(!open)
            }}>
                {
                    open ? "Close Instructions" : "Add Instructions"
                }
            </Button>
            {/* <ContentDialogBox
                title="Add Instructions"
                isOpen={open}
                onClose={() => {
                    setOpen(false)
                }}
                paperStyle={{
                    zIndex: 9999999999,
                }}
                maxWidth="md"
                content={<> */}

            {
                open && <>
                    <Typography component={'div'} style={{
                        position: "fixed",
                        top: "80px",
                        right: "0",
                        borderRadius: "1rem",
                        width: "min(100%, 700px)",
                        minHeight: "min(100%, 500px)",
                        maxHeight: "min(100%, 80dvh)",
                        overflowY: "auto",
                        backgroundColor: "#83cceb",
                        display: "flex",
                        // justifyContent: "center",
                        // alignItems: "center",
                        gap: "1rem",
                        padding: "1rem",
                        flexDirection: "column",
                    }}>
                        {
                            instructions.map((instruction, index) => {
                                return <div style={{
                                    display: "flex",
                                    gap: "1rem",
                                    width: "100%",
                                    alignItems: "center",
                                }}>
                                    <TextField
                                        key={index}
                                        value={instruction}
                                        onChange={(e) => {
                                            const newInstructions = [...instructions]
                                            newInstructions[index] = e.target.value
                                            setInstructions(newInstructions)
                                        }}
                                        fullWidth
                                        multiline
                                        label={`Instruction ${index + 1}`}
                                        variant='standard'
                                        sx={{
                                        }}
                                    />
                                    <Button variant='contained' color='error' size='small' onClick={() => {
                                        const newInstructions = [...instructions]
                                        newInstructions.splice(index, 1)
                                        setInstructions(newInstructions)
                                    }}>
                                        <DeleteIcon />
                                    </Button>
                                </div>
                            })
                        }
                        <Button variant='contained' color='success' onClick={() => {
                            setInstructions([...instructions, ""])
                        }}>
                            Add Instruction
                        </Button>

                    </Typography>
                </>
            }

        </div>
    )
}

export default AddInstructionComponent