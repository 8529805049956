import React, { useContext, useEffect, useState } from 'react'
import { LessonDataType } from '../../../../../../types/Lesson'
import { ContentDialogBox } from '../../../../../../components/dialog-box'
import { getImagePathByComponentName, getSensorsList } from '../../Common/CircuitToBlockMapper'
import { Button, Card } from '@mui/material'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../../context/SettingsContext'
import { TypeAnimation } from 'react-type-animation';
import TypingEffect from '../../../../../../components/typer-effect/Typing'
import TheorySuccessCard from '../../ProgressHandler/TheorySuccessCard'
import { updateLessonProgressDetails } from '../../../../../../services/backend/Lessons'
import { UserContext } from '../../../../../../context/UserContext'
import { getRandomEndTheoryGreetings } from '../../../../../../utils/Greetings'

type Props = {
    lessonData: LessonDataType,
    setRefreshData: () => void,
    isVisible: boolean,
    closePlayer: () => void
    setLessonData: (data: LessonDataType) => void
}

function TheoryPlayerComponent({
    lessonData,
    setRefreshData,
    isVisible,
    closePlayer,
    setLessonData
}: Props) {
    const [currentSection, setCurrentSection] = useState<number>(!lessonData.progress.progressReport.TheoryProgress.isCompleted ? lessonData.progress.progressReport.TheoryProgress.currentStep || 0 : 0)
    const [theoryParts, setTheoryParts] = useState<
        React.ReactNode[][]>([[<></>]])
    let [timer, setTimer] = useState<number>(0)
    const [isCompleted, setIsCompleted] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer((pre) => {
                console.log("Timer", pre);
                return pre + 1
            })
        }, 1000)
        return () => clearInterval(interval)
    }, [])
    useEffect(() => {
        setTimer(0)
    }, [currentSection])
    const { settings } = useContext(SettingContext)
    const { user } = useContext(UserContext)


    function nextSection() {
        if (currentSection < theoryParts.length - 1) setCurrentSection(currentSection + 1)
        updateLesson()
    }
    function previousSection() {
        if (currentSection > 0) setCurrentSection(currentSection - 1)
    }
    async function updateLesson() {
        if (!!lessonData) {
            if (!lessonData.progress.progressReport.TheoryProgress.isCompleted) {
                let isAlreadyUpdated = lessonData.progress.progressReport.TheoryProgress.stepsDetails.some((step) => step.stepNumber === currentSection)
                if (!isAlreadyUpdated) {
                    let newProgressReportData = {
                        ...lessonData.progress.progressReport,
                        TheoryProgress: {
                            ...lessonData.progress.progressReport.TheoryProgress,
                            currentTime: lessonData.progress.progressReport.TheoryProgress.currentTime + timer,
                            isCompleted: currentSection === theoryParts.length - 2,
                            coins: currentSection === theoryParts.length - 2 ? 1 : 0,
                            currentStep: currentSection,
                            stepsDetails: [
                                ...lessonData.progress.progressReport.TheoryProgress.stepsDetails,
                                {
                                    stepNumber: currentSection,
                                    stepTime: timer
                                }
                            ]
                        }
                    }

                    lessonData.progress.progressReport.TheoryProgress.stepsDetails.push({
                        stepNumber: currentSection,
                        stepTime: new Date().getTime()
                    })

                    let newLessonData = {
                        ...lessonData,
                        progress: {
                            ...lessonData.progress,
                            progressReport: newProgressReportData
                        }
                    }
                    setLessonData(newLessonData)
                    const response = await updateLessonProgressDetails(user.userData?.id as string, lessonData.lessonId, newProgressReportData)
                    console.log(response);

                }
            }
        }

    }
    useEffect(() => {
        const finalContent: React.ReactNode[][] = [
            [
                <h1 style={{
                    textAlign: "center",
                    fontSize: "60px",
                    fontWeight: "bolder"

                }}>

                    <TypingEffect text={lessonData.theory.title} count={1} />
                </h1>,
                <p style={{
                    textAlign: "center",
                    fontWeight: "bolder"
                }}>
                    <TypingEffect text={lessonData.theory.description} count={1} />
                </p>
            ],
            [
                <h3 style={{
                    textAlign: "center",
                    fontSize: "50px"
                }}> <TypingEffect text={"Hardware Used"} count={1} /></h3>,
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    margin: "0 10px",
                }}>
                    {
                        getSensorsList(lessonData.circuitInstructions.finalCircuit).map((sensor, index) => {
                            return (
                                <Card key={index} style={{
                                    width: "200px",
                                    minHeight: "120px",
                                    display: "flex",
                                    padding: "10px",
                                    margin: "10px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    borderRadius: "10px",
                                    background: "#0000001A",

                                }}>
                                    <img src={getImagePathByComponentName(sensor)} style={{
                                        maxHeight: "300px",
                                        maxWidth: "300px"
                                    }} alt="" />
                                    <h6 style={{
                                        color: eduCOBOTColorScheme.white,
                                        fontWeight: "bolder"
                                    }}>{sensor}</h6>
                                </Card>
                            )
                        })
                    }
                </div>
            ],
            [
                <h3 style={{
                    textAlign: "center",
                    fontSize: "50px"
                }}>Requirements</h3>,
                <ol style={{ padding: "0 20px", margin: "20px auto" }}>
                    {
                        lessonData.theory.requirements.map((requirement, index) => {
                            return (
                                <li style={{
                                    fontSize: "20px",
                                    color: eduCOBOTColorScheme.white,
                                    fontWeight: "bolder"
                                }} key={index}> <TypingEffect text={requirement} count={1} /></li>
                            )
                        })
                    }
                </ol>
            ]
        ]
        for (let i = 0; i < lessonData.theory.content.length; i++) {
            const content: React.ReactNode[] = []
            if (lessonData.theory.content[i].type === "heading") {
                content.push(
                    <h3 style={{
                        textAlign: "center",
                        fontSize: "50px"
                    }}>{lessonData.theory.content[i].value}</h3>
                )
            } else {
                continue
            }
            while (i < lessonData.theory.content.length - 1 && lessonData.theory.content[i + 1].type !== "heading") {
                i++
                console.log(lessonData.theory.content[i].type);
                if (lessonData.theory.content[i].type === "image") {
                    content.push(
                        <img src={lessonData.theory.content[i].value} style={{
                            maxWidth: "50%",
                            maxHeight: "200px",
                            margin: "10px auto",
                            borderRadius: "5px"
                        }} alt="" />
                    )
                }
                else if (lessonData.theory.content[i].type === "paragraph") {
                    content.push(
                        <p style={{
                            textAlign: 'justify',
                            width: "100%",
                            margin: "0 auto",
                            fontSize: "20px",
                        }}><TypingEffect text={lessonData.theory.content[i].value} count={1} /></p>
                    )
                }
            }
            finalContent.push(content)
        }
        finalContent.push(
            [
                <h1 style={{
                    textAlign: "center",
                    fontSize: settings.screen == "mobile" ? "60px" : "60px",
                }}>

                    <TypingEffect text={getRandomEndTheoryGreetings()} count={1} />
                </h1>
            ]
        )
        setTheoryParts(prev => finalContent)
    }, [])
    useEffect(() => {
        if (currentSection === theoryParts.length - 1 && currentSection !== 0) {
            setIsCompleted(true);
        } else {
            setIsCompleted(false);
        }
    }, [currentSection])
    return (
        <div>
            <div> {
                !lessonData.progress.progressReport.TheoryProgress.isCompleted ?
                    <Button color='error' variant='contained' sx={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        margin: 1,
                        zIndex: 99999
                    }}
                    >
                        {(timer / 60).toFixed(0)} min {timer % 60} sec
                    </Button> : <>

                        <Button color='warning' variant='contained' sx={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            margin: 1,
                            zIndex: 99999
                        }}
                        >
                            Completed With {(lessonData.progress.progressReport.TheoryProgress.currentTime / 60).toFixed(0)} min {lessonData.progress.progressReport.TheoryProgress.currentTime % 60} sec
                        </Button>
                    </>

            }
            </div>
            <ContentDialogBox
                isOpen={isVisible}
                onClose={closePlayer}
                isTransition={true}
                transitionDirection='up'
                fullScreen
                paperStyle={{
                    padding: "0",
                    background: "url('https://storage.googleapis.com/educobot-robotics/images/set-1/theory-background%20(2).svg')",
                    backgroundSize: "cover",
                }}
                contentStyle={{
                    padding: "0",
                    background: "transparent",
                    display: "flex",
                    justifyContent: "center",
                    flex: "none"
                }}

                content={
                    <div style={{
                        // background: "url(https://images.unsplash.com/photo-1711062717294-8902df8cebe3?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGJsYWNrYm9hcmR8ZW58MHx8MHx8fDA%3D) no-repeat",
                        // backgroundSize: "cover",
                        width: window.innerHeight * 1.4,
                        color: "white",
                        height: window.innerHeight * 0.85,
                        // border: "15px solid #036F55",
                        borderRadius: "20px",
                        padding: "10px",
                        paddingBottom: "50px",
                        overflowY: "scroll",

                    }}>
                        <div key={currentSection} style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            minHeight: window.innerHeight * 0.90 - 100,
                        }}>
                            {
                                theoryParts && theoryParts[currentSection] && theoryParts[currentSection].map((Element, index) => {
                                    return <React.Fragment key={index}>
                                        {Element}
                                    </React.Fragment>
                                })
                            }
                        </div>
                    </div>
                }
                actions={<>
                    <div style={{
                        width: window.innerHeight * 1.5,
                        margin: "0 auto",
                        display: "flex",
                        justifyContent: settings.screen === "mobile" ? "start" : "start",
                    }}>
                        <Button onClick={previousSection} disabled={currentSection === 0} color='warning' variant='contained' sx={{
                            textTransform: "none",
                            margin: 1
                        }}>
                            Previous
                        </Button>

                        <Button onClick={nextSection} disabled={currentSection === theoryParts.length - 1} color='success' variant='contained' sx={{
                            textTransform: "none",
                            margin: 1
                        }}>
                            Next
                        </Button>
                    </div>

                </>}
            />
            <TheorySuccessCard lesson={lessonData} isCompleted={isCompleted} />

        </div>
    )
}

export default TheoryPlayerComponent