import React from 'react'
import { TypeAnimation } from 'react-type-animation';

type Props = {
    text: string,
    count: number
}

function TypingEffect({
    text,
    count
}: Props) {
    return (
        <TypeAnimation
            sequence={[
                text
            ]}
            speed={99}
            wrapper="span"
            cursor={false}
            repeat={count}
            style={{
                fontSize: 'inherit', 
                display: 'inline-block',
                fontWeight:"bolder"
            }}
        />

    )
}

export default TypingEffect