import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { eduCOBOTColorScheme, SettingContext } from '../../../context/SettingsContext';
import { BackendConfigs } from '../../../config.environment';
import { APIResponse } from '../../../types/Response';
import { Button, Card, CardActions, TextField } from '@mui/material';
import { routes } from '../../../routes/routes';
import { LessonDataType } from '../../../types/Lesson';

export interface LessonBuilderLessonsProps {
}


export default function LessonBuilderLessons(props: LessonBuilderLessonsProps) {
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate()
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [currentFilter, setCurrentFilter] = React.useState("all")
    const [lessonBuilderLessons, setLessonBuilderLessons] = React.useState<LessonDataType[]>([])
    const [backupLessonBuilderLessons, setBackupLessonBuilderLessons] = React.useState<LessonDataType[]>([])
    const [searchText, setSearchText] = React.useState("")
    async function fetchLessonBuilderLessons() {
        handleGlobalLoading(true, "Fetching lessonBuilderLessons")
        setLessonBuilderLessons([])
        try {
            const response = await fetch(`${BackendConfigs.url}/GetLessonsByUserAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userId: user.userData?.id
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                if (!!result.data) {
                    setLessonBuilderLessons(result.data.map((lesson: any) => lesson.lesson))
                    setBackupLessonBuilderLessons(result.data.map((lesson: any) => lesson.lesson))
                }
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }
    React.useEffect(() => {
        fetchLessonBuilderLessons()
    }, [user])

    useEffect(() => {
        if (currentFilter === "all") {
            setLessonBuilderLessons(backupLessonBuilderLessons)
        } else {
            setLessonBuilderLessons(backupLessonBuilderLessons.filter((lesson) => !!lesson.editors.some((editor) => editor.includes(currentFilter))))
        }
    }, [currentFilter])

    useEffect(() => {
        if (searchText === "") {
            setLessonBuilderLessons(backupLessonBuilderLessons)
        }
        else {
            setLessonBuilderLessons(backupLessonBuilderLessons.filter((lesson) =>
                lesson.name.toLowerCase().includes(searchText.toLowerCase()) ||
                lesson.description.toLowerCase().includes(searchText.toLowerCase()) ||
                lesson.lessonNumber.toString().includes(searchText.toLowerCase())
            ))
        }
    }, [searchText])

    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                // padding: "15px",
                gap: "10px",

            }}>
                <Button size='large' onClick={() => {
                    navigate(routes.DASHBOARD.LESSON_BUILDER_LESSON_CREATE)
                }} variant={'contained'} color='secondary' sx={{
                    textTransform: "none",
                    borderRadius: "8px",
                }}>
                    Create New Lesson
                </Button>

            </div>





            <div style={{
                display: "flex",
                justifyContent: 'start',
                alignItems: "start",
                flexWrap: "wrap",
            }}>
                {
                    lessonBuilderLessons.map((lesson) => {
                        return (
                            <LessonCard key={lesson._id} title={lesson.name} image={lesson.thumbnail} lesson={lesson} />
                        )
                    })
                }
                {
                    lessonBuilderLessons.length === 0 && <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "30vh",
                        margin: "10px",
                        padding: "10px",
                        boxShadow: "0 0 5px #ccc",
                        borderRadius: "10px"
                    }}>
                        <p style={{ textAlign: "center" }}>No Lesson Found</p>
                    </Card>
                }
            </div>
        </div>
    );
}

function LessonCard({ image, title, lesson }: { image: string, title: string, lesson: LessonDataType }) {
    const navigate = useNavigate()
    const [isHovering, setIsHovering] = React.useState(false)
    const { settings } = useContext(SettingContext)
    return (

        <Card onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} style={{
            width: "min(300px, 100%)",
            minHeight: "min(250px, 500px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: "20px",
            margin: "15px",
            background: isHovering ? eduCOBOTColorScheme.green : undefined,
            backdropFilter: "blur(2px)",
            padding: "20px 10px",
            transition: "all 0.2s ease-in-out",
            boxShadow: "1px 5px 5px #cccccc35",
        }}
            sx={{
                ":hover": {
                    scale: "1.1",
                }
            }}
        >
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "2px",
                padding: "10px"
            }}>
                <div style={
                    {
                        width: "30px",
                        height: "30px",
                        minWidth: "30px",
                        color: settings.themeMode != "dark" ? "white" : eduCOBOTColorScheme.black,
                        backgroundColor: settings.themeMode == "dark" ? "white" : eduCOBOTColorScheme.black,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                    }
                }>
                    {
                        lesson.lessonNumber
                    }
                </div>
                <h4 style={{
                    width: "min(200px, 100%)",
                    // color: eduCOBOTColorScheme.black,
                    textAlign: "center",

                }} >
                    {title}
                </h4>
                <div style={
                    {
                        width: "30px",
                        height: "30px",
                        color: settings.themeMode != "dark" ? "white" : eduCOBOTColorScheme.black,
                        backgroundColor: settings.themeMode == "dark" ? "white" : eduCOBOTColorScheme.black,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                    }
                }>
                    G
                </div>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                gap: "2px"
            }}>
                {
                    lesson.tags &&lesson.tags.map((tag, index) => {
                        return (
                            <p key={index} style={{
                                textAlign: "center",
                                color: isHovering ? eduCOBOTColorScheme.black : eduCOBOTColorScheme.gray,
                                background: isHovering ? eduCOBOTColorScheme.white : "#F2F3F5",
                                borderRadius: "15px",
                                margin: "5px 2px",
                                padding: "3px 10px",
                                fontSize: "0.8em",
                            }} >
                                {tag}
                            </p>
                        )
                    })
                }
            </div>
            <img src={image} alt="" style={{
                maxWidth: "300px",
                maxHeight: "160px",
                margin: "10px auto"
            }} />

            <p style={{
                fontSize: "0.8em",
                color: isHovering ? eduCOBOTColorScheme.white : eduCOBOTColorScheme.gray,
                padding: "10px 0px",
                textAlign: "justify"
            }}>
                {lesson.description}
            </p>
            <div style={{
                width: "100%",
                padding: "10px",
            }}>
                <Button size='small' fullWidth onClick={() => {
                    navigate(routes.DASHBOARD.LESSON_BUILDER.WORKSPACE(lesson.lessonId))
                }} variant={'outlined'} sx={{
                    textTransform: "none",
                    borderRadius: "8px",
                    color: settings.themeMode === "dark" ? eduCOBOTColorScheme.black : undefined,
                    background: eduCOBOTColorScheme.white,
                    ":hover": {
                        background: eduCOBOTColorScheme.white,
                    }
                }}>
                    Open
                </Button>
            </div>
        </Card>
    )
}


