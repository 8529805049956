import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../../context/SettingsContext'
import { Button, Card, Container, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../../../../../../routes/routes'
import { BackendConfigs } from '../../../../../../config.environment'
import { APIResponse } from '../../../../../../types/Response'
import { toast } from 'react-toastify'
import { LessonBottomBarComponent } from '../../Common/BottomBar'
import { LessonDataType } from '../../../../../../types/Lesson'
import CodeGenerator from './CodeGenerator'
import CodePlayerComponent from './CodePlayer'
import audioPlayer from '../../../../../../components/audio-player'








type MicropythonLessonWorkspacePageProps = {}

export default function MicropythonLessonWorkspacePage({ }: MicropythonLessonWorkspacePageProps) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const { lessonId } = useParams();
    const [lessonData, setLessonData] = useState<LessonDataType | null>(null)
    const [xml, setXml] = useState<string>("")
    const [code, setCode] = useState<string>("")
    const [newBlocks, setNewBlocks] = useState<string[]>([])
    const [refreshData, setRefreshData] = useState<boolean>(false)
    const [isGuideVisible, setIsGuideVisible] = useState(true);

    const [keys, setKeys] = useState<{
        [key: string]: string
    }>({

    })

    async function getLessons() {
        console.log(lessonId, user.userData);
        if (!!user.userData && !!lessonId) {
            handleGlobalLoading(true, "Fetching Lesson")
            setLessonData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetLessonByIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id,
                        lessonId: lessonId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    // toast.success(result.message)
                    setLessonData(result.data)
                } else {
                    navigate(routes.DASHBOARD.MAIN)
                }
            } catch (error) {
                toast.error("Error Fetching Lesson")
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }

    useEffect(() => {
        getLessons()
    }, [lessonId, user])
    if (!lessonData) {
        return <></>
    }
    return (
        <>
            <div style={{
                overflow: "scroll",
                height: window.innerHeight,
                maxWidth: "100vw",
                overflowX: "hidden"
            }}>
                {
                    lessonData ? <>
                        <div key={refreshData ? "1" : "2"}>
                            <CodeGenerator key={refreshData ? "1" : "2"} lessonData={lessonData} code={code} setCode={setCode} xml={xml} setXml={setXml} keys={keys} setNewBlocks={(newBlocks) => {
                                setNewBlocks(newBlocks)
                            }} />
                        </div>
                    </> : <></>
                }
                <CodePlayerComponent closePlayer={()=>{
                    setIsGuideVisible(false)
                }} isVisible={isGuideVisible} setRefreshData={()=>{setRefreshData(!refreshData)}} xml={xml} code={code} lessonData={lessonData} setXml={setXml} setCode={setCode} setLessonData={setLessonData}/>
            </div>
            {
                lessonData &&
                <LessonBottomBarComponent toggleHelp={()=>{ setIsGuideVisible(!isGuideVisible) }} lesson={lessonData} autoFill={() => {
                    setXml(lessonData.codeInstructions.finalXml)
                    setRefreshData(!refreshData)
                }}/>
            }
        </>

    )
}


