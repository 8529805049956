import * as React from 'react';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import Lessons from './Lessons';
import LevelsTimelineComponent from './SAASLessons/LevelsTimeline';
import { LessonDataType } from '../../../types/Lesson';
import { SettingContext } from '../../../context/SettingsContext';
import { BackendConfigs } from '../../../config.environment';
import { APIResponse } from '../../../types/Response';
import { UserContext } from '../../../context/UserContext';

export type LessonsPageProps = {
}



export default function LessonsPage(props: LessonsPageProps) {
    const { settings, handleGlobalLoading } = React.useContext(SettingContext)
    const { user } = React.useContext(UserContext)
    const [currentLevel, setCurrentLevel] = React.useState(0);
    const [completedStep, setCompletedStep] = React.useState(0);
    const [screen, setScreen] = React.useState<"Level" | "Lesson">("Level");
    return (
        <div>
            <DashboardLayout title={screen === "Level" ? "Curriculum" : `${currentLevel + 1} Level Lessons`}>
                <Lessons />
            </DashboardLayout>
        </div>
    );
}
