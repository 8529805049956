import React, { useEffect, useState } from 'react';
import * as tmImage from '@teachablemachine/image';



export function getFinalPrediction(prediction: {
    className: string,
    probability: number
}[], threshold = 0.5) {
    // console.log(threshold);
    
    let maxProbability = 0;
    let value = "";
    prediction.forEach((predictionItem) => {
        if (predictionItem.probability > maxProbability) {
            maxProbability = predictionItem.probability
            value = predictionItem.className
        }
    })
    // console.log(value);
    
    if (maxProbability >= threshold) {
        return value
    } else {
        return ""
    }
}



const GoogleTeachableExecuter = ({
    model_url = "https://teachablemachine.withgoogle.com/models/iPnfTrwzT/",
    preview = true,
    size = 1000,
    info = true,
    interval = 100,
    setPredictionValue = (value: any) => { },
    setFinalPrediction = (value: string) => { },
}) => {
    const [prediction, setPrediction] = useState<any>(null);
    const previewRef = React.useRef<HTMLDivElement>();
    const requestRef = React.useRef<number>();
    const intervalRef = React.useRef<any>();
    async function init() {
        const modelURL = model_url + "model.json";
        const metadataURL = model_url + "metadata.json";
        const model = await tmImage.load(modelURL, metadataURL);
        const flip = true;
        const webcam = new tmImage.Webcam(size * 2, size, flip);
        await webcam.setup();
        try {
            await webcam.play();
        }
        catch (e) {
            console.log(e);
        }
        if (interval === null) {

            requestRef.current = window.requestAnimationFrame(loop);
        } else {
            intervalRef.current = setTimeout(loop, interval) as any;
        }
        if (preview) {
            previewRef.current && previewRef.current.replaceChildren(webcam.canvas);
        }
        async function loop() {
            if (webcam === null) { } else {
                webcam.update();
                await predict();
            }
            if (interval === null) {
                requestRef.current = window.requestAnimationFrame(loop);
            } else {
                intervalRef.current = setTimeout(loop, interval);
            }
        }
        async function predict() {
            const prediction = await model.predict(webcam.canvas);
            setPrediction(prediction);
            // console.log(prediction);
            setPredictionValue(prediction)
            setFinalPrediction(getFinalPrediction(prediction))
        }
    }
    useEffect(() => {
        init();
        return () => {
            if (interval === null) {
                cancelAnimationFrame(requestRef.current as number);
            } else {
                clearTimeout(intervalRef.current);
            }
            (document.querySelector('#webcam-container') as HTMLDivElement) && (document.querySelector('#webcam-container') as HTMLDivElement).firstChild?.remove();
        };
    }, [model_url]);
    const label = [] as any[];
    return React.createElement("div", null, label, React.createElement("div", {
        id: "webcam-container",
        ref: previewRef,
    }));
    return <></>
};
export default GoogleTeachableExecuter;