import React, { useEffect } from 'react'
import CircuitDesigner from '../../../../../components/circuit-designer'
import CircuitToCodeGenerator from '../../../../../components/circuit-to-code-generator'
import { BackendConfigs } from '../../../../../config.environment'
import { ProjectDataType } from '../../../../../types/Dashboard'
import { motion } from 'framer-motion'
import { framerAnimation } from '../../../../../theme/animation/MotionConfigs'
import { LessonDataType } from '../../../../../types/Lesson'

type Props = {
  circuitJSON: string
  setCircuitJSON: React.Dispatch<React.SetStateAction<string>>
}

function CircuitDesignerForLessonBuilderComponent({
  circuitJSON,
  setCircuitJSON,
}: Props) {


  useEffect(() => {
    // console.log(project.circuit.circuitJSON);
    // console.log(circuitJSON);
  }, [])

  return (
    <div style={{
      height: "99vh",
      // border: "1px solid gray"
    }}>
      
      <CircuitToCodeGenerator circuitJSON={circuitJSON} setCircuitJSON={setCircuitJSON} />
    </div>
  )
}

export default CircuitDesignerForLessonBuilderComponent