import { Button, Card, LinearProgress, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { LessonDataType, LessonProgressStats } from '../../../types/Lesson'
import { eduCOBOTColorScheme } from '../../../context/SettingsContext'
import { ContentDialogBox } from '../../../components/dialog-box'
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from '../../../context/UserContext'
import Chart from 'react-apexcharts';
import { BackendConfigs } from '../../../config.environment'
import { APIResponse } from '../../../types/Response'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

type Props = {
    lessons: LessonDataType[],
}

function TotalProgressComponent({
    lessons,
}: Props) {
    const [isOpen, setIsOpen] = React.useState(false)
    const { user } = React.useContext(UserContext)
    const [progressData, setProgressData] = useState<LessonProgressStats>()


    async function fetchProgressReport() {
        try {
            const response = await fetch(`${BackendConfigs.url}/GetUserProgressAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json"
                },
                body: JSON.stringify({
                    userId: user.userData?.id
                })
            })
            const result = await response.json() as APIResponse
            setProgressData(result.data)
        } catch (error) {
            console.log(error);

        }
    }
    useEffect(() => {
        if (!!user.userData) {
            fetchProgressReport()
        }
    }, [user])
    if (!!progressData) {
        return (






            <>
                <Tooltip title="Progress Report" placement="bottom">

                    <Button variant="outlined" color="primary" onClick={() => {
                        setIsOpen(true)
                    }} style={{
                    }}>
                        <img src="https://storage.googleapis.com/educobot-robotics/images/set-1/Progress%20Report.svg" style={{
                            width: "30px"
                        }} alt="" />
                    </Button>
                </Tooltip>
                <ContentDialogBox
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    fullScreen
                    title={
                        <>
                            <Typography variant='h6'>
                                Progress Report for <span style={{
                                    color: "initial",
                                    fontWeight: "bolder",
                                    textDecoration: "underline"
                                }}> {user.userData?.name}</span>
                            </Typography>
                        </>
                    }
                    content={

                        <>
                            <Button variant='contained' color='warning' sx={{
                                position: "fixed",
                                top: "10px",
                                right: "10px"
                            }} onClick={() => {
                                setIsOpen(false)
                            }}>
                                <CloseIcon />
                            </Button>

                            <Typography component={'div'} sx={{
                                // height: "100%",
                            }}>


                                <div>
                                    <h5 style={{
                                        textAlign: "center",
                                        color: eduCOBOTColorScheme.gray
                                    }}>
                                        Current Progress
                                    </h5>
                                    <Card sx={{
                                        padding: "10px"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            margin: "20px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px",
                                            }}>
                                                <img src="https://educobot.com/imgs/product/coin5.png" width={100} alt="" />
                                                <h1 style={{
                                                    fontSize: "50px"
                                                }}>
                                                    {
                                                        progressData.coins.gained
                                                    }
                                                </h1>
                                            </div>
                                        </div>

                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexWrap: "wrap",
                                            gap: "30px",
                                        }}>

                                            <div style={{
                                                display: "flex",
                                                gap: "10px",
                                                flexDirection: "column",
                                                margin: "50px 0"
                                            }}>
                                                <h3>
                                                    Total {progressData.lessons.completed} out of {progressData.lessons.outof} lessons completed
                                                </h3>
                                                <Card sx={{ width: '100%' }}>
                                                    <Chart
                                                        // key={data}
                                                        type="pie"
                                                        height={400}
                                                        width={500}
                                                        series={[progressData.lessons.completed, progressData.lessons.outof - progressData.lessons.completed]}
                                                        options={{
                                                            labels: ["Completed", "Non-Completed"],
                                                            colors: [eduCOBOTColorScheme.green, eduCOBOTColorScheme.red],
                                                            stroke: {
                                                            },
                                                            plotOptions: {
                                                                radialBar: {
                                                                    startAngle: 0,
                                                                    endAngle: 360,
                                                                    dataLabels: {
                                                                        show: true,
                                                                        value: {
                                                                            formatter: function (value: any) {
                                                                                return value + "%";
                                                                            },
                                                                            fontSize: "30px"
                                                                        }
                                                                    }
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Card>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                gap: "10px",
                                                flexDirection: "column",
                                                margin: "50px 0",
                                            }}>
                                                <h3>
                                                    Variations in Completed Lessons
                                                </h3>
                                                <Card sx={{ width: '100%' }}>
                                                    <Chart
                                                        // key={data}
                                                        type="pie"
                                                        height={400}
                                                        width={500}
                                                        series={[
                                                            progressData.assemblyProgress.completedLessonCount,
                                                            progressData.circuitProgress.completedLessonCount,
                                                            progressData.codeProgress.completedLessonCount,
                                                            progressData.iotProgress.completedLessonCount,
                                                            progressData.aiProgress.completedLessonCount,
                                                            progressData.lessons.outof - progressData.lessons.completed]}
                                                        options={{
                                                            labels: ["Assembly", "Circuitry", "Coding", "IOT", "AI", "Not Completed"],
                                                            colors: [
                                                                eduCOBOTColorScheme.light_blue,
                                                                eduCOBOTColorScheme.blue,
                                                                eduCOBOTColorScheme.green,
                                                                eduCOBOTColorScheme.orange,
                                                                eduCOBOTColorScheme.purple,
                                                                eduCOBOTColorScheme.red],
                                                            stroke: {
                                                            },
                                                            plotOptions: {
                                                                radialBar: {
                                                                    startAngle: 0,
                                                                    endAngle: 360,
                                                                    dataLabels: {
                                                                        show: true,
                                                                        value: {
                                                                            formatter: function (value: any) {
                                                                                return value + "%";
                                                                            },
                                                                            fontSize: "30px"
                                                                        }
                                                                    }
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Card>
                                            </div>
                                        </div>


                                        <div style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            justifyContent: 'center',

                                            gap: "50px",
                                        }}>

                                            <div style={{
                                                margin: "10px 0"
                                            }}>
                                                <h5>
                                                    Assembly Lessons: {progressData.assemblyProgress.completedLessonCount} out of {progressData.assemblyProgress.completedLessonCount + progressData.assemblyProgress.remainingLessons} lessons completed
                                                </h5>
                                                <Card sx={{ width: '100%' }}>
                                                    <Chart
                                                        // key={data}
                                                        type="radialBar"
                                                        height={200}
                                                        width={300}
                                                        series={[parseInt((progressData.assemblyProgress.completedLessonCount * 100 / ((progressData.assemblyProgress.completedLessonCount + progressData.assemblyProgress.remainingLessons) || 1)).toFixed(2), 10)]}
                                                        options={{
                                                            labels: [""],
                                                            fill: {
                                                                colors: [eduCOBOTColorScheme.green],
                                                                type: 'gradient',
                                                                gradient: {
                                                                    shade: 'dark',
                                                                    type: 'circular',
                                                                    shadeIntensity: 0.5,
                                                                    inverseColors: true,
                                                                    opacityFrom: 1,
                                                                    opacityTo: 1,
                                                                    stops: [0, 100]
                                                                }
                                                            },
                                                            stroke: {
                                                            },
                                                            plotOptions: {
                                                                radialBar: {
                                                                    startAngle: -135,
                                                                    endAngle: 135,
                                                                    dataLabels: {
                                                                        show: true,
                                                                        value: {
                                                                            formatter: function (value: any) {
                                                                                return value + "%";
                                                                            },
                                                                            fontSize: "30px"
                                                                        }
                                                                    }
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Card>
                                            </div>
                                            <div style={{
                                                margin: "10px 0"
                                            }}>
                                                <h5>
                                                    Circuit Lessons: {progressData.circuitProgress.completedLessonCount} out of {progressData.circuitProgress.completedLessonCount + progressData.circuitProgress.remainingLessons} lessons completed
                                                </h5>
                                                <Card sx={{ width: '100%' }}>
                                                    <Chart
                                                        // key={data}
                                                        type="radialBar"
                                                        height={200}
                                                        width={300}
                                                        series={[parseInt((progressData.circuitProgress.completedLessonCount * 100 / ((progressData.circuitProgress.completedLessonCount + progressData.circuitProgress.remainingLessons) || 1)).toFixed(2), 10)]}
                                                        options={{
                                                            labels: [""],
                                                            fill: {
                                                                colors: [eduCOBOTColorScheme.light_blue],
                                                                type: 'gradient',
                                                                gradient: {
                                                                    shade: 'dark',
                                                                    type: 'circular',
                                                                    shadeIntensity: 0.5,
                                                                    inverseColors: true,
                                                                    opacityFrom: 1,
                                                                    opacityTo: 1,
                                                                    stops: [0, 100]
                                                                }
                                                            },
                                                            stroke: {
                                                            },
                                                            plotOptions: {
                                                                radialBar: {
                                                                    startAngle: -135,
                                                                    endAngle: 135,
                                                                    dataLabels: {
                                                                        show: true,
                                                                        value: {
                                                                            formatter: function (value: any) {
                                                                                return value + "%";
                                                                            },
                                                                            fontSize: "30px"
                                                                        }
                                                                    }
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Card>
                                            </div>
                                            <div style={{
                                                margin: "10px 0"
                                            }}>                    <h5>
                                                    Coding Lessons: {progressData.codeProgress.completedLessonCount} out of {progressData.codeProgress.completedLessonCount + progressData.codeProgress.remainingLessons} lessons completed
                                                </h5>
                                                <Card sx={{ width: '100%' }}>
                                                    <Chart
                                                        // key={data}
                                                        type="radialBar"
                                                        height={200}
                                                        width={300}
                                                        series={[parseInt((progressData.codeProgress.completedLessonCount * 100 / ((progressData.codeProgress.completedLessonCount + progressData.codeProgress.remainingLessons) || 1)).toFixed(2), 10)]}
                                                        options={{
                                                            labels: [""],
                                                            fill: {
                                                                colors: [eduCOBOTColorScheme.gray],
                                                                type: 'gradient',
                                                                gradient: {
                                                                    shade: 'dark',
                                                                    type: 'circular',
                                                                    shadeIntensity: 0.5,
                                                                    inverseColors: true,
                                                                    opacityFrom: 1,
                                                                    opacityTo: 1,
                                                                    stops: [0, 100]
                                                                }
                                                            },
                                                            stroke: {
                                                            },
                                                            plotOptions: {
                                                                radialBar: {
                                                                    startAngle: -135,
                                                                    endAngle: 135,
                                                                    dataLabels: {
                                                                        show: true,
                                                                        value: {
                                                                            formatter: function (value: any) {
                                                                                return value + "%";
                                                                            },
                                                                            fontSize: "30px"
                                                                        }
                                                                    }
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Card>
                                            </div>
                                            <div style={{
                                                margin: "10px 0"
                                            }}>                    <h5>
                                                    IOT  Lessons: {progressData.iotProgress.completedLessonCount} out of {progressData.iotProgress.completedLessonCount + progressData.iotProgress.remainingLessons} lessons completed
                                                </h5>
                                                <Card sx={{ width: '100%' }}>
                                                    <Chart
                                                        // key={data}
                                                        type="radialBar"
                                                        height={200}
                                                        width={300}
                                                        series={[parseInt((progressData.iotProgress.completedLessonCount * 100 / ((progressData.iotProgress.completedLessonCount + progressData.iotProgress.remainingLessons) || 1)).toFixed(2), 10)]}
                                                        options={{
                                                            labels: [""],
                                                            fill: {
                                                                colors: [eduCOBOTColorScheme.black],
                                                                type: 'gradient',
                                                                gradient: {
                                                                    shade: 'dark',
                                                                    type: 'circular',
                                                                    shadeIntensity: 0.5,
                                                                    inverseColors: true,
                                                                    opacityFrom: 1,
                                                                    opacityTo: 1,
                                                                    stops: [0, 100]
                                                                }
                                                            },
                                                            stroke: {
                                                            },
                                                            plotOptions: {
                                                                radialBar: {
                                                                    startAngle: -135,
                                                                    endAngle: 135,
                                                                    dataLabels: {
                                                                        show: true,
                                                                        value: {
                                                                            formatter: function (value: any) {
                                                                                return value + "%";
                                                                            },
                                                                            fontSize: "30px"
                                                                        }
                                                                    }
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Card>
                                            </div>
                                            <div style={{
                                                margin: "10px 0"
                                            }}>                    <h5>
                                                    AI  Lessons: {progressData.aiProgress.completedLessonCount} out of {progressData.aiProgress.completedLessonCount + progressData.aiProgress.remainingLessons} lessons completed
                                                </h5>
                                                <Card sx={{ width: '100%' }}>
                                                    <Chart
                                                        // key={data}
                                                        type="radialBar"
                                                        height={200}
                                                        width={300}
                                                        series={[parseInt((progressData.aiProgress.completedLessonCount * 100 / ((progressData.aiProgress.completedLessonCount + progressData.aiProgress.remainingLessons) || 1)).toFixed(2), 10)]}
                                                        options={{
                                                            labels: [""],
                                                            fill: {
                                                                colors: [eduCOBOTColorScheme.red],
                                                                type: 'gradient',
                                                                gradient: {
                                                                    shade: 'dark',
                                                                    type: 'circular',
                                                                    shadeIntensity: 0.5,
                                                                    inverseColors: true,
                                                                    opacityFrom: 1,
                                                                    opacityTo: 1,
                                                                    stops: [0, 100]
                                                                }
                                                            },
                                                            stroke: {
                                                            },
                                                            plotOptions: {
                                                                radialBar: {
                                                                    startAngle: -135,
                                                                    endAngle: 135,
                                                                    dataLabels: {
                                                                        show: true,
                                                                        value: {
                                                                            formatter: function (value: any) {
                                                                                return value + "%";
                                                                            },
                                                                            fontSize: "30px"
                                                                        }
                                                                    }
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Card>
                                            </div>
                                        </div>
                                        {/* <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexWrap: "wrap",
                                            gap: "30px",
                                        }}>
                                            <h1>
                                                Your Stake
                                            </h1>



                                        </div> */}
                                    </Card>
                                </div>

                            </Typography>
                            <CalenderStakesComponent stakes={progressData.stakes} />
                        </>


                    } />
            </>
        )
    }
    return null
}

export default TotalProgressComponent


type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];


function CalenderStakesComponent({
    stakes
}: {
    stakes: string[]
}) {
    const [value, onChange] = useState<Value>(new Date());
    function getDateCountInStakes(date: Date) {
        console.log(date.getTime());
        const day = date.getDay()
        const month = date.getMonth()
        const year = date.getFullYear()
        const date_ = date.getDate()


        let count = stakes.filter(value => {
            const stakeDate = new Date(parseInt(value))
            return stakeDate.getDate() == date_ && stakeDate.getMonth() == month && stakeDate.getFullYear() == year && stakeDate.getDay() == day
        }).length
        return count
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: "center"
        }}>
            <h1>
                Progress Stakes
                <pre>

                    {/* {
                    JSON.stringify(stakes, null, 4)
                } */}
                </pre>
            </h1>
            <Calendar tileContent={({ activeStartDate, date, view }) => view === 'month' && getDateCountInStakes(date) > 0 ? <p style={{
                padding: "2px",
                color: "white",
                background: eduCOBOTColorScheme.green
            }}>{getDateCountInStakes(date)}</p> : null} onChange={onChange} value={value} className={"react-calender-style"} />

        </div>
    )
}