export class JavascriptAudioPlayer {
    audio = new Audio();

    play(fileName: string, isMute: boolean = false) {
        console.log(fileName," is playing");
        
        try {
            this.audio = new Audio(fileName.includes('https') ? fileName : `/audios/${fileName}`);
            if (isMute) {
                this.audio.volume = 0.0;
            }
            this.audio.pause();
            this.audio.play();
        } catch (error) {
            console.log(error);
        }
    }
    stop() {
        try {
            this.audio.volume = 0.0;
            this.audio.pause();
        } catch (error) {
            console.log(error);
        }
    }
    resume() {
        try {
            this.audio.volume = 1.0;
            this.audio.play();
        } catch (error) {
            console.log(error);
        }
    }
}


const audioPlayer = new JavascriptAudioPlayer();
export default audioPlayer