import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../../context/SettingsContext'
import { Button, Card, Container, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../../../../../../routes/routes'
import { BackendConfigs } from '../../../../../../config.environment'
import { APIResponse } from '../../../../../../types/Response'
import { toast } from 'react-toastify'
import { LessonBottomBarComponent } from '../../Common/BottomBar'
import { LessonDataType } from '../../../../../../types/Lesson'
import TheoryPlayerComponent from './TheoryPlayer'
type LessonTheoryPageProps = {}

export default function LessonTheoryPage({ }: LessonTheoryPageProps) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const { lessonId } = useParams();
    const [lessonData, setLessonData] = useState<LessonDataType | null>(null)
    const [isGuideVisible, setIsGuideVisible] = useState(true);
    async function getLesson() {
        console.log(lessonId, user.userData);
        if (!!user.userData && !!lessonId) {
            handleGlobalLoading(true, "Fetching Lesson")
            setLessonData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetLessonByIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id,
                        lessonId: lessonId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    // toast.success(result.message)
                    console.log(result.data.editors);
                    if (result.data.editors.some((editor: string) => editor.toLowerCase().includes("theory"))) {
                        setLessonData(result.data)
                    } else {
                        if (result.data.editors.some((editor: string) => editor.toLowerCase().includes("assembly"))) {
                            navigate(routes.DASHBOARD.LESSON.WORKSPACE.ASSEMBLY(lessonId))
                        }
                        else if (result.data.editors.some((editor: string) => editor.toLowerCase().includes("circuit"))) {
                            navigate(routes.DASHBOARD.LESSON.WORKSPACE.CIRCUIT(lessonId))
                        }
                        else if (result.data.editors.some((editor: string) => editor.toLowerCase().includes("code"))) {
                            navigate(routes.DASHBOARD.LESSON.WORKSPACE.MICROPYTHON(lessonId))
                        }
                        else if (result.data.editors.some((editor: string) => editor.toLowerCase().includes("iot"))) {
                            navigate(routes.DASHBOARD.LESSON.WORKSPACE.MONITOR(lessonId))
                        }
                        else if (result.data.editors.some((editor: string) => editor.toLowerCase().includes("ai"))) {
                            navigate(routes.DASHBOARD.LESSON.WORKSPACE.AI(lessonId))
                        }
                    }
                } else {
                    navigate(routes.DASHBOARD.MAIN)
                }
            } catch (error) {
                toast.error("Error Fetching Lesson")
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }

    useEffect(() => {
        getLesson()
    }, [lessonId, user])
    useEffect(() => {

    }, [])
    return (
        <>
            <div style={{
                marginBottom: 50,
                overflow: "scroll"
            }}>
                <Container>

                </Container>
            </div>
            {
                lessonData &&
                <LessonBottomBarComponent  lesson={lessonData} />
            }
            {
                lessonData &&
                <TheoryPlayerComponent setLessonData={setLessonData} closePlayer={() => {
                    // setIsGuideVisible(false)
                }} isVisible={isGuideVisible} setRefreshData={() => { }} lessonData={lessonData} />
            }

        </>

    )
}