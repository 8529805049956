import { Button, Card, Container, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ContentDialogBox } from '../../../../components/dialog-box'
import DeleteIcon from '@mui/icons-material/Delete';
import AddInstructionComponent from './AddInstructions';
import CloseIcon from '@mui/icons-material/Close';
import CircuitDesignerForLessonBuilderComponent from './CircuitDesignerForLessonBuilder';

type Props = {
    referenceCircuitJSON: string,
    setReferenceCircuitJSON: (circuitJSON: string) => void,
    instructions: string[],
    setInstructions: (instructions: string[]) => void
}

function CircuitBuildingComponent({
    referenceCircuitJSON,
    setReferenceCircuitJSON,
    instructions,
    setInstructions
}: Props) {
    const [open, setIsOpen] = React.useState(false)
    const [circuitJSON, setCircuitJSON] = useState<string>(referenceCircuitJSON)
    useEffect(() => {
        setReferenceCircuitJSON(circuitJSON)
    }, [circuitJSON])
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "center",
            alignItems: "center",
        }}>

            <Card sx={{
                padding: "5px 20px",
                display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                alignItems: "center",
                width: "100%",
            }}>
                <img src="https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20creating%20a%20circuit.png" style={{
                    height: "50px",
                    margin: "0.1rem"
                }} alt="" />
                <h3 style={{
                    // width: "100%",
                    flexGrow: 1,
                    padding: "0 1rem",
                }}>
                    Circuit
                </h3>
                <Button style={{
                    borderRadius: "0.4rem",
                }} variant='contained' color='info' onClick={() => {
                    setIsOpen(true)
                }}>
                    Add Circuit
                </Button>
            </Card>
            {
                open && <>
                    <div style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100dvw",
                        height: "100dvh",
                        backgroundColor: "white",
                        zIndex: 9999
                    }}>
                        <div style={{
                            position: "fixed",
                            top: 0,
                            right: 0,
                            zIndex: 99999,
                            display: "flex",
                            justifyContent: "center",
                            gap: "1rem",
                            padding: "0.5rem",
                            backgroundColor: "white",
                            outline: "1px solid gray",
                            borderRadius: "5px",
                        }}>
                            <Tooltip title="Give this Circuit as a Reference" style={{
                                zIndex: 999999999
                            }}>
                                <Button variant='contained' color='secondary'>
                                    Circuit Reference
                                </Button>
                            </Tooltip>
                            <Tooltip title="Open Instructions" >
                                <AddInstructionComponent lessonInstructions={instructions} setLessonInstructions={setInstructions} />
                            </Tooltip>
                            <Button variant='contained' color='warning' onClick={() => {
                                setIsOpen(false)
                            }}>
                                <CloseIcon />
                            </Button>
                        </div>
                        <CircuitDesignerForLessonBuilderComponent circuitJSON={circuitJSON} setCircuitJSON={setCircuitJSON} />
                    </div>
                </>
            }
        </div>
    )
}

export default CircuitBuildingComponent