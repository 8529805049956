import { Button, Card, Container, TextField } from '@mui/material'
import React from 'react'
import { ContentDialogBox } from '../../../../components/dialog-box'
import DeleteIcon from '@mui/icons-material/Delete';
type Props = {}

function IOTBuildComponent({ }: Props) {
    const [open, setIsOpen] = React.useState(false)
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "center",
            alignItems: "center",
        }}>

            <Card sx={{
                padding: "5px 20px",
                display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                alignItems: "center",
                width: "100%",
            }}>
                <img src="https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20with%20a%20text%20written%20on%20screen%20as%20IOT.png" style={{
                    height: "50px",
                    margin: "0.1rem"
                }} alt="" />
                <h3 style={{
                    // width: "100%",
                    flexGrow: 1,
                    padding: "0 1rem",
                }}>
                    IOT
                </h3>
                <Button style={{
                    borderRadius: "0.4rem",
                }} variant='contained' color='info' onClick={() => {
                    setIsOpen(true)
                }}>
                    Add IOT
                </Button>
            </Card>
        </div>
    )
}

export default IOTBuildComponent