import { Button, Card, Container, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { ContentDialogBox } from '../../../../../components/dialog-box'
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    theory: {
        type: "theory" | "mcq",
        question: string,
        correctAnswer: string,
        options: string[]
    }[],
    setTheory: (theory: {
        type: "theory" | "mcq",
        question: string,
        correctAnswer: string,
        options: string[]
    }[]) => void,
    isEditable?: boolean
}

function TheoryAttemptComponent({ theory, setTheory, isEditable }: Props) {
    const [open, setOpen] = React.useState(false)
    const [questions, setQuestions] = React.useState<{
        type: "theory" | "mcq",
        question: string,
        correctAnswer: string,
        options: string[]
    }[]>(theory)
    useEffect(() => {
        console.log(theory);
    }, [])
    React.useEffect(() => {
        setTheory(questions)
    }, [questions])
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        }}>
            <Card sx={{
                padding: "5px 20px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
            }}>
                <img src="https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20with%20the%20text%20Theory%20on%20the%20screen.png" style={{
                    height: "150px",
                    margin: "0.1rem"
                }} alt="" />
                <h3 style={{
                    // width: "100%",
                    flexGrow: 1,
                    padding: "0 1rem",
                }}>
                    Theory
                </h3>
                <Button fullWidth style={{
                    borderRadius: "0.4rem",
                }} variant='contained' color='primary' onClick={() => {
                    setOpen(true)
                }}>
                    Open
                </Button>
            </Card>
            <ContentDialogBox
                isOpen={open}
                onClose={() => {
                    setOpen(false)
                }}
                fullScreen
                content={<>
                    <div style={{
                        position: "fixed",
                        top: 0,
                        right: 0,
                        zIndex: 99999,
                        display: "flex",
                        justifyContent: "center",
                        gap: "1rem",
                        padding: "0.5rem",
                        backgroundColor: "white",
                        // outline: "1px solid gray",
                        borderRadius: "5px",
                    }}>
                        <Button variant='contained' color='warning' onClick={() => {
                            setOpen(false)
                        }}>
                            <CloseIcon />
                        </Button>
                    </div>
                    <Container style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "1rem",
                        flexDirection: "column",
                    }}>

                        {
                            questions.length === 0 && <div style={{
                                width: "100%",
                            }}>
                                <Card style={{
                                    padding: "1rem",
                                    width: "max(1000px,100%)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "1rem",
                                    minHeight: "200px",
                                }}>
                                    <h2>No Questions Added</h2>
                                </Card>

                            </div>
                        }
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: "1rem",
                            width: "100%"
                        }}>
                            {questions.map((question, index) => {

                                if (question.type === "mcq") {
                                    return <div key={index} style={{
                                        width: "100%",
                                    }}>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            // alignItems: "center",
                                            gap: "1rem",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "0 1rem",
                                                fontWeight: "bolder",
                                            }}>
                                                {index + 1}.
                                            </div>
                                            <TextField
                                                disabled={!isEditable}
                                                fullWidth
                                                variant='standard'
                                                label="Question"
                                                value={question.question}
                                                onChange={(e) => {
                                                    const newQuestions = [...questions]
                                                    newQuestions[index].question = e.target.value
                                                    setQuestions(newQuestions)
                                                }}
                                                placeholder={`Question ${index + 1}`}
                                            />
                                            {/* <div>

                                                <Button color='error' onClick={() => {
                                                    if(!isEditable) return
                                                    let newQuestions = [...questions]
                                                    newQuestions.splice(index, 1)
                                                    setQuestions(newQuestions)
                                                }}>
                                                    <DeleteIcon />
                                                </Button>
                                            </div> */}
                                        </div>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            gap: "1rem",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                            margin: "1rem 0"
                                        }}>
                                            {
                                                question.options.map((option, optionIndex) => (
                                                    <Button key={optionIndex} variant={
                                                        question.correctAnswer === option ? "contained" : "outlined"
                                                    } onClick={() => {
                                                        if (!isEditable) return
                                                        const newQuestions = [...questions]
                                                        newQuestions[index].correctAnswer = option
                                                        setQuestions(newQuestions)
                                                    }} >
                                                        {option}

                                                    </Button>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                                else if (question.type === "theory") {
                                    return <div key={index} style={{
                                        width: "100%",
                                    }}>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            // alignItems: "center",
                                            gap: "1rem",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "0 1rem",
                                                fontWeight: "bolder",
                                            }}>
                                                {index + 1}.
                                            </div>
                                            <TextField
                                                disabled={!isEditable}
                                                fullWidth
                                                variant='standard'
                                                label="Question"
                                                value={question.question}
                                                onChange={(e) => {
                                                    if (!isEditable) return
                                                    const newQuestions = [...questions]
                                                    newQuestions[index].question = e.target.value
                                                    setQuestions(newQuestions)
                                                }}
                                                placeholder={`Question ${index + 1}`}
                                            />
                                            {/* <div>

                                                <Button variant='text' color='error' onClick={() => {
                                                    if(!isEditable) return
                                                    let newQuestions = [...questions]
                                                    newQuestions.splice(index, 1)
                                                    setQuestions(newQuestions)
                                                }}>
                                                    <DeleteIcon />
                                                </Button>
                                            </div> */}
                                        </div>
                                        <div style={{
                                            width: "100%",
                                            margin: "0.5rem 0"
                                        }}>
                                            {/* <TextField
                                                disabled={!isEditable}
                                                fullWidth
                                                label="Write Your Answer"
                                                value={question.correctAnswer}
                                                onChange={(e) => {
                                                    const newQuestions = [...questions]
                                                    newQuestions[index].correctAnswer = e.target.value
                                                    setQuestions(newQuestions)
                                                }}
                                                multiline
                                                rows={4}
                                            /> */}
                                        </div>
                                    </div>
                                }
                            }
                            )}
                        </div>
                    </Container>

                </>}
            />
        </div>
    )
}

export default TheoryAttemptComponent