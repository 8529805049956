import React, { useContext, useEffect } from 'react'
import { SettingContext } from '../../../context/SettingsContext'
import { Button, Card, TextField, Typography } from '@mui/material'
import { BackendConfigs } from '../../../config.environment'
import { UserContext } from '../../../context/UserContext'
import { APIResponse } from '../../../types/Response'
import { toast } from 'react-toastify'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type Props = {}

function APIKeySettings({ }: Props) {
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [APIKey, setAPIKey] = React.useState<string>("")
    const [isAPIKeyVisible, setIsAPIKeyVisible] = React.useState<boolean>(false)
    async function getAPIKey() {
        if (!user.userData?.id) return
        handleGlobalLoading(true, "Fetching API Key...")
        try {
            const response = await fetch(`${BackendConfigs.url}/GetAPIKeyAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ownerId: user.userData?.id
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                console.log(result);

                if (result.success) {
                    setAPIKey(result.data.apiKey)
                    toast.success("API Key Found Successfully")
                    handleGlobalLoading(false)

                    return
                }
            }
        } catch (error) {
            console.log(error);
        }
        toast.error("Error while getting API Key")
        handleGlobalLoading(false)
    }
    async function generateAPIKey() {

        if (!user.userData?.id) return
        handleGlobalLoading(true, "Generating API Key...")
        try {
            const response = await fetch(`${BackendConfigs.url}/GenerateAPIKeyAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ownerId: user.userData?.id
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                console.log(result);
                if (result.success) {
                    setAPIKey(result.data.apiKey)
                    toast.success("API Key Generated Successfully")
                    handleGlobalLoading(false)
                    return
                }
            }
        } catch (error) {
            console.log(error);
        }
        toast.error("Error while Generating API Key")
        handleGlobalLoading(false)
    }
    async function regenerateAPIKey() {
        if (!user.userData?.id) return
        handleGlobalLoading(true, "Regenerating API Key...")
        try {
            const response = await fetch(`${BackendConfigs.url}/RegenerateAPIKeyAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ownerId: user.userData?.id
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                console.log(result);

                if (result.success) {
                    toast.success("API Key Regenerated Successfully")
                    handleGlobalLoading(false)
                    await getAPIKey()
                    return
                }
            }
        } catch (error) {
            console.log(error);
        }
        toast.error("Error while regenerating API Key")
        handleGlobalLoading(false)
    }
    useEffect(() => {
        getAPIKey()
    }, [user])
    return (
        <div style={{
            width: "100%",
            // background:"white"
            padding: "10px",
        }}>
            <Card style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
                padding: "10px",
                margin: "10px",
                minHeight: "200px",
            }}>

                <div style={{
                    width:"100%"
                }}>
                    {
                        APIKey === "" &&
                        <Card style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: "10px",
                            padding: "10px",
                            margin: "10px",
                            minHeight: "200px",
                        }}>
                            <h4>API Key</h4>
                            <p>
                                No API Key is associated with your account
                            </p>
                            <Button onClick={generateAPIKey} size='small' variant='contained' color='warning' style={{
                                textTransform: "none",
                                padding: "5px 50px"
                            }}>Generate</Button>
                        </Card>
                    }
                    {
                        APIKey !== "" &&
                        <Typography style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            // border: "1px solid #00000055",
                            padding: "20px",
                            width: "100%",
                            borderRadius: "10px",
                        }}>
                            <TextField size='small' disabled value={isAPIKeyVisible ? APIKey : "******************************"} onChange={(e) => setAPIKey(e.target.value)} fullWidth id="api-key" label="API Key" variant='outlined' />
                            <div style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: "10px",
                            }}>
                                <Button size='small' style={{
                                    textTransform: "none",
                                }} onClick={
                                    () => {
                                        window.navigator.clipboard.writeText(APIKey)
                                        toast.info("API Key Copied Successfully")
                                    }
                                }><ContentCopyIcon /></Button>
                                <Button size='small' style={{
                                    textTransform: "none",
                                }} onClick={() => {
                                    setIsAPIKeyVisible(!isAPIKeyVisible)
                                }}>
                                    {
                                        isAPIKeyVisible ? <VisibilityIcon /> : <VisibilityOffIcon />
                                    }
                                </Button>
                                <Button size='small' variant='contained' style={{
                                    textTransform: "none",
                                    padding: "5px 30px"
                                }} onClick={regenerateAPIKey}>Regenerate</Button>
                            </div>
                        </Typography>
                    }
                </div>
            </Card>
        </div>
    )
}

export default APIKeySettings