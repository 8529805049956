import { Button, Card, Paper } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../context/SettingsContext'
import { useNavigate } from 'react-router-dom'
import { LessonDataType } from '../../../../../types/Lesson'
import { routes } from '../../../../../routes/routes'
import { SteppedLineTo } from 'react-lineto';
import Rating, { IconContainerProps } from '@mui/material/Rating';



type Props = {
    lesson: LessonDataType,
}

function LessonDescription({ lesson }: Props) {
    const { settings } = useContext(SettingContext)
    const [currentUnlockSection, setCurrentUnlockSection] = useState(0)
    const [isAllCompleted, setIsAllCompleted] = useState(false)
    function isSectionAvailable(section: string) {
        return lesson.editors.some(editor => editor.includes(section))
    }
    useEffect(() => {
        let isAllCompleted = true;
        let isSectionNotCompleted = false
        if (lesson.editors.some(editor => editor.includes("theory"))) {
            if (lesson.progress.progressReport.TheoryProgress.isCompleted) setCurrentUnlockSection(2)
            else {
                if (!isSectionNotCompleted) {

                    isAllCompleted = false
                    isSectionNotCompleted = true
                    setCurrentUnlockSection(1)
                }

            }
        }
        if (lesson.editors.some(editor => editor.includes("assembly"))) {
            if (lesson.progress.progressReport.AssemblyProgress.isCompleted) setCurrentUnlockSection(3)
            else {
                if (!isSectionNotCompleted) {
                    isAllCompleted = false
                    isSectionNotCompleted = true
                    setCurrentUnlockSection(2)
                }
            }
        }
        if (lesson.editors.some(editor => editor.includes("circuit"))) {
            if (lesson.progress.progressReport.CircuitProgress.isCompleted) setCurrentUnlockSection(4)
            else {
                if (!isSectionNotCompleted) {
                    isAllCompleted = false
                    isSectionNotCompleted = true
                    setCurrentUnlockSection(3)
                }


            }
        }
        if (lesson.editors.some(editor => editor.includes("micropython"))) {
            if (lesson.progress.progressReport.MicroPythonProgress.isCompleted) setCurrentUnlockSection(5)
            else {
                if (!isSectionNotCompleted) {
                    isAllCompleted = false
                    isSectionNotCompleted = true
                    setCurrentUnlockSection(4)
                }

            }
        }
        if (lesson.editors.some(editor => editor.includes("monitor"))) {
            if (lesson.progress.progressReport.IOTProgress.isCompleted) setCurrentUnlockSection(6)
            else {
                if (!isSectionNotCompleted) {
                    isAllCompleted = false
                    isSectionNotCompleted = true
                    setCurrentUnlockSection(5)
                }

            }
        }
        if (lesson.editors.some(editor => editor.includes("ai"))) {
            if (lesson.progress.progressReport.AIProgress.isCompleted) setCurrentUnlockSection(7)
            else {
                if (!isSectionNotCompleted) {
                    isAllCompleted = false
                    isSectionNotCompleted = true
                    setCurrentUnlockSection(6)
                }
            }
        }
        setIsAllCompleted(isAllCompleted)
    }, [])
    const navigate = useNavigate()
    const [sections, setSections] = useState([
        {
            id: "section-A",
            comparer: "theory",
            title: "Theory",
            description: "Understand the theory behind the lesson",
            link: routes.DASHBOARD.LESSON.WORKSPACE.THEORY(lesson.lessonId),
            image: "https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20with%20the%20text%20Theory%20on%20the%20screen.png",
            progressKey: "TheoryProgress",
            lessonContentKey: "theory",
            maxCoins: 1
        },
        {
            id: "section-B",
            comparer: "assembly",
            title: "Assembly",
            description: "assemble the components give to you",
            link: routes.DASHBOARD.LESSON.WORKSPACE.ASSEMBLY(lesson.lessonId),
            image: "https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20with%20the%20text%20Assembly%20on%20the%20screen.png",
            progressKey: "AssemblyProgress",
            lessonContentKey: "assemblyInstructions",
            maxCoins: 1
        },
        {
            id: "section-C",
            comparer: "circuit",
            title: "Circuit",
            description: "Design the circuit with the components and microcontroller",
            link: routes.DASHBOARD.LESSON.WORKSPACE.CIRCUIT(lesson.lessonId),
            image: "https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20creating%20a%20circuit.png",
            progressKey: "CircuitProgress",
            lessonContentKey: "circuitInstructions",
            maxCoins: 3
        },
        {
            id: "section-D",
            comparer: "micropython",
            title: "Coding",
            description: "Write a Micropython logic for the circuit",
            link: routes.DASHBOARD.LESSON.WORKSPACE.MICROPYTHON(lesson.lessonId),
            image: "https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20with%20the%20text%20Coding%20written%20on%20the%20screen.png",
            progressKey: "MicroPythonProgress",
            lessonContentKey: "codeInstructions",
            maxCoins: 3
        },
        {
            id: "section-E",
            comparer: "monitor",
            title: "IOT Monitor Design",
            description: "Design the IOT Monitor for recording and monitoring the data",
            link: routes.DASHBOARD.LESSON.WORKSPACE.MONITOR(lesson.lessonId),
            image: "https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20with%20a%20text%20written%20on%20screen%20as%20IOT.png",
            progressKey: "IOTProgress",
            lessonContentKey: "iotInstructions",
            maxCoins: 3
        },
        {
            id: "section-F",
            comparer: "ai",
            title: "AI Logic",
            description: " Write AI Logic for ML Model",
            link: routes.DASHBOARD.LESSON.WORKSPACE.AI(lesson.lessonId),
            image: "https://storage.googleapis.com/educobot-robotics/images/set-2/a%20small%20robot%20with%20a%20text%20written%20on%20screen%20as%20AI.png",
            progressKey: "AIProgress",
            lessonContentKey: "aiLogicInstructions",
            maxCoins: 3
        },
    ])

    return (
        <div style={{
            minHeight: "30vh",
            display: 'flex',
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%"
        }}>
            <Card style={{
                minHeight: "150px",
                width: "100%",
                // margin: "10px",
                padding: "10px",
                boxShadow: "0 0 5px #ccc",
                borderRadius: "10px",
                background: "#00000000",
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                }}>
                    <img src={lesson.thumbnail} alt="" style={{
                        maxWidth: "min(95%, 300px)",
                        maxHeight: "200px",
                    }} />
                    <div style={{
                        margin: "0 10px",
                        // width: "100%"
                    }}>
                        <h1 style={{
                            margin: "10px 0",
                            textAlign: "center",
                        }}>{lesson.name}</h1>
                    </div>
                </div>
                {
                    isAllCompleted &&
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "100%",
                        gap: "10px",
                    }}>
                        <img src="https://cdn-icons-png.flaticon.com/512/7518/7518748.png" alt="" style={{
                            width: "200px"
                        }} />
                        <h3 style={{
                            textAlign: "center",
                            color: eduCOBOTColorScheme.gray
                        }}>
                            {
                                isAllCompleted ? "Congratulations! You have completed the lesson" : "Complete the sections to unlock the next section"
                            }
                        </h3>
                    </div>
                }
                <div style={{
                    margin: "10px 0",
                }}>
                    <div style={{
                        display: "flex",
                        gap: "10px",
                    }}>
                        <h5>Aim: </h5>
                        <span style={{
                            fontSize: "12px",
                            color: eduCOBOTColorScheme.gray
                        }}>
                            {
                                lesson.description
                            }
                        </span>
                    </div>
                    <div style={{
                        gap: "10px",
                    }}>
                        <h5>Objective:</h5>
                        <div style={{
                            margin: "5px 20px",
                        }}>
                            {
                                lesson.objectives.map((objective, index) => {
                                    return (
                                        <li style={{
                                            fontSize: "12px",
                                            color: eduCOBOTColorScheme.gray
                                        }} key={index}>{objective}</li>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div style={{
                        gap: "10px",
                    }}>
                        <h5>Outcomes:</h5>
                        <div style={{
                            margin: "5px 20px",
                        }}>
                            {
                                lesson.outcomes.map((outcome, index) => {
                                    return (
                                        <li style={{
                                            fontSize: "12px",
                                            color: eduCOBOTColorScheme.gray
                                        }} key={index}>{outcome}</li>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <h5>Requirements</h5>
                    <ol style={{ padding: "0 20px" }}>

                        {
                            lesson.theory.requirements.map((requirement, index) => {
                                return (
                                    <li style={{
                                        fontSize: "12px",
                                        color: eduCOBOTColorScheme.gray
                                    }} key={index}>{requirement}</li>
                                )
                            })
                        }
                    </ol>
                </div>

                <h5 style={{
                    textAlign: "center",
                }}>Sections</h5>

                <div className='sections-timeline' style={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "100%",
                    justifyContent: settings.screen == "mobile" ? "center" : "space-evenly",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginTop: "30px",
                }}>

                    {
                        sections.map((section, index) => isSectionAvailable(section.comparer) && (
                            <Button key={index} disabled={index + 1 !== currentUnlockSection && !(lesson.progress.progressReport[`${section.progressKey}` as unknown as keyof typeof lesson.progress.progressReport] as {
                                isCompleted: boolean
                            }).isCompleted} variant={
                                (lesson.progress.progressReport[`${section.progressKey}` as unknown as keyof typeof lesson.progress.progressReport] as {
                                    isCompleted: boolean
                                }).isCompleted ? "contained" : 'text'
                            } color={
                                (lesson.progress.progressReport[`${section.progressKey}` as unknown as keyof typeof lesson.progress.progressReport] as {
                                    isCompleted: boolean
                                }).isCompleted ? "success" : undefined
                            } sx={{
                                marginTop: (lesson.progress.progressReport[`${section.progressKey}` as unknown as keyof typeof lesson.progress.progressReport] as {
                                    isCompleted: boolean
                                }).isCompleted ? "30px" : "10px",
                            }}>
                                {
                                    ((lesson.progress.progressReport[`${section.progressKey}` as unknown as keyof typeof lesson.progress.progressReport] as {
                                        isCompleted: boolean
                                    }).isCompleted || (lesson.progress.progressReport[`${section.progressKey}` as unknown as keyof typeof lesson.progress.progressReport] as unknown as {
                                        stepsDetails: []
                                    }).stepsDetails.length > 0) && <div style={{
                                        position: "absolute",
                                        zIndex: 1000,
                                        width: "100%",
                                        height: "50px",
                                        top: "-50px",
                                        right: "0px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        // border: "2px solid #000",
                                    }}>
                                        <div style={{
                                            color: eduCOBOTColorScheme.gray
                                        }}>
                                            {
                                                (lesson.progress.progressReport[`${section.progressKey}` as unknown as keyof typeof lesson.progress.progressReport] as unknown as {
                                                    stepsDetails: []
                                                }).stepsDetails.length + (section.comparer === "theory" ? 5 : 0) + (section.comparer === "circuit" ? 1 : 0) + (section.comparer === "monitor" ? 1 : 0)
                                            } / {
                                                ((lesson[`${section.lessonContentKey}` as unknown as keyof typeof lesson] as unknown as {
                                                    content: string[]
                                                }).content.length).toFixed(0)
                                            } Steps
                                        </div>
                                        {
                                            (lesson.progress.progressReport[`${section.progressKey}` as unknown as keyof typeof lesson.progress.progressReport] as {
                                                isCompleted: boolean
                                            }).isCompleted &&
                                            <div>
                                                <Rating readOnly name="customized-10" defaultValue={(lesson.progress.progressReport[`${section.progressKey}` as unknown as keyof typeof lesson.progress.progressReport] as {
                                                    coins: number
                                                }).coins} max={section.maxCoins} size='large' />
                                                <img src="https://cdn-icons-png.flaticon.com/512/5290/5290119.png" alt="" style={{
                                                    width: "50px",
                                                    height: "50px",

                                                }} />
                                            </div>
                                        }
                                    </div>
                                }
                                <Card className={section.id} key={index} style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    maxWidth: "min(95%, 200px)",
                                    minWidth: "min(95%, 200px)",
                                    // padding: "10px",
                                    boxShadow: "0 0 5px #ccc",
                                    borderRadius: "10px",
                                    zIndex: 999,
                                    opacity: index + 1 !== currentUnlockSection && !(lesson.progress.progressReport[`${section.progressKey}` as unknown as keyof typeof lesson.progress.progressReport] as {
                                        isCompleted: boolean
                                    }).isCompleted ? 0.5 : 1,
                                }}>
                                    <img src={section.image} style={{
                                        maxWidth: "100%",
                                        height: "200px",
                                    }} alt="" />
                                    <div style={{
                                        position: "absolute",
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        margin: "5px",
                                        maxWidth: "min(95%, 200px)",
                                        minWidth: "min(95%, 200px)",
                                        height: "95%",
                                        background: "#00000055",
                                        // padding: "10px",
                                        zIndex: 999,
                                    }}>

                                        <h2 style={{
                                            textAlign: "center",
                                            margin: "5px 5px",
                                            color: eduCOBOTColorScheme.white,
                                        }}>{section.title}</h2>
                                        <h5 style={{
                                            color: eduCOBOTColorScheme.white,
                                            fontSize: "10px",
                                            textAlign: "center",
                                            margin: "5px 5px",
                                        }}
                                        >{section.description}</h5>
                                        <Button onClick={() => navigate(section.link)} style={{
                                            margin: "5px auto",
                                            background: eduCOBOTColorScheme.blue,
                                            color: "#FFF",
                                            width: "90%",
                                        }}>Go to {section.title}</Button>
                                    </div>
                                </Card>
                            </Button>
                        ))
                    }
                    {
                        isAllCompleted && !lesson.progress.progressReport.isCompleted &&
                        <Button variant='contained' >

                            <Card style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                flexDirection: "column",
                                justifyContent: "center",
                                maxWidth: "min(95%, 200px)",
                                minWidth: "min(95%, 200px)",
                                // padding: "10px",
                                boxShadow: "0 0 5px #ccc",
                                borderRadius: "10px",
                                zIndex: 999,
                            }}>
                                <img src={"https://cdn-icons-png.flaticon.com/512/5307/5307921.png"} style={{
                                    maxWidth: "100%",
                                    height: "200px",
                                }} alt="" />
                                <div style={{
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    margin: "5px",
                                    maxWidth: "min(95%, 200px)",
                                    minWidth: "min(95%, 200px)",
                                    height: "95%",
                                    background: "#00000025",
                                    // padding: "10px",
                                    zIndex: 999,
                                }}>

                                    <h5 style={{
                                        color: eduCOBOTColorScheme.white,
                                        fontSize: "10px",
                                        textAlign: "center",
                                        margin: "5px 5px",
                                    }}
                                    >
                                        Submit the lesson to mark it as completed
                                    </h5>
                                    <Button onClick={() => navigate(routes.DASHBOARD.LESSON.SUBMIT(lesson.lessonId))} style={{
                                        margin: "5px auto",
                                        background: eduCOBOTColorScheme.blue,
                                        color: "#FFF",
                                        width: "90%",
                                    }}>Submit</Button>
                                </div>
                            </Card>
                        </Button>
                    }
                </div>
                {
                    isAllCompleted && lesson.progress.progressReport.isCompleted && lesson.progress.progressReport.submittedFileURL && <>
                        <h4 style={{
                            marginTop: "30px"
                        }}>Submitted Image</h4>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            width: "100%",
                            gap: "10px",

                        }}>
                            <img src={lesson.progress.progressReport.submittedFileURL || ""} alt="" style={{
                                width: "min(95%, 500px)",
                            }} />
                        </div>
                    </>
                }


            </Card>



        </div>
    )
}

export default LessonDescription