import React from 'react'
import { DotLottieReact } from '@lottiefiles/dotlottie-react'
import { Button } from '@mui/material'
import { eduCOBOTColorScheme } from '../../../../../context/SettingsContext'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../../../routes/routes'
import { LessonDataType } from '../../../../../types/Lesson'

type Props = {
    lesson:LessonDataType
}

function Submitted({ lesson }: Props) {
    const navigate = useNavigate()
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
        }}>
            <div
                style={{
                    width: 'max(50%,280px)',
                }}
            >
                <DotLottieReact
                    src="/animations/Submitted.lottie"
                    autoplay
                />
            </div>
            <div>
                <h5 style={{
                    color: eduCOBOTColorScheme.orange
                }}>Thank you for your submission</h5>
            </div>
            <Button
                onClick={() => {
                    navigate(routes.DASHBOARD.LESSON.DASHBOARD(lesson.lessonId))
                }}
                sx={{
                    width: "250px",
                    height: "40px",
                    borderRadius: "5px",
                    marginTop: "20px",
                }}
                variant="contained"

            >
                Go to overview
            </Button>
        </div>

    )
}

export default Submitted